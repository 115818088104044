import React from "react";
import styled from "styled-components";
import { devices } from "../../styles";

export const GuideItem = ({ heading = "", icon = "", text = "" }) => {
  return (
    <GuideItemWrapper>
      <img src={icon} alt="" />
      <p>
        <strong>{heading}:</strong> {text}
      </p>
    </GuideItemWrapper>
  );
};

const GuideItemWrapper = styled.li`
  display: flex;
  column-gap: 24px;
  align-items: center;
  flex: 1;
  padding: 16px;
  box-shadow: 0px 1px 2px rgba(60, 64, 67, 0.3),
    0px 2px 6px 2px rgba(60, 64, 67, 0.15);
  border-radius: 16px;

  img {
    height: 48px;
    width: 48px;
  }

  p {
    font-size: 16px;
    line-height: 26px;
    text-align: left;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @media ${devices.tablet} {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;

    &:not(:last-child) {
      margin-bottom: 0px;
    }
  }
`;
