import React, { useState } from "react";
import styled from "styled-components";
import { devices } from "../../styles";
import Logo from "../../assets/logo.png";
import GuideIcon1 from "../../assets/guide-icon-0.png";
import GuideIcon2 from "../../assets/guide-icon-1.png";
import GuideIcon3 from "../../assets/guide-icon-2.png";
import QRCode from "../../assets/qr-code.png";
import { GuideItem } from "./IntroGuideItem";
import PinInstructions from "./IntroGuidePinInstructions";
import IntroGuideModal from "./IntroGuideModal";

const guideItems = [
  {
    heading: "Shape",
    icon: GuideIcon1,
    text: "Answer questions about your patient's condition",
  },
  {
    heading: "Size",
    icon: GuideIcon2,
    text: "Measure your patient's affected leg(s)",
  },
  {
    heading: "Send",
    icon: GuideIcon3,
    text: "Generate the order code and email the prescription details",
  },
];

export default function IntroGuidePage() {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Wrapper>
      <img src={Logo} alt="Hosiery hunter logo" />
      <h1>The right hosiery solution at your fingertips</h1>
      <MainContentWrapper>
        <MainContent>
          <p>
            The Hosiery Hunter&reg; app has been designed to make hosiery
            selection simple, by finding the compression garment that is most
            suitable for the condition being treated.
          </p>
          <h2>Shape, Size, Send</h2>
          <GuideItemContainer>
            {guideItems.map((el) => {
              const { heading, icon, text } = el;
              return (
                <GuideItem
                  key={heading}
                  heading={heading}
                  icon={icon}
                  text={text}
                />
              );
            })}
          </GuideItemContainer>
          <PinInstructions setModalOpen={setModalOpen} />
          <QRCodeContainer>
            <h4>Launch the app on your phone by scanning the QR Code</h4>
            <img src={QRCode} alt="QR code" />
          </QRCodeContainer>
        </MainContent>
      </MainContentWrapper>
      <IntroGuideModal
        device={modalOpen}
        isOpen={modalOpen !== false}
        close={() => setModalOpen(false)}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  > img {
    height: 80px;
    width: 80px;
  }

  h1 {
    font-size: 24px;
    line-height: 36px;
    padding: 30px 24px 20px;
  }

  @media ${devices.desktop} {
    padding-top: 24px;

    h1 {
      text-align: center;
      max-width: 500px;
      font-size: 34px;
      line-height: 46px;
    }
  }
`;

const MainContentWrapper = styled.div`
  background-color: #ffffff;
  flex: 1;
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  color: #000000;
`;

const MainContent = styled.div`
  /* background-color: #ffffff;
  flex: 1;
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  color: #000000; */
  padding: 24px 16px;

  > p,
  > h2 {
    text-align: center;
  }
  h2 {
    margin-block: 24px;
    color: ${(props) => props.theme.colorTertiary};
    text-transform: uppercase;
  }

  @media ${devices.tablet} {
    max-width: 690px;
    margin-inline: auto;
  }
  @media ${devices.desktop} {
    max-width: 1130px;
    margin-inline: auto;
    padding: 40px 0px 0px;

    p {
      padding-inline: 16px;
    }
  }

  @media (min-width: 1162px) {
    p {
      padding-inline: 0px;
    }
  }
`;

const GuideItemContainer = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  @media ${devices.tablet} {
    flex-direction: row;
    column-gap: 16px;
  }

  @media ${devices.desktop} {
    padding-inline: 16px;
  }
`;

const QRCodeContainer = styled.div`
  display: none;
  max-width: 366px;
  margin-inline: auto;
  padding: 16px;
  flex-direction: column;
  row-gap: 16px;
  align-items: center;
  background: linear-gradient(206.12deg, #e20074 1.01%, #b9005f 100%);
  box-shadow: 0px 1px 2px rgba(60, 64, 67, 0.3),
    0px 2px 6px 2px rgba(60, 64, 67, 0.15);
  border-radius: 16px 16px 0px 0px;

  h4 {
    color: #ffffff;
    text-align: center;
  }

  img {
    width: 160px;
    height: 160px;
  }

  @media ${devices.desktop} {
    display: flex;
  }
`;
