import React, { useContext, useState } from "react";

const ResponseStateContext = React.createContext();
const ResponseDispatchContext = React.createContext();

export function ResponseProvider({ children }) {
  const [responses, setResponses] = useState({});

  return (
    <ResponseStateContext.Provider value={responses}>
      <ResponseDispatchContext.Provider value={setResponses}>
        {children}
      </ResponseDispatchContext.Provider>
    </ResponseStateContext.Provider>
  );
}

export function useResponses() {
  const responses = useContext(ResponseStateContext);
  const setResponses = useContext(ResponseDispatchContext);

  const updateResponse = (key, value) => {
    const newResponses = { ...responses, [key]: value };
    setResponses(newResponses);
  };

  const resetResponses = () => {
    setResponses({});
  };

  const getCompressionClass = () => {
    return responses.compression_class;
  };

  const getScore = () => {
    let symptomScore = 0;
    let limbScore = 0;

    const symptomScores = {
      VENOUS: 1,
      VENOUS_MILD_OEDEMA: 3,
      HEALED_VENOUS: 8,
      ACTIVE_VENOUS: 45,
      LYMPHOEDEMA: 21,
    };

    if (responses.symptoms) {
      for (let symptom in symptomScores) {
        if (responses.symptoms.indexOf(symptom) !== -1) {
          symptomScore += symptomScores[symptom];
        }
      }
    }

    const limbScores = {
      STRAIGHT_LEG: 0,
      NORMAL_SHAPE: 0,
      SHAPE_DISTORTION: 10,
    };

    if (responses.limb) {
      for (let limb in limbScores) {
        if (responses.limb === limb) {
          limbScore = limbScores[limb];
        }
      }
    }

    return symptomScore + limbScore;
  };

  return {
    responses,
    updateResponse,
    resetResponses,
    getScore,
    getCompressionClass,
  };
}
