import React from "react";
import styled from "styled-components";
import InstructionsIOS from "../../assets/guide-instruction-ios.png";
import InstructionsAndroid from "../../assets/guide-instruction-android.png";
import InstructionsIOSAndroid from "../../assets/guide-instruction-ios-android.png";
import { SecondaryButton } from "../../components/Button";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { devices } from "../../styles";

export default function PinInstructions({ setModalOpen }) {
  const { width } = useWindowDimensions();

  return width >= 768 ? (
    <PinInstructionsWrapper>
      <h3>Pin this app to your home screen, for easy access</h3>
      <List>
        <InstructionItem
          onClick={() => setModalOpen("ios")}
          role="button"
          tabIndex="0"
        >
          <h4>Instructions for iOS</h4>
          <Container>
            <img src={InstructionsIOS} alt="" />
          </Container>
        </InstructionItem>
        <InstructionItem
          onClick={() => setModalOpen("android")}
          role="button"
          tabIndex="0"
        >
          <h4>Instructions for Android</h4>
          <Container>
            <img src={InstructionsAndroid} alt="" />
          </Container>
        </InstructionItem>
      </List>
    </PinInstructionsWrapper>
  ) : (
    <MobilePinInstructions>
      <h4>Pin this app to your home screen, for easy access</h4>
      <Container>
        <img src={InstructionsIOSAndroid} alt="" />
        <ButtonContainer>
          <SecondaryButton onClick={() => setModalOpen("ios")} disabled={false}>
            iOS
          </SecondaryButton>
          <SecondaryButton
            onClick={() => setModalOpen("android")}
            disabled={false}
          >
            Android
          </SecondaryButton>
        </ButtonContainer>
      </Container>
    </MobilePinInstructions>
  );
}

const PinInstructionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin-bottom: 24px;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  margin-bottom: 16px;

  @media ${devices.desktop} {
    margin-bottom: 40px;
  }
`;

const InstructionItem = styled.div`
  border: 1px solid #d4ccbd;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 6px;
  padding-block: 16px;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 16px;

  h4 {
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    padding-inline: 16px;
  }

  img {
    width: 220px;
    height: 123px;
  }

  &:hover {
    border: 1px solid #e20074;
    box-shadow: 0px 1px 2px rgba(60, 64, 67, 0.3),
      0px 1px 3px 1px rgba(60, 64, 67, 0.15);
  }
  @media ${devices.tablet} {
    row-gap: unset;
    padding-block: 16px 0px;

    img {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  @media ${devices.desktop} {
    padding-block: 0px;

    img {
      width: 366px;
      height: 206px;
    }

    h4 {
      padding: 13px 26px;
    }
  }
`;

const MobilePinInstructions = styled(InstructionItem)`
  border: 1px solid #d4ccbd;
  border-radius: 6px;
  padding-block: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 16px;

  h3 {
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    padding-inline: 16px;
  }

  img {
    width: 187px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;
