import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import gsap from "gsap";
import ReactGA from "react-ga4";
import Answer from "../../components/Answer";
import { PrimaryButton, SecondaryButton } from "../../components/Button";
import { QuestionnaireHeader } from "../../components/Header";
import LeaveAppModal from "../../components/LeaveAppModal";
import ProductInfo from "../../components/ProductInfo";
import { useOffers } from "../../context/offerContext";
import SendEmailModal from "../../components/SendEmailModal";
import { useResponses } from "../../context/responseContext";
import { devices } from "../../styles";

import { questions, RESPONSE_SECTIONS } from "./questions";

const analyticsActionNames = {
  0: "Symptoms",
  1: "Leg shape",
  2: "Compression class",
  3: "Algorithm",
  4: "Garment selection",
  5: "Measurement input",
  6: "Color picker",
  7: "Product information",
};

export default function Questionnaire() {
  const history = useHistory();
  const [index, setIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const { responses, updateResponse, resetResponses, getScore } =
    useResponses();
  const { details, selected, resetSelected } = useOffers();
  const questionCount = 3;
  const [willOpenModal, setWillOpenModal] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isCombinedError, setIsCombinedError] = useState(false);
  const [updateMeasurements, setUpdateMeasurements] = useState(false);

  // fade in content on initial load
  useEffect(() => {
    gsap.fromTo(
      [...document.querySelectorAll("#content")],
      { opacity: 0 },
      {
        opacity: 1,
        duration: 0.5,
      }
    );
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", title: `${analyticsActionNames[index]}` });
  }, [index]);

  useEffect(() => {
    setUpdateMeasurements(false);
  }, [index]);

  useEffect(() => {
    if (index < questionCount) {
      if (selectedAnswers && selectedAnswers.length > 0) {
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
      }
    }
  }, [index, selectedAnswers]);

  // scroll to top when new question shows
  useEffect(() => {
    window.scrollTo(0, 0);
    setSelectedAnswers([]);
    if (index !== 5) {
      setShowError(false);
    }
  }, [index]);

  useEffect(() => {
    const section = questions[index].section;

    if (responses[section]) {
      setSelectedAnswers(responses[section]);
    }
  }, [index, responses]);

  const handleAnswerClick = (answerKey) => {
    const { multipleAnswers } = questions[index];
    const isSelected = selectedAnswers
      ? selectedAnswers.includes(answerKey)
      : false;

    if (!multipleAnswers) {
      if (isSelected) {
        ReactGA.event({
          category: "User",
          action: "Answer unselected",
        });
        setSelectedAnswers(null);
      } else {
        ReactGA.event({
          category: "User",
          action: "Answer selected",
        });
        setSelectedAnswers(answerKey);
      }
    } else {
      if (isSelected) {
        ReactGA.event({
          category: "User",
          action: "Answer unselected",
        });
        setSelectedAnswers(
          selectedAnswers.filter((item) => item !== answerKey)
        );
      } else {
        setSelectedAnswers([...selectedAnswers, answerKey]);
        ReactGA.event({
          category: "User",
          action: "Answer selected",
        });
      }
    }
  };

  // return to start of questions and remove all saved data
  const reset = () => {
    ReactGA.event({
      category: "User",
      action: "Reset user responses",
    });
    setIndex(0);
    resetResponses();
    setSelectedAnswers([]);
    resetSelected();
    setWillOpenModal(false);
  };

  const updateResponses = (value) => {
    const key = questions[index].section;
    if (key) {
      updateResponse(key, value); // update responses inside the responsesContext
    }
  };

  // TODO: at the moment the user can click the button multiple times while animating and it will run the animation multiple times
  // need to disable the buttons after being clicked until animation finished/next screen loaded
  // when the question index changes, fade out the current content and fade in the new content
  const fadeOutInAnimation = (
    onCompleteFn,
    increment = 1,
    runCompleteAnimation = true
  ) => {
    gsap.fromTo(
      [...document.querySelectorAll("#content")],
      { opacity: 1 },
      {
        opacity: 0,
        duration: 0.5,
        onComplete: () => {
          onCompleteFn && onCompleteFn(increment);
          if (runCompleteAnimation) {
            gsap.fromTo(
              [...document.querySelectorAll("#content")],
              { opacity: 0 },
              {
                opacity: 1,
                duration: 0.5,
              }
            );
          }
        },
      }
    );
  };

  const handleNextButtonClick = () => {
    const onComplete = (increment = 1) => {
      updateResponses(selectedAnswers);
      setSelectedAnswers([]);
      setIndex(index + increment);
    };

    // if on measurements screen, show error if there is one, otherwise move to next section
    if (index === 5) {
      if (isError) {
        setShowError(true);
      } else {
        // update measurements state in the repsonses context - this will set the color from the Measurements component
        // in the responses context without potentially overwriting previous setState calls
        setUpdateMeasurements(true);

        // if no color or toe type to selected, skip the ColorPicker and move straight to the ProductInfo screen.
        const skipColorPicker =
          (selected.colour.length === 0 && selected.toe_type.length === 0) ||
          (details.colors.length === 1 && details.toe.length === 1);

        if (skipColorPicker && !responses[RESPONSE_SECTIONS.TOE_TYPE]) {
          updateResponse(RESPONSE_SECTIONS.TOE_TYPE, details.toe[0]);
        }

        if (skipColorPicker) {
          fadeOutInAnimation(onComplete, 2, true);
        } else {
          fadeOutInAnimation(onComplete, 1, true);
        }
      }
    } else {
      if (!willOpenModal) {
        fadeOutInAnimation(onComplete);
      } else {
        setModalOpen("download");
      }
    }
  };

  const handlePrevButtonClick = () => {
    updateResponses(null);
    const onComplete = () => {
      if (index === 0) {
        history.push("/intro");
      } else if (index === questionCount + 1) {
        setIndex(index - 2);
      } else {
        setIndex(index - 1);
        setSelectedAnswers([]);
      }
    };

    fadeOutInAnimation(onComplete, index === 0 ? false : true);
  };

  return (
    <Wrapper>
      <QuestionnaireHeader
        reset={reset}
        index={index}
        length={questions.length}
      />
      {index === questions.length - 1 ? (
        <ProductInfo
          openModal={() => setModalOpen("email")}
          returnToStart={reset}
          modalOpen={modalOpen}
        />
      ) : (
        <QuestionContainer id="content">
          <h1 id="heading">
            {selected
              ? `${questions[index].question.replace(
                  /\{{(.+?)\}}/g,
                  selected.displayName
                )}`
              : `${questions[index].question}`}
          </h1>
          <p
            id="subheading"
            dangerouslySetInnerHTML={{ __html: questions[index].subheading }}
          ></p>
          {questions[index].component ? (
            React.createElement(questions[index].component, {
              goToNext: () => setIndex((i) => i + 1),
              setButtonDisabled: setButtonDisabled,
              setWillOpenModal: setWillOpenModal,
              showError: showError,
              setShowError: setShowError,
              setIsError: setIsError,
              setIsCombinedError: setIsCombinedError,
              updateMeasurements: updateMeasurements,
            })
          ) : (
            <AnswerContainer id="answer-container" index={index}>
              {index ===
                0 /** This is the symptoms so split answers into columns */ && (
                <SymptomsWrapper>
                  <div>
                    {[...questions[index].answers.slice(0, 3)].map((answer) => {
                      const { key } = answer;
                      const isSelected = selectedAnswers
                        ? selectedAnswers.includes(key)
                        : false;

                      return (
                        <Answer
                          key={key}
                          section={questions[index].section}
                          isSelected={isSelected}
                          selectAnswer={() => handleAnswerClick(key)}
                          heading={answer.heading}
                          subheading={answer.subheading}
                          image={answer.image}
                          body={answer.body}
                        />
                      );
                    })}
                  </div>
                  <div>
                    {[...questions[index].answers.slice(3)].map((answer) => {
                      const { key } = answer;
                      const isSelected = selectedAnswers
                        ? selectedAnswers.includes(key)
                        : false;

                      return (
                        <Answer
                          key={key}
                          section={questions[index].section}
                          isSelected={isSelected}
                          selectAnswer={() => handleAnswerClick(key)}
                          heading={answer.heading}
                          subheading={answer.subheading}
                          image={answer.image}
                          body={answer.body}
                        />
                      );
                    })}
                  </div>
                </SymptomsWrapper>
              )}

              {index !== 0 &&
                index !==
                  2 /** This is neither symptoms nor compression class (index == 1) */ &&
                questions[index].answers.map((answer) => {
                  const { key } = answer;
                  const isSelected = selectedAnswers
                    ? selectedAnswers.includes(key)
                    : false;

                  return (
                    <Answer
                      key={key}
                      section={questions[index].section}
                      isSelected={isSelected}
                      selectAnswer={() => handleAnswerClick(key)}
                      heading={answer.heading}
                      subheading={answer.subheading}
                      image={answer.image}
                      body={answer.body}
                    />
                  );
                })}

              {index === 2 &&
                getScore() ==
                  1 /** This is compression class and score is 1 */ &&
                questions[index].answers.slice(0, 2).map((answer) => {
                  const { key } = answer;
                  const isSelected = selectedAnswers
                    ? selectedAnswers.includes(key)
                    : false;

                  return (
                    <Answer
                      key={key}
                      section={questions[index].section}
                      isSelected={isSelected}
                      selectAnswer={() => handleAnswerClick(key)}
                      heading={answer.heading}
                      subheading={answer.subheading}
                      image={answer.image}
                      body={answer.body}
                    />
                  );
                })}

              {index === 2 &&
                getScore() >
                  1 /** This is compression class and score is > 1 */ &&
                questions[index].answers.slice(2).map((answer) => {
                  const { key } = answer;
                  const isSelected = selectedAnswers
                    ? selectedAnswers.includes(key)
                    : false;

                  return (
                    <Answer
                      key={key}
                      section={questions[index].section}
                      isSelected={isSelected}
                      selectAnswer={() => handleAnswerClick(key)}
                      heading={answer.heading}
                      subheading={answer.subheading}
                      image={answer.image}
                      body={answer.body}
                    />
                  );
                })}
            </AnswerContainer>
          )}
          {
            <ErrorContainer>
              {showError &&
                `The ${
                  isCombinedError ? "combined dimensions" : "dimensions"
                } entered do not match the specifications for ${
                  selected.displayName
                }; please check the measurements you have uploaded, and either enter ‘back’ to select an alternative product, or contact the medi clinical team for advice via the email enquiries.clinical@mediuk.co.uk`}
            </ErrorContainer>
          }
        </QuestionContainer>
      )}
      {index !== questionCount && index !== questions.length - 1 && (
        <ButtonContainer>
          <SecondaryButton
            onClick={() => {
              handlePrevButtonClick();
            }}
          >
            Back
          </SecondaryButton>
          <PrimaryButton
            disabled={buttonDisabled}
            onClick={() => {
              handleNextButtonClick();
            }}
          >
            Next
          </PrimaryButton>
        </ButtonContainer>
      )}
      <LeaveAppModal
        isOpen={modalOpen === "download"}
        close={() => setModalOpen(false)}
      />
      <SendEmailModal
        isOpen={modalOpen === "email"}
        close={() => setModalOpen(false)}
        reset={reset}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 80px;

  @media ${devices.tablet} {
    padding-top: 120px;
  }
`;

const QuestionContainer = styled.div`
  flex: 1;
  padding: 28px 16px;
  opacity: 0;

  h1 {
    color: ${(props) => props.theme.colorTertiary};
    margin-bottom: 24px;
  }

  > p {
    margin-bottom: 24px;
  }

  @media ${devices.tablet} {
    > p {
      margin-bottom: 0px;
    }
    padding: 72px;
  }
  @media ${devices.desktop} {
    max-width: 900px;
    width: 900px;
    margin: 0 auto;
    box-sizing: border-box;
  }
`;

const AnswerContainer = styled.div`
  @media ${devices.tablet} {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-top: 72px;
    align-items: flex-start;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 18px 94px;
  box-shadow: 0px 1px 2px rgba(60, 64, 67, 0.3),
    0px 2px 6px 2px rgba(60, 64, 67, 0.15);

  button {
    &:first-child {
      margin-right: 24px;
    }
  }
`;

const SymptomsWrapper = styled.div`
  > div {
    &:first-child {
      margin-bottom: 24px;
    }
  }
  @media ${devices.tablet} {
    display: flex;
    > div {
      margin: none;
      min-width: calc(50% - 24px);

      &:first-child {
        margin-right: 24px;
      }
    }
  }
`;

const ErrorContainer = styled.div`
  font-size: 12px;
  margin-top: 32px;
  margin-bottom: -32px;
  min-height: 40px;
  color: red;
`;
