import React, { useContext, useState } from "react";

const EmailStateContext = React.createContext();
const EmailDispatchContext = React.createContext();

export function EmailProvider({ children }) {
  const [emailInfo, setEmailInfo] = useState({});

  return (
    <EmailStateContext.Provider value={emailInfo}>
      <EmailDispatchContext.Provider value={setEmailInfo}>
        {children}
      </EmailDispatchContext.Provider>
    </EmailStateContext.Provider>
  );
}

export function useEmailInfo() {
  const emailInfo = useContext(EmailStateContext);
  const setEmailInfo = useContext(EmailDispatchContext);

  return {
    emailInfo,
    setEmailInfo,
  };
}
