import React from "react";
import { QuaternaryButton, TertiaryButton } from "../Button";
import Modal from "../Modal";
import Notification from "../../assets/notification.png";
import styled from "styled-components";
import { devices } from "../../styles";

export default function LeaveAppModal({ isOpen, close }) {
	const handleClick = () => {
		window.location = "https://m2mhosieryhunter.mediuk.co.uk/?src=hh";
	};

	return (
		<Modal leaveApp={true} isOpen={isOpen} close={close}>
			<img alt="" src={Notification} />
			<h3>
				You are about to leave this app and go to Hosiery Hunter&reg; made to measure
			</h3>
			<p>
				The selected garment is made to measure. You will be taken to the made
				to measure app to complete the details required to request the
				prescription and place the order.
			</p>
      <ButtonContainer>
        <QuaternaryButton onClick={() => close()}>Cancel</QuaternaryButton>
        <MadeToMeasureButton onClick={() => handleClick()}>
          Go to made to measure
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M21.8135 10.8994C21.2612 10.8994 20.8135 10.4517 20.8135 9.89941V5.24218L12.6212 13.4345C12.2306 13.825 11.5975 13.825 11.207 13.4345C10.8164 13.044 10.8164 12.4108 11.207 12.0203L19.3989 3.82834H14.7424C14.1901 3.82834 13.7424 3.38062 13.7424 2.82834C13.7424 2.27605 14.1901 1.82834 14.7424 1.82834L19.8135 1.82834C21.4703 1.82834 22.8135 3.17148 22.8135 4.82834V9.89941C22.8135 10.4517 22.3658 10.8994 21.8135 10.8994ZM5.5 3.99978C4.94772 3.99978 4.5 4.44749 4.5 4.99978V18.9998C4.5 19.5521 4.94772 19.9998 5.5 19.9998H19.5C20.0523 19.9998 20.5 19.5521 20.5 18.9998V15.9998C20.5 15.4475 20.9477 14.9998 21.5 14.9998C22.0523 14.9998 22.5 15.4475 22.5 15.9998V18.9998C22.5 20.6566 21.1569 21.9998 19.5 21.9998H5.5C3.84315 21.9998 2.5 20.6566 2.5 18.9998V4.99978C2.5 3.34293 3.84315 1.99978 5.5 1.99978H8.5C9.05228 1.99978 9.5 2.44749 9.5 2.99978C9.5 3.55206 9.05228 3.99978 8.5 3.99978H5.5Z" fill="white"/>
          </svg>
        </MadeToMeasureButton>
      </ButtonContainer>
		</Modal>
	);
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const MadeToMeasureButton = styled(TertiaryButton)`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 8px 10px 16px;
  margin-left: 16px !important;

  svg {
    width: 24px;
    height: 24px;
  }

  @media ${devices.tablet} {
    margin-left: 24px !important;
  }
`;