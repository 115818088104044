import React, { useContext, useState } from "react";

import MedivenElegance from '../assets/products/mediven_elegance.png';
import MedivenMen from '../assets/products/mediven_for_men.png';
import MedivenPlus from '../assets/products/mediven_plus.png';
import MedivenActive from '../assets/products/mediven_active.png';
import MedivenMondi from '../assets/products/mediven_mondi.png';
import MedivenCosy from '../assets/products/mediven_cosy.png';
import MedivenUlcer from '../assets/products/mediven_ulcer.png';
import DuomedSoft from '../assets/products/duomed_soft.png';
import DuomedSoft2Easy from '../assets/products/duomed_soft2easy.jpg';
import Juxtalite from '../assets/products/juxtalite.png';
import JuxtafitLowerLegging from '../assets/products/juxtafit_lower_legging.png';

import MeasurementImage_cBcC from '../assets/measurements/cBcC.png';
import MeasurementImage_cBcCAD from '../assets/measurements/cBcCAD.png';
import MeasurementImage_cBcCBD from '../assets/measurements/cBcCBD.png';
import MeasurementImage_cBcB1cCBD from '../assets/measurements/cBcB1cCBD.png';

const OfferStateContext = React.createContext();
const OfferDispatchContext = React.createContext();
const DetailsStateContext = React.createContext();
const DetailsDispatchContext = React.createContext();
const SelectedStateContext = React.createContext();
const SelectedDispatchContext = React.createContext();
const SelectedProductStateContext = React.createContext();
const SelectedProductDispatchContext = React.createContext();

const defaultDetails = {
  colors: [],
  toe: [],
};

export function OfferProvider({ children }) {
  const [offers, setOffers] = useState([]);
  const [selected, setSelected] = useState(null);
  const [details, setDetails] = useState(defaultDetails);
  const [selectedProduct, setSelectedProduct] = useState({left: null, right: null});

  return (
    <OfferStateContext.Provider value={offers}>
      <OfferDispatchContext.Provider value={setOffers}>
        <SelectedStateContext.Provider value={selected}>
          <SelectedDispatchContext.Provider value={setSelected}>
            <SelectedProductStateContext.Provider value={selectedProduct}>
              <SelectedProductDispatchContext.Provider value={setSelectedProduct}>
                <DetailsStateContext.Provider value={details}>
                  <DetailsDispatchContext.Provider value={setDetails}>
                    {children}
                  </DetailsDispatchContext.Provider>
                </DetailsStateContext.Provider>
              </SelectedProductDispatchContext.Provider>
            </SelectedProductStateContext.Provider>
          </SelectedDispatchContext.Provider>
        </SelectedStateContext.Provider>
      </OfferDispatchContext.Provider>
    </OfferStateContext.Provider>
  );
}

export function useOffers() {
  const offers = useContext(OfferStateContext);
  const setOffers = useContext(OfferDispatchContext);
  const selected = useContext(SelectedStateContext);
  const setSelected = useContext(SelectedDispatchContext);
  const details = useContext(DetailsStateContext);
  const setDetails = useContext(DetailsDispatchContext);
  const selectedProduct = useContext(SelectedProductStateContext);
  const setSelectedProduct = useContext(SelectedProductDispatchContext);

  const resetSelected = () => {
    setSelected(null);
  }

  return { offers, setOffers, selected, setSelected, details, setDetails, selectedProduct, setSelectedProduct, resetSelected };
}

export function getProductCollectionNames(score, compressionClass){
  let productCollectionNames = [];

  switch(true){
    case score === 1: 
      if (compressionClass === 'BSC_1'){
        productCollectionNames = [
          { 
            name:'Duomed soft', 
            displayName: 'duomed® soft', 
            img: DuomedSoft,
            desc:'<b>duomed® soft</b> is a British Standard class stocking and has a light, sheer fabric that is comfortable and easy to apply aiding patient acceptance. <b>duomed® soft</b> British Standard class I is suitable for immediate compression without an ABPI assessment and in the absence of red flags.', 
            isMadeToMeasure: false,
            measurementsRequired: [{name:'CC', range:[28,50]},{name:'CB', range:[19,34]}],
            measurementGuide: MeasurementImage_cBcC,
            colour:['Black','Sand'],
            toe_type:['Open','Closed'],
            
          },
          {
            name:'Duomed soft 2easy',
            displayName: 'duomed® soft 2easy®', 
            img: DuomedSoft2Easy,
            desc:'<b>duomed® soft 2easy®</b> is a British Standard class unique 2 piece stocking system for simple application supporting patient motivation and engagement. <b>duomed® soft 2easy®</b> British Standard class I is suitable for immediate compression without an ABPI assessment and in the absence of red flags.', 
            isMadeToMeasure: false,
            measurementsRequired: [{name:'CC', range:[28,50]},{name:'CB', range:[19,34]}],
            measurementGuide: MeasurementImage_cBcC,
            colour:['Black','Sand'],
            toe_type:['Closed']
          },
          {
            name:'Juxtalite',
            displayName: 'juxtalite®', 
            img: Juxtalite,
            desc:'<b>juxtalite®</b> wraps are suitable for legs that are swollen, poor shape with excessive exudate. Can be used over any type of dressing and any size leg ulcer including wet legs. <b>juxtalite®</b> allows you to set the compression for your patient within a range of 20–50 mmHg, using the unique Built-in Pressure System (BPS) and is guaranteed to provide effective compression for 6 months.', 
            isMadeToMeasure: false,
            measurementsRequired: [{name:'CC', range:[26,64]},{name:'CB', range:[19,42]}, {name:'measurement_BD', range:[28,33]}],
            measurementGuide: MeasurementImage_cBcCBD,
            colour:[],
            toe_type:[]
          }]; 
      } else {
        productCollectionNames = [
          { 
            name:'Duomed soft', 
            displayName: 'duomed® soft', 
            img: DuomedSoft,
            desc:'<b>duomed® soft</b> is a British Standard class stocking and has a light, sheer fabric that is comfortable and easy to apply aiding patient acceptance. <b>duomed® soft</b> British Standard class II is suitable for immediate compression without an ABPI assessment and in the absence of red flags.', 
            isMadeToMeasure: false,
            measurementsRequired: [{name:'CC', range:[28,50]},{name:'CB', range:[19,34]}],
            measurementGuide: MeasurementImage_cBcC,
            colour:['Black','Sand'],
            toe_type:['Open','Closed'],
            
          },
          {
            name:'Duomed soft 2easy',
            displayName: 'duomed® soft 2easy®', 
            img: DuomedSoft2Easy,
            desc:'<b>duomed® soft 2easy®</b> is a British Standard class unique 2 piece stocking system for simple application supporting patient motivation and engagement. <b>duomed® soft 2easy®</b> British Standard class II is suitable for immediate compression without an ABPI assessment and in the absence of red flags.', 
            isMadeToMeasure: false,
            measurementsRequired: [{name:'CC', range:[28,50]},{name:'CB', range:[19,34]}],
            measurementGuide: MeasurementImage_cBcC,
            colour:['Black','Sand'],
            toe_type:['Closed']
          },
          {
            name:'Juxtalite',
            displayName: 'juxtalite®', 
            img: Juxtalite,
            desc:'<b>juxtalite®</b> wraps are suitable for legs that are swollen, poor shape with excessive exudate. Can be used over any type of dressing and any size leg ulcer including wet legs. <b>juxtalite®</b> allows you to set the compression for your patient within a range of 20–50 mmHg, using the unique Built-in Pressure System (BPS) and is guaranteed to provide effective compression for 6 months.', 
            isMadeToMeasure: false,
            measurementsRequired: [{name:'CC', range:[26,64]},{name:'CB', range:[19,42]}, {name:'measurement_BD', range:[28,33]}],
            measurementGuide: MeasurementImage_cBcCBD,
            colour:[],
            toe_type:[]
          }]; 
      }
      break;
    case score>1 && score < 14:
      productCollectionNames = [
        {
          name:'Mediven Plus', 
          displayName: 'mediven plus®', 
          img: MedivenPlus,
          desc:'<b>mediven plus®</b> is RAL compression for mild to moderate conditions and is an open or closed toe garment. <b>mediven plus®</b> is suitable for those with mild to moderate venous oedema (leg swelling).', 
          isMadeToMeasure: false,
          measurementsRequired: [{name:'CC', range:[28,52]},{name:'CB', range:[18,32]}, {name:'ID', range:[33,44]}],
          measurementGuide: MeasurementImage_cBcCAD,
          colour:['Black','Biege'],
          toe_type:['Open', 'Closed'],
        },
        {
          name:'Elegance', 
          displayName: 'mediven elegance®', 
          img: MedivenElegance,
          desc:'<b>mediven elegance®</b> is the lightest RAL compression for mild conditions and is suitable for adults who do not show any signs of venous oedema (leg swelling).', 
          isMadeToMeasure: false,
          measurementsRequired: [{name:'CC', range:[28,51]},{name:'CB', range:[18,32]}, {name:'ID', range:[33, 44]}],
          measurementGuide: MeasurementImage_cBcCAD,
          colour:['Black','Biege'],
          toe_type:['Closed']
        },
        {
          name:'For Men', 
          displayName: 'mediven® for men', 
          img: MedivenMen,
          desc:'<b>mediven® for men</b> is a below knee RAL compression sock for mild to moderate conditions. It is suitable for those with a wide foot and/or a longer second toe as it has a generous toe sizing area. Ideal for those with a shoe size of 39–46. <b>mediven® for men</b> is recommended primarily for those with none or very mild venous oedema (leg swelling).', 
          isMadeToMeasure: false,
          measurementsRequired: [{name:'CC', range:[28,51]},{name:'CB', range:[18,32]}, {name:'ID', range:[33,44]}],
          measurementGuide: MeasurementImage_cBcCAD,
          colour:['Black','Grey','Navy'],
          toe_type:['Closed']
        }, 
        {
          name:'Active', 
          displayName: 'mediven active®', 
          img: MedivenActive,
          desc:'<b>mediven active®</b> is a below knee RAL compression sock for moderate to chronic conditions. It is suitable for those with a longer leg length and foot. Ideal for a shoe size of 38–48. <b>mediven active®</b> is suitable for those with mild to moderate venous oedema (leg swelling). ', 
          isMadeToMeasure: false,
          measurementsRequired: [{name:'CC', range:[28,51]},{name:'CB', range:[18,32]}, {name:'ID', range:[37,49]}],
          measurementGuide: MeasurementImage_cBcCAD,
          colour:['Black','Grey','White','Navy','Brown'],
          toe_type:['Closed']
        },
        {
          name:'Juxtalite', 
          displayName: 'juxtalite®', 
          img: Juxtalite,
          desc:'<b>juxtalite®</b> wraps are suitable for legs that are swollen, poor shape with excessive exudate. Can be used over any type of dressing and any size leg ulcer including wet legs. <b>juxtalite®</b> allows you to set the compression for your patient within a range of 20–50 mmHg, using the unique Built-in Pressure System (BPS) and is guaranteed to provide effective compression for 6 months.', 
          isMadeToMeasure: false,
          measurementsRequired: [{name:'CC', range:[26,61]},{name:'CB', range:[19,37]}, {name:'measurement_BD', range:[28,33]}],
          measurementGuide: MeasurementImage_cBcCBD,
          colour:[],
          toe_type:[]
        }]; 
      break;
    case score>=14 && score < 20:
      productCollectionNames = [
        {
          name:'Mediven Plus', 
          displayName: 'mediven plus®', 
          img: MedivenPlus,
          desc:'<b>mediven plus®</b> is RAL compression for mild to moderate conditions and is an open or closed toe garment. <b>mediven plus®</b> is suitable for those with mild to moderate venous oedema (leg swelling).', 
          isMadeToMeasure: false,
          measurementsRequired: [{name:'CC', range:[28,52]},{name:'CB', range:[18,32]}, {name:'ID', range:[33,44]}],
          measurementGuide: MeasurementImage_cBcCAD,
          colour:[],
          toe_type:['Open', 'Closed']
        },
        {
          name:'Juxtalite', 
          displayName: 'juxtalite®', 
          img: Juxtalite,
          desc:'<b>juxtalite®</b> wraps are suitable for legs that are swollen, poor shape with excessive exudate. Can be used over any type of dressing and any size leg ulcer including wet legs. <b>juxtalite®</b> allows you to set the compression for your patient within a range of 20–50 mmHg, using the unique Built-in Pressure System (BPS) and is guaranteed to provide effective compression for 6 months.', 
          isMadeToMeasure: false,
          measurementsRequired: [{name:'CC', range:[26,61]},{name:'CB', range:[19,37]}, {name:'measurement_BD', range:[28,33]}],
          measurementGuide: MeasurementImage_cBcCBD,
          colour:[],
          toe_type:[]
        }]; 
      break;
    case score>=20 && score < 45:
      productCollectionNames = [
        {
          name:'Mediven Mondi', 
          displayName: 'mediven mondi®', 
          img: MedivenMondi,
          desc:'<b>mediven mondi®</b> is a RAL flat knit made to measure material used in the treatment of mild to moderate chronic oedema and lymphoedema.', 
          isMadeToMeasure: true,
          measurementsRequired: [],
          measurementGuide: '',
          colour:[],
          toe_type:[]
        },
        {
          name:'Mediven cosy', 
          displayName: 'mediven® cosy', 
          img: MedivenCosy,
          desc:'<b>mediven® cosy</b> is a RAL flat knit made to measure fabric used in the treatment of moderate to complex lymphoedema and lipodema.', 
          isMadeToMeasure: true,
          measurementsRequired: [],
          measurementGuide: '',
          colour:[],
          toe_type:[]
        },
        {
          name:'Juxtafit Lower Legging', 
          displayName: 'juxtafit® lower legging', 
          img: JuxtafitLowerLegging,
          desc:'<b>juxtafit® lower legging</b> is suitable for the reduction or maintenance of limb volume in chronic oedema and lymphoedema. <b>juxtafit® lower legging</b> allows you to set the compression for your patient within a range of 20–50 mmHg, using the unique Built-in Pressure System (BPS) and is guaranteed to provide effective compression for 12 months.', 
          isMadeToMeasure: false,
          measurementsRequired: [{name:'CC', range:[30,70]},{name:'measurement_CB1', range:[20,60]},{name:'CB', range:[15,52]},{name:'measurement_BD', range:[28,36]}],
          measurementGuide: MeasurementImage_cBcB1cCBD,
          colour:[],
          toe_type:[]
        }]; 
      break;
    case score>=45 && score < 61:
      if (compressionClass === 'RAL_1'){
        productCollectionNames = [
          {
            name:'ulcer kit', 
            displayName: 'mediven® ulcer kit', 
            img: MedivenUlcer,
            desc:'For small to moderate size venous leg ulcers with low to moderate exudate. When low compression is required (class 1), use only one layer of the mediven ulcer kit.', 
            isMadeToMeasure: false,
            measurementsRequired: [{name:'CC', range:[28,51]},{name:'CB', range:[18,32]}, {name:'ID', range:[34,44]}],
            measurementGuide: MeasurementImage_cBcCAD,
            colour:[],
            toe_type:[]
          },
          {
            name:'Juxtalite', 
            displayName: 'juxtalite®', 
            img: Juxtalite,
            desc:'<b>juxtalite®</b> wraps are suitable for legs that are swollen, poor shape with excessive exudate. Can be used over any type of dressing and any size leg ulcer including wet legs. <b>juxtalite®</b> allows you to set the compression for your patient within a range of 20–50 mmHg, using the unique Built-in Pressure System (BPS) and is guaranteed to provide effective compression for 6 months.', 
            isMadeToMeasure: false,
            measurementsRequired: [{name:'CC', range:[26,61]},{name:'CB', range:[19,37]}, {name:'measurement_BD', range:[28,33]}],
            measurementGuide: MeasurementImage_cBcCBD,
            colour:[],
            toe_type:[]
          }
        ]; 
      } else {
        productCollectionNames = [
          {
            name:'ulcer kit', 
            displayName: 'mediven® ulcer kit', 
            img: MedivenUlcer,
            desc:'For small to moderate sized venous leg ulcers with low to moderate exudate. ', 
            isMadeToMeasure: false,
            measurementsRequired: [{name:'CC', range:[28,51]},{name:'CB', range:[18,32]}, {name:'ID', range:[34,44]}],
            measurementGuide: MeasurementImage_cBcCAD,
            colour:[],
            toe_type:[]
          },
          {
            name:'Juxtalite', 
            displayName: 'juxtalite®', 
            img: Juxtalite,
            desc:'<b>juxtalite®</b> wraps are suitable for legs that are swollen, poor shape with excessive exudate. Can be used over any type of dressing and any size leg ulcer including wet legs. <b>juxtalite®</b> allows you to set the compression for your patient within a range of 20–50 mmHg, using the unique Built-in Pressure System (BPS) and is guaranteed to provide effective compression for 6 months.', 
            isMadeToMeasure: false,
            measurementsRequired: [{name:'CC', range:[26,61]},{name:'CB', range:[19,37]}, {name:'measurement_BD', range:[28,33]}],
            measurementGuide: MeasurementImage_cBcCBD,
            colour:[],
            toe_type:[]
          }
        ]; 

      }
      break;
    default:
      productCollectionNames = [
        {
          name:'Mediven Mondi', 
          displayName: 'mediven mondi®', 
          img: MedivenMondi,
          desc:'<b>mediven mondi®</b> is a RAL flat knit made to measure material used in the treatment of mild to moderate chronic oedema and lymphoedema.', 
          isMadeToMeasure: true,
          measurementsRequired: [],
          measurementGuide: '',
          colour:[],
          toe_type:[]
        },
        {
          name:'Mediven cosy', 
          displayName: 'mediven® cosy', 
          img: MedivenCosy,
          desc:'<b>mediven® cosy</b> is a RAL flat knit made to measure fabric used in the treatment of moderate to complex lymphoedema and lipodema.', 
          isMadeToMeasure: true,
          measurementsRequired: [],
          measurementGuide: '',
          colour:[],
          toe_type:[]
        },
        {
          name:'Juxtafit Lower Legging', 
          displayName: 'juxtafit® lower legging', 
          img: JuxtafitLowerLegging,
          desc:'<b>juxtafit® lower legging</b> is suitable for the reduction or maintenance of limb volume in chronic oedema and lymphoedema. <b>juxtafit® lower legging</b> allows you to set the compression for your patient within a range of 20–50 mmHg, using the unique Built-in Pressure System (BPS) and is guaranteed to provide effective compression for 12 months.', 
          isMadeToMeasure: false,
          measurementsRequired: [{name:'CC', range:[30,70]},{name:'CB', range:[15,52]},{name:'measurement_CB1', range:[20,60]},{name:'measurement_BD', range:[28,36]}],
          measurementGuide: MeasurementImage_cBcB1cCBD,
          colour:[],
          toe_type:[]
        }
      ]; 
      break;  
  } 

  if(compressionClass === 'RAL_3'){
    // Mediven Plus or Juxta products only
    for (let i = 0; i < productCollectionNames.length; i++){
      const item = productCollectionNames[i];

      for (var prop in item){
        if (prop === 'name'){
          const name = item[prop];

          if (name.match(/Duomed soft/) !== null) {
            productCollectionNames.splice(i, 1);
            i--;
          }
          else if (name.match(/Duomed soft 2easy/) !== null) {
            productCollectionNames.splice(i, 1);
            i--;
          }
          else if (name.match(/Elegance/) !== null) {
            productCollectionNames.splice(i, 1);
            i--;
          }
          else if (name.match(/For Men/) !== null) {
            productCollectionNames.splice(i, 1);
            i--;
          }
          else if (name.match(/Active/) !== null) {
            productCollectionNames.splice(i, 1);
            i--; 
          }
          // if (prop.match(/Ulcer kit/) !== null) {
          //   delete item[prop];    
          // }
        }

      }
    }

  }

  return productCollectionNames;

}

export function  getUniqueFeatures(list, feature){

  return [...new Map(list.map(product =>
    [product[feature], product[feature]])).values()];
}

export function getAllProducts(productCollectionName,
  measurement_CC, 
  measurement_CB, 
  measurement_AD, 
  measurement_CB1, 
  measurement_BD,
  colour,
  toe_type,
  compressionClass
  ){
  const products = getUniqueProduct(productCollectionName,
    measurement_CC, 
    measurement_CB, 
    measurement_AD, 
    measurement_CB1, 
    measurement_BD,
    colour,
    toe_type,
    compressionClass,
    true);

    return products;
}

export function getUniqueProduct(productCollectionName,
  measurement_CC, 
  measurement_CB, 
  measurement_AD, 
  measurement_CB1, 
  measurement_BD,
  colour,
  toe_type,
  compressionClass,
  returnAll = false
  ){

    let filteredProducts = [];

    // match by name
    if(productCollectionName !== null){
      filteredProducts = productsMap.filter(function(product){
        return product.productCollection.toLowerCase() === productCollectionName.toLowerCase();
      });
    }else{
      return ['error','You must specify a product collection name']
    }

    // match by colour:
    if(colour != null){
      filteredProducts = filteredProducts.filter(function(product){
        return product.colour.indexOf(colour) !== -1;
      });
    }

    // match by 'toe type'
    if(toe_type !== null){
      filteredProducts = filteredProducts.filter(function(product){
        return product.toe_type.indexOf(toe_type) !== -1;
      });
    }


    // match by compression class
    if(compressionClass != null){
      filteredProducts = filteredProducts.filter(function(product){
        // if product doesn't have a ccl set, then always include it, otherwise check the compression classes match
        return product.ccl === null || (product.ccl === Math.abs(compressionClass.split('_')[1]));
      });
    }

    // Filter by sizes based on ranges (socks go up a size; wraps go down size)

    
    // BUT... If it's a wrap, so we want the SMALLER variant
    if(productCollectionName.toLowerCase().indexOf('juxta') !== -1){

      // filter by measurement_CC:
      if(measurement_CC !== null){
        filteredProducts = filteredProducts.filter(function(product){
          let ranges = product.measurement_CC.split('-');
          let lowerRange = ranges[0];
          let upperRange = ranges[1];
          return measurement_CC >= lowerRange && measurement_CC <= upperRange;
        });
      }

      // filter by measurement_CB:
      if(measurement_CB !== null){
        filteredProducts = filteredProducts.filter(function(product){
          let ranges = product.measurement_CB.split('-');
          let lowerRange = ranges[0];
          let upperRange = ranges[1];
          return measurement_CB >= lowerRange && measurement_CB <= upperRange;
        });
      }

      // filter by measurement_CB1:
      if(measurement_CB1 !== null){
        filteredProducts = filteredProducts.filter(function(product){
          let ranges = product.measurement_CB1.split('-');
          let lowerRange = ranges[0];
          let upperRange = ranges[1];
          return measurement_CB1 >= lowerRange && measurement_CB1 <= upperRange;
        });
      }

      // filter by measurement_BD:
      if(measurement_BD !== null){
        filteredProducts = filteredProducts.filter(function(product){
          return measurement_BD === product.measurement_BD;
        });
      }

      if(filteredProducts.length > 1){ // if there is more than one selection always select the lower size
        filteredProducts = filteredProducts[0];
      }

    }else{
        // filter by measurement_CC:
        if(measurement_CC !== null){
          filteredProducts = filteredProducts.filter(function(product){
            let ranges = product.measurement_CC.split('-');
            let lowerRange = ranges[0];
            let upperRange = ranges[1];
            return measurement_CC >= lowerRange && measurement_CC <= upperRange;
          });
        }

        // filter by measurement_CB:
        if(measurement_CB !== null){
          filteredProducts = filteredProducts.filter(function(product){
            let ranges = product.measurement_CB.split('-');
            let lowerRange = ranges[0];
            let upperRange = ranges[1];
            return measurement_CB >= lowerRange && measurement_CB <= upperRange;
          });
        }

        // filter by measurement_AD:
        if(measurement_AD !== null){
          filteredProducts = filteredProducts.filter(function(product){
            let ranges = product.measurement_AD.split('-');
            let lowerRange = ranges[0];
            let upperRange = ranges[1];
            return measurement_AD >= lowerRange && measurement_AD <= upperRange;
          });
        }

        if (!returnAll){
          if(filteredProducts.length > 1){ // if there is more than one selection always big the last (bigger item)
            filteredProducts = filteredProducts.splice(filteredProducts.length-1)[0];
          }
        }
      }
      
      if(filteredProducts.length === 0 ){
        return ['error','No products found matching the search criteria.']
      }
      
      // don't return array, just return unique product object
      if (filteredProducts.length === 1 && returnAll === false){
        filteredProducts = filteredProducts[0];
      }
    
    return filteredProducts;

}

export function getProductVariants( productCollectionName ){

    let filteredProducts = productsMap.filter(function(product){
      return product.productCollection.toLowerCase() === productCollectionName.toLowerCase();
    });
   
    return filteredProducts;
}

const productsMap = 
[
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102/0/I',
    size: 'I',
    pip: '323-5678',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102/0/II',
    size: 'II',
    pip: '323-5686',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102/0/III',
    size: 'III',
    pip: '323-5694',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102/0/IV',
    size: 'IV',
    pip: '323-5702',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102/0/V',
    size: 'V',
    pip: '323-5710',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102/0/VI',
    size: 'VI',
    pip: '323-5728',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102/0/VII',
    size: 'VII',
    pip: '323-5736',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '34-38',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104/0/I',
    size: 'I',
    pip: '323-5744',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '37-41',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104/0/II',
    size: 'II',
    pip: '323-5751',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '40-44',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104/0/III',
    size: 'III',
    pip: '323-5769',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '42-46',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104/0/IV',
    size: 'IV',
    pip: '323-5777',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '44-48',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104/0/V',
    size: 'V',
    pip: '323-5785',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '46-50',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104/0/VI',
    size: 'VI',
    pip: '323-5793',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '48-52',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104/0/VII',
    size: 'VII',
    pip: '323-5801',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101/0/I',
    size: 'I',
    pip: '215-6016',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101/0/II',
    size: 'II',
    pip: '323-5405',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101/0/III',
    size: 'III',
    pip: '323-5413',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101/0/IV',
    size: 'IV',
    pip: '323-5421',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101/0/V',
    size: 'V',
    pip: '323-5439',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101/0/VI',
    size: 'VI',
    pip: '323-5447',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101/0/VII',
    size: 'VII',
    pip: '323-5454',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '34-38',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103/0/I',
    size: 'I',
    pip: '323-5462',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '37-41',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103/0/II',
    size: 'II',
    pip: '323-5470',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '40-44',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103/0/III',
    size: 'III',
    pip: '323-5488',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '42-46',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103/0/IV',
    size: 'IV',
    pip: '323-5496',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '44-48',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103/0/V',
    size: 'V',
    pip: '323-5504',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '46-50',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103/0/VI',
    size: 'VI',
    pip: '323-5512',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '48-52',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103/0/VII',
    size: 'VII',
    pip: '323-5520',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102/5/I',
    size: 'I',
    pip: '323-5819',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102/5/II',
    size: 'II',
    pip: '323-5827',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102/5/III',
    size: 'III',
    pip: '323-5835',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102/5/IV',
    size: 'IV',
    pip: '323-5843',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102/5/V',
    size: 'V',
    pip: '323-5850',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102/5/VI',
    size: 'VI',
    pip: '323-5868',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102/5/VII',
    size: 'VII',
    pip: '323-5876',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '34-38',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104/5/I',
    size: 'I',
    pip: '323-5884',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '37-41',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104/5/II',
    size: 'II',
    pip: '323-5892',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '40-44',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104/5/III',
    size: 'III',
    pip: '323-5900',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '42-46',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104/5/IV',
    size: 'IV',
    pip: '323-5918',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '44-48',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104/5/V',
    size: 'V',
    pip: '323-5926',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '46-50',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104/5/VI',
    size: 'VI',
    pip: '323-5934',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '48-52',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104/5/VII',
    size: 'VII',
    pip: '323-5942',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101/5/I',
    size: 'I',
    pip: '323-5538',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101/5/II',
    size: 'II',
    pip: '323-5546',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101/5/III',
    size: 'III',
    pip: '323-5553',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101/5/IV',
    size: 'IV',
    pip: '323-5561',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101/5/V',
    size: 'V',
    pip: '323-5579',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101/5/VI',
    size: 'VI',
    pip: '323-5587',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101/5/VII',
    size: 'VII',
    pip: '323-5595',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '34-38',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103/5/I',
    size: 'I',
    pip: '323-5603',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '37-41',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103/5/II',
    size: 'II',
    pip: '323-5611',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '40-44',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103/5/III',
    size: 'III',
    pip: '323-5629',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '42-46',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103/5/IV',
    size: 'IV',
    pip: '323-5637',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '44-48',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103/5/V',
    size: 'V',
    pip: '323-5645',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '46-50',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103/5/VI',
    size: 'VI',
    pip: '323-5652',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '48-52',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103/5/VII',
    size: 'VII',
    pip: '323-5660',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202/0/I',
    size: 'I',
    pip: '323-6239',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202/0/II',
    size: 'II',
    pip: '323-6247',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202/0/III',
    size: 'III',
    pip: '323-6254',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202/0/IV',
    size: 'IV',
    pip: '323-6262',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202/0/V',
    size: 'V',
    pip: '323-6270',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202/0/VI',
    size: 'VI',
    pip: '323-6288',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202/0/VII',
    size: 'VII',
    pip: '323-6296',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '34-38',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204/0/I',
    size: 'I',
    pip: '323-6304',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '37-41',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204/0/II',
    size: 'II',
    pip: '323-6312',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '40-44',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204/0/III',
    size: 'III',
    pip: '323-6320',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '42-46',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204/0/IV',
    size: 'IV',
    pip: '323-6338',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '44-48',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204/0/V',
    size: 'V',
    pip: '323-6346',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '46-50',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204/0/VI',
    size: 'VI',
    pip: '323-6353',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '48-52',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204/0/VII',
    size: 'VII',
    pip: '323-6361',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201/0/I',
    size: 'I',
    pip: '033-5752',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201/0/II',
    size: 'II',
    pip: '323-5959',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201/0/III',
    size: 'III',
    pip: '323-5967',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201/0/IV',
    size: 'IV',
    pip: '323-5975',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201/0/V',
    size: 'V',
    pip: '323-5983',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201/0/VI',
    size: 'VI',
    pip: '323-5991',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201/0/VII',
    size: 'VII',
    pip: '323-6007',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '34-38',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203/0/I',
    size: 'I',
    pip: '215-6024',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '37-41',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203/0/II',
    size: 'II',
    pip: '323-6023',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '40-44',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203/0/III',
    size: 'III',
    pip: '323-6031',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '42-46',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203/0/IV',
    size: 'IV',
    pip: '323-6049',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '44-48',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203/0/V',
    size: 'V',
    pip: '323-6056',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '46-50',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203/0/VI',
    size: 'VI',
    pip: '323-6064',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '48-52',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203/0/VII',
    size: 'VII',
    pip: '323-6072',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202/5/I',
    size: 'I',
    pip: '323-6379',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202/5/II',
    size: 'II',
    pip: '323-6387',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202/5/III',
    size: 'III',
    pip: '323-6395',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202/5/IV',
    size: 'IV',
    pip: '323-6403',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202/5/V',
    size: 'V',
    pip: '323-6411',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202/5/VI',
    size: 'VI',
    pip: '323-6429',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202/5/VII',
    size: 'VII',
    pip: '323-6437',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '34-38',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204/5/I',
    size: 'I',
    pip: '323-6445',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '37-41',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204/5/II',
    size: 'II',
    pip: '323-6452',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '40-44',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204/5/III',
    size: 'III',
    pip: '323-6460',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '42-46',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204/5/IV',
    size: 'IV',
    pip: '323-6478',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '44-48',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204/5/V',
    size: 'V',
    pip: '323-6486',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '46-50',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204/5/VI',
    size: 'VI',
    pip: '323-6494',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'xw',
    measurement_CC: '48-52',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204/5/VII',
    size: 'VII',
    pip: '323-6502',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201/5/I',
    size: 'I',
    pip: '323-6080',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201/5/II',
    size: 'II',
    pip: '323-6098',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201/5/III',
    size: 'III',
    pip: '323-6106',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201/5/IV',
    size: 'IV',
    pip: '323-6114',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201/5/V',
    size: 'V',
    pip: '323-6122',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201/5/VI',
    size: 'VI',
    pip: '323-6130',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201/5/VII',
    size: 'VII',
    pip: '323-6148',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '34-38',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203/5/I',
    size: 'I',
    pip: '323-6155',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '37-41',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203/5/II',
    size: 'II',
    pip: '323-6163',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '40-44',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203/5/III',
    size: 'III',
    pip: '323-6171',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '42-46',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203/5/IV',
    size: 'IV',
    pip: '323-6189',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '44-48',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203/5/V',
    size: 'V',
    pip: '323-6197',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '46-50',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203/5/VI',
    size: 'VI',
    pip: '323-6213',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '48-52',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203/5/VII',
    size: 'VII',
    pip: '323-6221',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402/5/I',
    size: 'I',
    pip: '323-6783',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402/5/II',
    size: 'II',
    pip: '323-6791',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402/5/III',
    size: 'III',
    pip: '323-6809',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402/5/IV',
    size: 'IV',
    pip: '323-6817',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402/5/V',
    size: 'V',
    pip: '323-6825',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402/5/VI',
    size: 'VI',
    pip: '323-6833',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402/5/VII',
    size: 'VII',
    pip: '323-6841',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401/5/I',
    size: 'I',
    pip: '323-6643',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401/5/II',
    size: 'II',
    pip: '323-6650',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401/5/III',
    size: 'III',
    pip: '323-6668',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401/5/IV',
    size: 'IV',
    pip: '323-6676',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401/5/V',
    size: 'V',
    pip: '323-6684',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401/5/VI',
    size: 'VI',
    pip: '323-6692',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401/5/VII',
    size: 'VII',
    pip: '323-6700',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '34-38',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT421/5/I',
    size: 'I',
    pip: null,
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '37-41',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT421/5/II',
    size: 'II',
    pip: null,
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '40-44',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT421/5/III',
    size: 'III',
    pip: null,
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '42-46',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT421/5/IV',
    size: 'IV',
    pip: null,
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '44-48',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT421/5/V',
    size: 'V',
    pip: null,
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '46-50',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT421/5/VI',
    size: 'VI',
    pip: null,
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '48-52',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT421/5/VII',
    size: 'VII',
    pip: null,
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402/0/I',
    size: 'I',
    pip: '323-6718',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402/0/II',
    size: 'II',
    pip: '323-6726',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402/0/III',
    size: 'III',
    pip: '323-6734',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402/0/IV',
    size: 'IV',
    pip: '323-6742',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402/0/V',
    size: 'V',
    pip: '323-6759',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402/0/VI',
    size: 'VI',
    pip: '323-6767',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402/0/VII',
    size: 'VII',
    pip: '323-6775',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401/0/I',
    size: 'I',
    pip: '033-5984',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401/0/II',
    size: 'II',
    pip: '323-6510',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401/0/III',
    size: 'III',
    pip: '323-6528',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401/0/IV',
    size: 'IV',
    pip: '323-6536',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401/0/V',
    size: 'V',
    pip: '323-6544',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401/0/VI',
    size: 'VI',
    pip: '323-6551',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401/0/VII',
    size: 'VII',
    pip: '323-6569',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '34-38',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT421/0/I',
    size: 'I',
    pip: '323-6577',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '37-41',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT421/0/II',
    size: 'II',
    pip: '323-6585',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '40-44',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT421/0/III',
    size: 'III',
    pip: '323-6593',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '42-46',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT421/0/IV',
    size: 'IV',
    pip: '323-6601',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '44-48',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT421/0/V',
    size: 'V',
    pip: '323-6619',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '46-50',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT421/0/VI',
    size: 'VI',
    pip: '323-6627',
    toe_type: 'open toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'xw',
    measurement_CC: '48-52',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT421/0/VII',
    size: 'VII',
    pip: '323-6635',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT186/0/',
    size: 'I',
    pip: '338-9103',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT186/0/',
    size: 'II',
    pip: '338-9145',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT186/0/',
    size: 'III',
    pip: '338-9152',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT186/0/',
    size: 'IV',
    pip: '338-9160',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT186/0/',
    size: 'V',
    pip: '338-9178',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT186/0/',
    size: 'VI',
    pip: '338-9186',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT186/0/',
    size: 'VII',
    pip: '338-9194',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT185/0/',
    size: 'I',
    pip: '338-8964',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT185/0/',
    size: 'II',
    pip: '338-8972',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT185/0/',
    size: 'III',
    pip: '338-8980',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT185/0/',
    size: 'IV',
    pip: '338-8998',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT185/0/',
    size: 'V',
    pip: '338-9004',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT185/0/',
    size: 'VI',
    pip: '338-9012',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT185/0/',
    size: 'VII',
    pip: '338-9020',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT186/5/',
    size: 'I',
    pip: '338-9202',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT186/5/',
    size: 'II',
    pip: '338-9210',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT186/5/',
    size: 'III',
    pip: '338-9228',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT186/5/',
    size: 'IV',
    pip: '338-9236',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT186/5/',
    size: 'V',
    pip: '338-9244',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT186/5/',
    size: 'VI',
    pip: '338-9251',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT186/5/',
    size: 'VII',
    pip: '338-9269',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT185/5/',
    size: 'I',
    pip: '338-9095',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT185/5/',
    size: 'II',
    pip: '338-9087',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT185/5/',
    size: 'III',
    pip: '338-9079',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT185/5/',
    size: 'IV',
    pip: '338-9061',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT185/5/',
    size: 'V',
    pip: '338-9053',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT185/5/',
    size: 'VI',
    pip: '338-9046',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT185/5/',
    size: 'VII',
    pip: '338-9038',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT286/0/',
    size: 'I',
    pip: '339-0572',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT286/0/',
    size: 'II',
    pip: '339-0580',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT286/0/',
    size: 'III',
    pip: '339-0598',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT286/0/',
    size: 'IV',
    pip: '339-0606',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT286/0/',
    size: 'V',
    pip: '339-0614',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT286/0/',
    size: 'VI',
    pip: '339-0622',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT286/0/',
    size: 'VII',
    pip: '339-0648',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT285/0/',
    size: 'I',
    pip: '339-0408',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT285/0/',
    size: 'II',
    pip: '339-0416',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT285/0/',
    size: 'III',
    pip: '339-0424',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT285/0/',
    size: 'IV',
    pip: '339-0432',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT285/0/',
    size: 'V',
    pip: '339-0440',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT285/0/',
    size: 'VI',
    pip: '339-0457',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT285/0/',
    size: 'VII',
    pip: '339-0465',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT286/5/',
    size: 'I',
    pip: '339-0655',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT286/5/',
    size: 'II',
    pip: '339-0671',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT286/5/',
    size: 'III',
    pip: '339-0689',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT286/5/',
    size: 'IV',
    pip: '339-0705',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT286/5/',
    size: 'V',
    pip: '339-0713',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT286/5/',
    size: 'VI',
    pip: '339-0721',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT286/5/',
    size: 'VII',
    pip: '339-0739',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT285/5/',
    size: 'I',
    pip: '339-0481',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT285/5/',
    size: 'II',
    pip: '339-0499',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT285/5/',
    size: 'III',
    pip: '339-0507',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT285/5/',
    size: 'IV',
    pip: '339-0515',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT285/5/',
    size: 'V',
    pip: '339-0523',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT285/5/',
    size: 'VI',
    pip: '339-0531',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'Elegance',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT285/5/',
    size: 'VII',
    pip: '339-0549',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT342/5/',
    size: 'I',
    pip: '356-8433',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT342/5/',
    size: 'II',
    pip: '349-6734',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT342/5/',
    size: 'III',
    pip: '349-6742',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT342/5/',
    size: 'IV',
    pip: '349-6759',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT342/5/',
    size: 'V',
    pip: '349-6767',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT342/5/',
    size: 'VI',
    pip: '349-6775',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT342/5/',
    size: 'VII',
    pip: '356-8441',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT342/2/',
    size: 'I',
    pip: '360-2885',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT342/2/',
    size: 'II',
    pip: '360-2893',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT342/2/',
    size: 'III',
    pip: '360-2901',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT342/2/',
    size: 'IV',
    pip: '360-2919',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT342/2/',
    size: 'V',
    pip: '360-2927',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT342/2/',
    size: 'VI',
    pip: '360-2935',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT342/2/',
    size: 'VII',
    pip: '360-2943',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT342/3/',
    size: 'I',
    pip: '356-8417',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT342/3/',
    size: 'II',
    pip: '349-6684',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT342/3/',
    size: 'III',
    pip: '349-6692',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT342/3/',
    size: 'IV',
    pip: '349-6700',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT342/3/',
    size: 'V',
    pip: '349-6718',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT342/3/',
    size: 'VI',
    pip: '349-6726',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT342/3/',
    size: 'VII',
    pip: '356-8425',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT343/5/',
    size: 'I',
    pip: '356-8474',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT343/5/',
    size: 'II',
    pip: '349-6833',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT343/5/',
    size: 'III',
    pip: '349-6481',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT343/5/',
    size: 'IV',
    pip: '349-6858',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT343/5/',
    size: 'V',
    pip: '349-6866',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT343/5/',
    size: 'VI',
    pip: '349-6874',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT343/5/',
    size: 'VII',
    pip: '356-8482',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT343/2/',
    size: 'I',
    pip: '360-2950',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT343/2/',
    size: 'II',
    pip: '360-2968',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT343/2/',
    size: 'III',
    pip: '360-2976',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT343/2/',
    size: 'IV',
    pip: '360-2984',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT343/2/',
    size: 'V',
    pip: '360-2992',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT343/2/',
    size: 'VI',
    pip: '360-3008',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT343/2/',
    size: 'VII',
    pip: '360-3016',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT343/3/',
    size: 'I',
    pip: '356-8458',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT343/3/',
    size: 'II',
    pip: '349-6783',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT343/3/',
    size: 'III',
    pip: '349-6791',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT343/3/',
    size: 'IV',
    pip: '349-6809',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT343/3/',
    size: 'V',
    pip: '349-6817',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT343/3/',
    size: 'VI',
    pip: '349-6825',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT343/3/',
    size: 'VII',
    pip: '356-8466',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT340/5/',
    size: 'I',
    pip: '360-4436',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT340/5/',
    size: 'II',
    pip: '337-0791',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT340/5/',
    size: 'III',
    pip: '334-0809',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT340/5/',
    size: 'IV',
    pip: '334-0817',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT340/5/',
    size: 'V',
    pip: '334-0825',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT340/5/',
    size: 'VI',
    pip: '334-0833',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT340/5/',
    size: 'VII',
    pip: '360-4469',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT340/2/',
    size: 'I',
    pip: '360-3024',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT340/2/',
    size: 'II',
    pip: '360-3032',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT340/2/',
    size: 'III',
    pip: '360-3040',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT340/2/',
    size: 'IV',
    pip: '360-3057',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT340/2/',
    size: 'V',
    pip: '360-3065',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT340/2/',
    size: 'VI',
    pip: '360-3073',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT340/2/',
    size: 'VII',
    pip: '360-3081',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT340/3/',
    size: 'I',
    pip: '360-4436',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT340/3/',
    size: 'II',
    pip: '334-0742',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT340/3/',
    size: 'III',
    pip: '334-0759',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT340/3/',
    size: 'IV',
    pip: '334-0767',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT340/3/',
    size: 'V',
    pip: '334-0775',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT340/3/',
    size: 'VI',
    pip: '334-0783',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT340/3/',
    size: 'VII',
    pip: '360-4444',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT341/5/',
    size: 'I',
    pip: '360-4493',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT341/5/',
    size: 'II',
    pip: '334-0841',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT341/5/',
    size: 'III',
    pip: '334-0858',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT341/5/',
    size: 'IV',
    pip: '334-0866',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT341/5/',
    size: 'V',
    pip: '334-0874',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT341/5/',
    size: 'VI',
    pip: '334-0882',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT341/5/',
    size: 'VII',
    pip: '360-4501',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT341/2/',
    size: 'I',
    pip: '360-3099',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT341/2/',
    size: 'II',
    pip: '360-3107',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT341/2/',
    size: 'III',
    pip: '360-3115',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT341/2/',
    size: 'IV',
    pip: '360-3123',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT341/2/',
    size: 'V',
    pip: '360-3131',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT341/2/',
    size: 'VI',
    pip: '360-3149',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Grey',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT341/2/',
    size: 'VII',
    pip: '360-3156',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT341/3/',
    size: 'I',
    pip: '360-4477',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT341/3/',
    size: 'II',
    pip: '334-0890',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT341/3/',
    size: 'III',
    pip: '334-0916',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT341/3/',
    size: 'IV',
    pip: '334-0932',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT341/3/',
    size: 'V',
    pip: '334-0940',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT341/3/',
    size: 'VI',
    pip: '334-0957',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Navy',
    productCollection: 'For Men',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT341/3/',
    size: 'VII',
    pip: '360-4485',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Brown',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/6/',
    size: 'I',
    pip: '376-6607',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Brown',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/6/',
    size: 'II',
    pip: '376-6615',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Brown',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/6/',
    size: 'III',
    pip: '376-6623',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Brown',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/6/',
    size: 'IV',
    pip: '376-6631',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Brown',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/6/',
    size: 'V',
    pip: '376-6649',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Brown',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/6/',
    size: 'VI',
    pip: '376-6656',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Brown',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/6/',
    size: 'VII',
    pip: '376-6664',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Grey',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/2/',
    size: 'I',
    pip: '376-6326',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Grey',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/2/',
    size: 'II',
    pip: '376-6334',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Grey',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/2/',
    size: 'III',
    pip: '376-6342',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Grey',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/2/',
    size: 'IV',
    pip: '376-6359',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Grey',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/2/',
    size: 'V',
    pip: '376-6367',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Grey',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/2/',
    size: 'VI',
    pip: '376-6375',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Grey',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/2/',
    size: 'VII',
    pip: '376-6383',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Navy',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/3/',
    size: 'I',
    pip: '376-6391',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Navy',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/3/',
    size: 'II',
    pip: '376-6409',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Navy',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/3/',
    size: 'III',
    pip: '376-6417',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Navy',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/3/',
    size: 'IV',
    pip: '376-6425',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Navy',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/3/',
    size: 'V',
    pip: '376-6433',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Navy',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/3/',
    size: 'VI',
    pip: '376-6441',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Navy',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/3/',
    size: 'VII',
    pip: '376-6458',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'White',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/7/',
    size: 'I',
    pip: '367-6532',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'White',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/7/',
    size: 'II',
    pip: '376-6540',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'White',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/7/',
    size: 'III',
    pip: '376-6557',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'White',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/7/',
    size: 'IV',
    pip: '376-6565',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'White',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/7/',
    size: 'V',
    pip: '376-6573',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'White',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/7/',
    size: 'VI',
    pip: '376-6581',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'White',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/7/',
    size: 'VII',
    pip: '376-6599',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/5/',
    size: 'I',
    pip: '376-6466',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/5/',
    size: 'II',
    pip: '376-6474',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/5/',
    size: 'III',
    pip: '376-6482',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/5/',
    size: 'IV',
    pip: '376-6490',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/5/',
    size: 'V',
    pip: '376-6508',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/5/',
    size: 'VI',
    pip: '376-6516',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT180/5/',
    size: 'VII',
    pip: '376-6524',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Active',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '37-42',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT181/5/',
    size: 'I',
    pip: '376-6813',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Active',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '37-42',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT181/5/',
    size: 'II',
    pip: '376-6821',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Active',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '37-42',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT181/5/',
    size: 'III',
    pip: '376-6839',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Active',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '37-42',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT181/5/',
    size: 'IV',
    pip: '376-6847',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Active',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '37-42',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT181/5/',
    size: 'V',
    pip: '376-6854',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Active',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '37-42',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT181/5/',
    size: 'VI',
    pip: '376-6862',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'Active',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '37-42',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT181/5/',
    size: 'VII',
    pip: '376-6870',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Brown',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/6/',
    size: 'I',
    pip: '376-7332',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Brown',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/6/',
    size: 'II',
    pip: '376-7340',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Brown',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/6/',
    size: 'III',
    pip: '376-7357',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Brown',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/6/',
    size: 'IV',
    pip: '376-7365',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Brown',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/6/',
    size: 'V',
    pip: '376-7373',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Brown',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/6/',
    size: 'VI',
    pip: '376-7381',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Brown',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/6/',
    size: 'VII',
    pip: '376-7399',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Grey',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/2/',
    size: 'I',
    pip: '376-7027',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Grey',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/2/',
    size: 'II',
    pip: '376-7035',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Grey',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/2/',
    size: 'III',
    pip: '376-7043',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Grey',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/2/',
    size: 'IV',
    pip: '376-7050',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Grey',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/2/',
    size: 'V',
    pip: '376-7068',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Grey',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/2/',
    size: 'VI',
    pip: '376-7076',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Grey',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/2/',
    size: 'VII',
    pip: '376-7084',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Navy',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/3/',
    size: 'I',
    pip: '376-7092',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Navy',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/3/',
    size: 'II',
    pip: '376-7100',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Navy',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/3/',
    size: 'III',
    pip: '376-7118',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Navy',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/3/',
    size: 'IV',
    pip: '376-7126',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Navy',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/3/',
    size: 'V',
    pip: '376-7134',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Navy',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/3/',
    size: 'VI',
    pip: '376-7142',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Navy',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/3/',
    size: 'VII',
    pip: '376-7167',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'White',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/7/',
    size: 'I',
    pip: '376-7266',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'White',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/7/',
    size: 'II',
    pip: '376-7274',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'White',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/7/',
    size: 'III',
    pip: '376-7282',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'White',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/7/',
    size: 'IV',
    pip: '376-7290',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'White',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/7/',
    size: 'V',
    pip: '376-7308',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'White',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/7/',
    size: 'VI',
    pip: '376-7316',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'White',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/7/',
    size: 'VII',
    pip: '376-7324',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'White',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/5/',
    size: 'I',
    pip: '376-7138',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'White',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/5/',
    size: 'II',
    pip: '376-7209',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'White',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/5/',
    size: 'III',
    pip: '376-7217',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'White',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/5/',
    size: 'IV',
    pip: '376-7225',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'White',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/5/',
    size: 'V',
    pip: '376-7233',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'White',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/5/',
    size: 'VI',
    pip: '376-7241',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'White',
    productCollection: 'Active',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '43-49',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT280/5/',
    size: 'VII',
    pip: '376-7258',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'Active',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '37-42',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT281/5/',
    size: 'I',
    pip: '376-7829',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'Active',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '37-42',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT281/5/',
    size: 'II',
    pip: '376-7837',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'Active',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '37-42',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT281/5/',
    size: 'III',
    pip: '376-7845',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'Active',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '37-42',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT281/5/',
    size: 'IV',
    pip: '376-7852',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'Active',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '37-42',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT281/5/',
    size: 'V',
    pip: '376-7860',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'Active',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '37-42',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT281/5/',
    size: 'VI',
    pip: '376-7878',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'Active',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '37-42',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT281/5/',
    size: 'VII',
    pip: '376-8025',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '19-21',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT511/B/',
    size: 2,
    pip: '388-1802',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '32-38',
    measurement_CB: '22-24',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT511/B/',
    size: 3,
    pip: '388-1810',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '36-42',
    measurement_CB: '25-27',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT511/B/',
    size: 4,
    pip: '388-1828',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '40-46',
    measurement_CB: '28-30',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT511/B/',
    size: 5,
    pip: '388-1836',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '44-50',
    measurement_CB: '31-34',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT511/B/',
    size: 6,
    pip: '388-1844',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '19-21',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT512/B/',
    size: 2,
    pip: '388-1851',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '32-38',
    measurement_CB: '22-24',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT512/B/',
    size: 3,
    pip: '388-1869',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '36-42',
    measurement_CB: '25-27',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT512/B/',
    size: 4,
    pip: '388-1877',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '40-46',
    measurement_CB: '28-30',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT512/B/',
    size: 5,
    pip: '388-1885',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '44-50',
    measurement_CB: '31-34',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT512/B/',
    size: 6,
    pip: '388-1893',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Sand',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '19-21',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT511/',
    size: 2,
    pip: '367-4850',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Sand',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '32-38',
    measurement_CB: '22-24',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT511/',
    size: 3,
    pip: '367-4868',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Sand',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '36-42',
    measurement_CB: '25-27',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT511/',
    size: 4,
    pip: '367-4876',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Sand',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '40-46',
    measurement_CB: '28-30',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT511/',
    size: 5,
    pip: '367-4884',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Sand',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '44-50',
    measurement_CB: '31-34',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT511/',
    size: 6,
    pip: '367-4892',
    toe_type: 'open toe'
  },
  {
    ccl: 1,
    colour: 'Sand',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '19-21',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT512/',
    size: 2,
    pip: '367-4926',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Sand',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '32-38',
    measurement_CB: '22-24',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT512/',
    size: 3,
    pip: '367-4934',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Sand',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '36-42',
    measurement_CB: '25-27',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT512/',
    size: 4,
    pip: '367-4959',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Sand',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '40-46',
    measurement_CB: '28-30',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT512/',
    size: 5,
    pip: '367-4967',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Sand',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '44-50',
    measurement_CB: '31-34',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT512/',
    size: 6,
    pip: '367-4975',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '19-21',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT521/B/',
    size: 2,
    pip: '388-2008',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '32-38',
    measurement_CB: '22-24',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT521/B/',
    size: 3,
    pip: '388-2016',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '36-42',
    measurement_CB: '25-27',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT521/B/',
    size: 4,
    pip: '388-2024',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '40-46',
    measurement_CB: '28-30',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT521/B/',
    size: 5,
    pip: '388-2032',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '44-50',
    measurement_CB: '31-34',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT521/B/',
    size: 6,
    pip: '388-2040',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '19-21',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT522/B/',
    size: 2,
    pip: '388-2057',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '32-38',
    measurement_CB: '22-24',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT522/B/',
    size: 3,
    pip: '388-2065',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '36-42',
    measurement_CB: '25-27',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT522/B/',
    size: 4,
    pip: '388-2073',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '40-46',
    measurement_CB: '28-30',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT522/B/',
    size: 5,
    pip: '388-2081',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '44-50',
    measurement_CB: '31-34',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT522/B/',
    size: 6,
    pip: '388-2099',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Sand',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '19-21',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT521/',
    size: 2,
    pip: '367-5089',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Sand',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '32-38',
    measurement_CB: '22-24',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT521/',
    size: 3,
    pip: '367-5097',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Sand',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '36-42',
    measurement_CB: '25-27',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT521/',
    size: 4,
    pip: '367-5105',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Sand',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '40-46',
    measurement_CB: '28-30',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT521/',
    size: 5,
    pip: '367-5113',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Sand',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '44-50',
    measurement_CB: '31-34',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT521/',
    size: 6,
    pip: '367-5121',
    toe_type: 'open toe'
  },
  {
    ccl: 2,
    colour: 'Sand',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '19-21',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT522/',
    size: 2,
    pip: '367-5139',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Sand',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '32-38',
    measurement_CB: '22-24',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT522/',
    size: 3,
    pip: '367-5147',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Sand',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '36-42',
    measurement_CB: '25-27',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT522/',
    size: 4,
    pip: '367-5154',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Sand',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '40-46',
    measurement_CB: '28-30',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT522/',
    size: 5,
    pip: '367-5162',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Sand',
    productCollection: 'duomed soft',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '44-50',
    measurement_CB: '31-34',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT522/',
    size: 6,
    pip: '367-5170',
    toe_type: 'closed toe'
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'ulcer kit',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT275/',
    size: 1,
    pip: '329-2109',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'ulcer kit',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT275/',
    size: 2,
    pip: '329-2117',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'ulcer kit',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT275/',
    size: 3,
    pip: '329-2125',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'ulcer kit',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT275/',
    size: 4,
    pip: '329-2133',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'ulcer kit',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT275/',
    size: 5,
    pip: '329-2141',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'ulcer kit',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT275/',
    size: 6,
    pip: '329-2158',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'ulcer kit',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT275/',
    size: 7,
    pip: '329-2174',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'ulcer kit',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '34-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT276/',
    size: 1,
    pip: '329-2190',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'ulcer kit',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '34-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT276/',
    size: 2,
    pip: '329-2208',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'ulcer kit',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '34-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT276/',
    size: 3,
    pip: '329-2216',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'ulcer kit',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '34-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT276/',
    size: 4,
    pip: '329-2224',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'ulcer kit',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '34-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT276/',
    size: 5,
    pip: '329-2232',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'ulcer kit',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '34-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT276/',
    size: 6,
    pip: '329-2240',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'ulcer kit',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '34-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT276/',
    size: 7,
    pip: '329-2323',
    toe_type: null
  },
  {
    ccl: 1,
    colour: 'Sand',
    productCollection: 'duomed soft 2easy',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '19-21',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT2E1/2',
    size: 2,
    pip: '417-1880',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Sand',
    productCollection: 'duomed soft 2easy',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '32-38',
    measurement_CB: '22-24',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT2E1/3',
    size: 3,
    pip: '417-1898',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Sand',
    productCollection: 'duomed soft 2easy',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '36-42',
    measurement_CB: '25-27',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT2E1/4',
    size: 4,
    pip: '417-1906',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Sand',
    productCollection: 'duomed soft 2easy',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '40-46',
    measurement_CB: '28-30',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT2E1/5',
    size: 5,
    pip: '417-1914',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Sand',
    productCollection: 'duomed soft 2easy',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '44-50',
    measurement_CB: '31-34',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT2E1/6',
    size: 6,
    pip: '417-1922',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'duomed soft 2easy',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '19-21',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT2E1/B/2',
    size: 2,
    pip: '417-1930',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'duomed soft 2easy',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '32-38',
    measurement_CB: '22-24',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT2E1/B/3',
    size: 3,
    pip: '417-1948',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'duomed soft 2easy',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '36-42',
    measurement_CB: '25-27',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT2E1/B/4',
    size: 4,
    pip: '417-1955',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'duomed soft 2easy',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '40-46',
    measurement_CB: '28-30',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT2E1/B/5',
    size: 5,
    pip: '417-1963',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'duomed soft 2easy',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '44-50',
    measurement_CB: '31-34',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT2E1/B/6',
    size: 6,
    pip: '417-1971',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Sand',
    productCollection: 'duomed soft 2easy',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '19-21',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT2E2/2',
    size: 2,
    pip: '417-2102',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Sand',
    productCollection: 'duomed soft 2easy',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '32-38',
    measurement_CB: '22-24',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT2E2/3',
    size: 3,
    pip: '417-2110',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Sand',
    productCollection: 'duomed soft 2easy',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '36-42',
    measurement_CB: '25-27',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT2E2/4',
    size: 4,
    pip: '417-2128',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Sand',
    productCollection: 'duomed soft 2easy',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '40-46',
    measurement_CB: '28-30',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT2E2/5',
    size: 5,
    pip: '417-2136',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Sand',
    productCollection: 'duomed soft 2easy',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '44-50',
    measurement_CB: '31-34',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT2E2/6',
    size: 6,
    pip: '417-2144',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'duomed soft 2easy',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '19-21',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT2E2/B/2',
    size: 2,
    pip: '417-2151',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'duomed soft 2easy',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '32-38',
    measurement_CB: '22-24',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT2E2/B/3',
    size: 3,
    pip: '417-2169',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'duomed soft 2easy',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '36-42',
    measurement_CB: '25-27',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT2E2/B/4',
    size: 4,
    pip: '417-2177',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'duomed soft 2easy',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '40-46',
    measurement_CB: '28-30',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT2E2/B/5',
    size: 5,
    pip: '417-2185',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'duomed soft 2easy',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '44-50',
    measurement_CB: '31-34',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT2E2/B/6',
    size: 6,
    pip: '417-2193',
    toe_type: 'closed toe'
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtafit lower legging',
    length: 'Standard',
    width: 'Regular',
    measurement_CC: '30-38',
    measurement_CB: '15-23',
    measurement_AD: null,
    measurement_CB1: '20-28',
    measurement_BD: 36,
    code: 'DT951/',
    size: 2,
    pip: '348-0597',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtafit lower legging',
    length: 'Standard',
    width: 'Regular',
    measurement_CC: '36-43',
    measurement_CB: '20-28',
    measurement_AD: null,
    measurement_CB1: '25-33',
    measurement_BD: 36,
    code: 'DT951/',
    size: 3,
    pip: '348-0605',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtafit lower legging',
    length: 'Standard',
    width: 'Regular',
    measurement_CC: '41-48',
    measurement_CB: '25-33',
    measurement_AD: null,
    measurement_CB1: '30-38',
    measurement_BD: 36,
    code: 'DT951/',
    size: 4,
    pip: '348-0613',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtafit lower legging',
    length: 'Standard',
    width: 'Regular',
    measurement_CC: '41-51',
    measurement_CB: '30-38',
    measurement_AD: null,
    measurement_CB1: '33-41',
    measurement_BD: 36,
    code: 'DT951/',
    size: 5,
    pip: '348-0621',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtafit lower legging',
    length: 'Standard',
    width: 'Regular',
    measurement_CC: '53-64',
    measurement_CB: '33-41',
    measurement_AD: null,
    measurement_CB1: '43-53',
    measurement_BD: 36,
    code: 'DT951/',
    size: 6,
    pip: '348-0639',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtafit lower legging',
    length: 'Standard',
    width: 'Regular',
    measurement_CC: '58-70',
    measurement_CB: '42-52',
    measurement_AD: null,
    measurement_CB1: '50-60',
    measurement_BD: 36,
    code: 'DT951/',
    size: 7,
    pip: '419-1508',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtafit lower legging',
    length: 'Standard',
    width: 'X Wide',
    measurement_CC: '43-50',
    measurement_CB: '20-28',
    measurement_AD: null,
    measurement_CB1: '33-40',
    measurement_BD: 36,
    code: 'DT951/',
    size: 'X-3',
    pip: '368-0816',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtafit lower legging',
    length: 'Standard',
    width: 'X Wide',
    measurement_CC: '48-56',
    measurement_CB: '25-33',
    measurement_AD: null,
    measurement_CB1: '38-46',
    measurement_BD: 36,
    code: 'DT951/',
    size: 'X/4',
    pip: '368-0824',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtafit lower legging',
    length: 'Standard',
    width: 'X Wide',
    measurement_CC: '51-61',
    measurement_CB: '30-38',
    measurement_AD: null,
    measurement_CB1: '41-51',
    measurement_BD: 36,
    code: 'DT951/',
    size: 'X/5',
    pip: '368-0832',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtafit lower legging',
    length: 'Petite',
    width: 'Regular',
    measurement_CC: '30-38',
    measurement_CB: '15-23',
    measurement_AD: null,
    measurement_CB1: '20-28',
    measurement_BD: 28,
    code: 'DT950/',
    size: 2,
    pip: '348-0548',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtafit lower legging',
    length: 'Petite',
    width: 'Regular',
    measurement_CC: '36-43',
    measurement_CB: '20-28',
    measurement_AD: null,
    measurement_CB1: '25-33',
    measurement_BD: 28,
    code: 'DT950/',
    size: 3,
    pip: '348-0555',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtafit lower legging',
    length: 'Petite',
    width: 'Regular',
    measurement_CC: '41-48',
    measurement_CB: '25-33',
    measurement_AD: null,
    measurement_CB1: '30-38',
    measurement_BD: 28,
    code: 'DT950/',
    size: 4,
    pip: '348-0563',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtafit lower legging',
    length: 'Petite',
    width: 'Regular',
    measurement_CC: '41-51',
    measurement_CB: '30-38',
    measurement_AD: null,
    measurement_CB1: '33-41',
    measurement_BD: 28,
    code: 'DT950/',
    size: 5,
    pip: '348-0571',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtafit lower legging',
    length: 'Petite',
    width: 'Regular',
    measurement_CC: '53-64',
    measurement_CB: '33-41',
    measurement_AD: null,
    measurement_CB1: '43-53',
    measurement_BD: 28,
    code: 'DT950/',
    size: 6,
    pip: '348-0589',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtafit lower legging',
    length: 'Petite',
    width: 'Regular',
    measurement_CC: '58-70',
    measurement_CB: '42-52',
    measurement_AD: null,
    measurement_CB1: '50-60',
    measurement_BD: 28,
    code: 'DT950/',
    size: 7,
    pip: '419-1581',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtafit lower legging',
    length: 'Petite',
    width: 'X Wide',
    measurement_CC: '43-50',
    measurement_CB: '20-28',
    measurement_AD: null,
    measurement_CB1: '33-40',
    measurement_BD: 28,
    code: 'DT950/',
    size: 'X-3',
    pip: '368-0782',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtafit lower legging',
    length: 'Petite',
    width: 'X Wide',
    measurement_CC: '48-56',
    measurement_CB: '25-33',
    measurement_AD: null,
    measurement_CB1: '38-46',
    measurement_BD: 28,
    code: 'DT950/',
    size: 'X/4',
    pip: '368-0790',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtafit lower legging',
    length: 'Petite',
    width: 'X Wide',
    measurement_CC: '51-61',
    measurement_CB: '30-38',
    measurement_AD: null,
    measurement_CB1: '41-51',
    measurement_BD: 28,
    code: 'DT950/',
    size: 'X/5',
    pip: '368-0808',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtalite',
    length: 'Long',
    width: 'Regular',
    measurement_CC: '26-36',
    measurement_CB: '19-22',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: 33,
    code: 'DT300/',
    size: 2,
    pip: '394-0913',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtalite',
    length: 'Long',
    width: 'Regular',
    measurement_CC: '33-44',
    measurement_CB: '22-27',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: 33,
    code: 'DT300/',
    size: 3,
    pip: '394-0921',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtalite',
    length: 'Long',
    width: 'Regular',
    measurement_CC: '41-51',
    measurement_CB: '25-32',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: 33,
    code: 'DT300/',
    size: 4,
    pip: '394-0939',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtalite',
    length: 'Long',
    width: 'Regular',
    measurement_CC: '37-48',
    measurement_CB: '30-37',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: 33,
    code: 'DT300/',
    size: 5,
    pip: '394-0947',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtalite',
    length: 'Long',
    width: 'Regular',
    measurement_CC: '53-64',
    measurement_CB: '35-42',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: 33,
    code: 'DT300/',
    size: 6,
    pip: '394-0954',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtalite',
    length: 'Long',
    width: 'X Wide',
    measurement_CC: '44-54',
    measurement_CB: '22-27',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: 33,
    code: 'DT300/',
    size: 'X-3',
    pip: '394-0962',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtalite',
    length: 'Long',
    width: 'X Wide',
    measurement_CC: '51-61',
    measurement_CB: '25-32',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: 33,
    code: 'DT300/',
    size: 'X/4',
    pip: '394-0970',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtalite',
    length: 'Long',
    width: 'X Wide',
    measurement_CC: '48-61',
    measurement_CB: '30-37',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: 33,
    code: 'DT300/',
    size: 'X/5',
    pip: '394-0988',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtalite',
    length: 'Short',
    width: 'Regular',
    measurement_CC: '26-36',
    measurement_CB: '19-22',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: 28,
    code: 'DT301/',
    size: 2,
    pip: '394-0996',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtalite',
    length: 'Short',
    width: 'Regular',
    measurement_CC: '33-44',
    measurement_CB: '22-27',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: 28,
    code: 'DT301/',
    size: 3,
    pip: '394-1002',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtalite',
    length: 'Short',
    width: 'Regular',
    measurement_CC: '41-51',
    measurement_CB: '25-32',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: 28,
    code: 'DT301/',
    size: 4,
    pip: '394-1010',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtalite',
    length: 'Short',
    width: 'Regular',
    measurement_CC: '37-48',
    measurement_CB: '30-37',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: 28,
    code: 'DT301/',
    size: 5,
    pip: '394-1028',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtalite',
    length: 'Short',
    width: 'Regular',
    measurement_CC: '53-64',
    measurement_CB: '35-42',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: 28,
    code: 'DT301/',
    size: 6,
    pip: '394-1036',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtalite',
    length: 'Short',
    width: 'X Wide',
    measurement_CC: '44-54',
    measurement_CB: '22-27',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: 28,
    code: 'DT301/',
    size: 'X-3',
    pip: '394-1044',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtalite',
    length: 'Short',
    width: 'X Wide',
    measurement_CC: '51-61',
    measurement_CB: '25-32',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: 28,
    code: 'DT301/',
    size: 'X/4',
    pip: '394-4051',
    toe_type: null
  },
  {
    ccl: null,
    colour: null,
    productCollection: 'juxtalite',
    length: 'Short',
    width: 'X Wide',
    measurement_CC: '48-61',
    measurement_CB: '30-37',
    measurement_AD: null,
    measurement_CB1: null,
    measurement_BD: 28,
    code: 'DT301/',
    size: 'X/5',
    pip: '394-1069',
    toe_type: null
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101CT/0/I',
    size: 'I',
    pip: '421-6487',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101CT/0/II',
    size: 'II',
    pip: '421-6495',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101CT/0/III',
    size: 'III',
    pip: '421-6503',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101CT/0/IV',
    size: 'IV',
    pip: '421-6511',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101CT/0/V',
    size: 'V',
    pip: '421-6537',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101CT/0/VI',
    size: 'VI',
    pip: '421-6545',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101CT/0/VII',
    size: 'VII',
    pip: '421-6560',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103CT/0/I',
    size: 'I',
    pip: '421-6396',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103CT/0/II',
    size: 'II',
    pip: '421-6420',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103CT/0/III',
    size: 'III',
    pip: '421-6438',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103CT/0/IV',
    size: 'IV',
    pip: '421-6446',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103CT/0/V',
    size: 'V',
    pip: '421-6453',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103CT/0/VI',
    size: 'VI',
    pip: '421-6461',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103CT/0/VII',
    size: 'VII',
    pip: '421-6479',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104CT/0/I',
    size: 'I',
    pip: '421-6875',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104CT/0/II',
    size: 'II',
    pip: '421-6883',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104CT/0/III',
    size: 'III',
    pip: '421-6891',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104CT/0/IV',
    size: 'IV',
    pip: '421-6909',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104CT/0/V',
    size: 'V',
    pip: '421-6917',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104CT/0/VI',
    size: 'VI',
    pip: '421-6925',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104CT/0/VII',
    size: 'VII',
    pip: '421-6933',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102CT/0/I',
    size: 'I',
    pip: '421-6735',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102CT/0/II',
    size: 'II',
    pip: '421-6743',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102CT/0/III',
    size: 'III',
    pip: '421-6750',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102CT/0/IV',
    size: 'IV',
    pip: '421-6768',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102CT/0/V',
    size: 'V',
    pip: '421-6776',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102CT/0/VI',
    size: 'VI',
    pip: '421-6784',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102CT/0/VII',
    size: 'VII',
    pip: '421-6792',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101CT/5/I',
    size: 'I',
    pip: '421-6586',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101CT/5/II',
    size: 'II',
    pip: '421-6602',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101CT/5/III',
    size: 'III',
    pip: '421-6628',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101CT/5/IV',
    size: 'IV',
    pip: '421-6669',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101CT/5/V',
    size: 'V',
    pip: '421-6685',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101CT/5/VI',
    size: 'VI',
    pip: '421-6719',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT101CT/5/VII',
    size: 'VII',
    pip: '421-6727',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103CT/5/I',
    size: 'I',
    pip: '421-6362',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103CT/5/II',
    size: 'II',
    pip: '421-6370',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103CT/5/III',
    size: 'III',
    pip: '421-6388',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103CT/5/IV',
    size: 'IV',
    pip: '421-6321',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103CT/5/V',
    size: 'V',
    pip: '421-6339',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103CT/5/VI',
    size: 'VI',
    pip: '421-6347',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT103CT/5/VII',
    size: 'VII',
    pip: '421-6354',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104CT/5/I',
    size: 'I',
    pip: '421-6941',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104CT/5/II',
    size: 'II',
    pip: '421-6958',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104CT/5/III',
    size: 'III',
    pip: '421-6966',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104CT/5/IV',
    size: 'IV',
    pip: '421-6974',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104CT/5/V',
    size: 'V',
    pip: '421-6982',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104CT/5/VI',
    size: 'VI',
    pip: '421-6990',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT104CT/5/VII',
    size: 'VII',
    pip: '421-7006',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102CT/5/I',
    size: 'I',
    pip: '421-6800',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102CT/5/II',
    size: 'II',
    pip: '421-6818',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102CT/5/III',
    size: 'III',
    pip: '421-6826',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102CT/5/IV',
    size: 'IV',
    pip: '421-6834',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102CT/5/V',
    size: 'V',
    pip: '421-6842',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102CT/5/VI',
    size: 'VI',
    pip: '421-6859',
    toe_type: 'closed toe'
  },
  {
    ccl: 1,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT102CT/5/VII',
    size: 'VII',
    pip: '421-6867',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201CT/0/I',
    size: 'I',
    pip: '421-7501',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201CT/0/II',
    size: 'II',
    pip: '421-7519',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201CT/0/III',
    size: 'III',
    pip: '421-7527',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201CT/0/IV',
    size: 'IV',
    pip: '421-7535',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201CT/0/V',
    size: 'V',
    pip: '421-7543',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201CT/0/VI',
    size: 'VI',
    pip: '421-7550',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201CT/0/VII',
    size: 'VII',
    pip: '421-7568',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203CT/0/I',
    size: 'I',
    pip: '421-7360',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203CT/0/II',
    size: 'II',
    pip: '421-7378',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203CT/0/III',
    size: 'III',
    pip: '421-7386',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203CT/0/IV',
    size: 'IV',
    pip: '421-7394',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203CT/0/V',
    size: 'V',
    pip: '421-7402',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203CT/0/VI',
    size: 'VI',
    pip: '421-7410',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203CT/0/VII',
    size: 'VII',
    pip: '421-7428',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204CT/0/I',
    size: 'I',
    pip: '421-7782',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204CT/0/II',
    size: 'II',
    pip: '421-7790',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204CT/0/III',
    size: 'III',
    pip: '421-7808',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204CT/0/IV',
    size: 'IV',
    pip: '421-7816',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204CT/0/V',
    size: 'V',
    pip: '421-7824',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204CT/0/VI',
    size: 'VI',
    pip: '421-7832',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204CT/0/VII',
    size: 'VII',
    pip: '421-7840',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202CT/0/I',
    size: 'I',
    pip: '421-7642',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202CT/0/II',
    size: 'II',
    pip: '421-7659',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202CT/0/III',
    size: 'III',
    pip: '421-7667',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202CT/0/IV',
    size: 'IV',
    pip: '421-7675',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202CT/0/V',
    size: 'V',
    pip: '421-7683',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202CT/0/VI',
    size: 'VI',
    pip: '421-7691',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202CT/0/VII',
    size: 'VII',
    pip: '421-7709',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201CT/5/I',
    size: 'I',
    pip: '421-7576',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201CT/5/II',
    size: 'II',
    pip: '421-7584',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201CT/5/III',
    size: 'III',
    pip: '421-7592',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201CT/5/IV',
    size: 'IV',
    pip: '421-7600',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201CT/5/V',
    size: 'V',
    pip: '421-7618',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201CT/5/VI',
    size: 'VI',
    pip: '421-7626',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT201CT/5/VII',
    size: 'VII',
    pip: '421-7634',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203CT/5/I',
    size: 'I',
    pip: '421-7436',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203CT/5/II',
    size: 'II',
    pip: '421-7444',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203CT/5/III',
    size: 'III',
    pip: '421-7451',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203CT/5/IV',
    size: 'IV',
    pip: '421-7469',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203CT/5/V',
    size: 'V',
    pip: '421-7477',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203CT/5/VI',
    size: 'VI',
    pip: '421-7485',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'XW',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT203CT/5/VII',
    size: 'VII',
    pip: '421-7493',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204CT/5/I',
    size: 'I',
    pip: '421-7857',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204CT/5/II',
    size: 'II',
    pip: '421-7865',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204CT/5/III',
    size: 'III',
    pip: '421-7873',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204CT/5/IV',
    size: 'IV',
    pip: '421-7881',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204CT/5/V',
    size: 'V',
    pip: '421-7949',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204CT/5/VI',
    size: 'VI',
    pip: '421-7956',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'XW',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT204CT/5/VII',
    size: 'VII',
    pip: '421-7964',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202CT/5/I',
    size: 'I',
    pip: '421-7717',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202CT/5/II',
    size: 'II',
    pip: '421-7725',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202CT/5/III',
    size: 'III',
    pip: '421-7733',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202CT/5/IV',
    size: 'IV',
    pip: '421-7741',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202CT/5/V',
    size: 'V',
    pip: '421-7758',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202CT/5/VI',
    size: 'VI',
    pip: '421-7766',
    toe_type: 'closed toe'
  },
  {
    ccl: 2,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT202CT/5/VII',
    size: 'VII',
    pip: '421-7774',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401CT/0/I',
    size: 'I',
    pip: '421-6032',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401CT/0/II',
    size: 'II',
    pip: '421-6040',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401CT/0/III',
    size: 'III',
    pip: '421-6065',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401CT/0/IV',
    size: 'IV',
    pip: '421-6073',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401CT/0/V',
    size: 'V',
    pip: '421-6081',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401CT/0/VI',
    size: 'VI',
    pip: '421-6099',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401CT/0/VII',
    size: 'VII',
    pip: '421-6107',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402CT/0/I',
    size: 'I',
    pip: '421-6180',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402CT/0/II',
    size: 'II',
    pip: '421-6198',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402CT/0/III',
    size: 'III',
    pip: '421-6206',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402CT/0/IV',
    size: 'IV',
    pip: '421-6214',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402CT/0/V',
    size: 'V',
    pip: '421-6222',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402CT/0/VI',
    size: 'VI',
    pip: '421-6230',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Beige',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402CT/0/VII',
    size: 'VII',
    pip: '421-6248',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401CT/5/I',
    size: 'I',
    pip: '421-6115',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401CT/5/II',
    size: 'II',
    pip: '421-6123',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401CT/5/III',
    size: 'III',
    pip: '421-6131',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401CT/5/IV',
    size: 'IV',
    pip: '421-6149',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401CT/5/V',
    size: 'V',
    pip: '421-6156',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401CT/5/VI',
    size: 'VI',
    pip: '421-6164',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Regular',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '39-44',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT401CT/5/VII',
    size: 'VII',
    pip: '421-6172',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '28-34',
    measurement_CB: '18-20',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402CT/5/I',
    size: 'I',
    pip: '421-6255',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '30-37',
    measurement_CB: '20-22',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402CT/5/II',
    size: 'II',
    pip: '421-6263',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '33-40',
    measurement_CB: '22-24',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402CT/5/III',
    size: 'III',
    pip: '421-6271',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '35-43',
    measurement_CB: '24-26',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402CT/5/IV',
    size: 'IV',
    pip: '421-6289',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '37-46',
    measurement_CB: '26-28',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402CT/5/V',
    size: 'V',
    pip: '421-6297',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '39-49',
    measurement_CB: '28-30',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402CT/5/VI',
    size: 'VI',
    pip: '421-6305',
    toe_type: 'closed toe'
  },
  {
    ccl: 3,
    colour: 'Black',
    productCollection: 'mediven plus',
    length: 'Petite',
    width: 'regular',
    measurement_CC: '41-51',
    measurement_CB: '30-32',
    measurement_AD: '33-38',
    measurement_CB1: null,
    measurement_BD: null,
    code: 'DT402CT/5/VII',
    size: 'VII',
    pip: '421-6313',
    toe_type: 'closed toe'
  }
];