import { createGlobalStyle } from "styled-components";

const breakpoints = {
  mobile: "500px",
  tablet: "768px",
  desktop: "1024px",
};

export const devices = {
  mobile: `(min-width: ${breakpoints.mobile})`,
  tablet: `(min-width: ${breakpoints.tablet})`,
  desktop: `(min-width: ${breakpoints.desktop})`,
};

export const defaultTheme = {
  colorPrimary: "#262F39",
  colorSecondary: "#ffffff",
  colorTertiary: "#E20074",
  backgroundPrimary: "#ffffff",
  backgroundSecondary: "#E20074",
  buttonPrimaryBackground: "#E20074",
  buttonPrimaryHover: "#8D004C",
  buttonSecondaryBackground: "#ffffff",
  buttonSecondaryColor: "#E20074",
  buttonSecondaryHover: "#8D004C",
  buttonTertiaryBackground: "#262F39",
  buttonQuaternaryBackground: "#ffffff",
  buttonQuaternaryHover: "#98A4AF",
};

export const GlobalStyles = createGlobalStyle`
	* {
		box-sizing: border-box;
		margin: 0px;
		padding: 0px;
	}

	body, html {
		color: ${defaultTheme.colorPrimary};
	}
	select {
		padding-right: 16px;
	}
	select, input[type="text"], input[type="number"], input[type="date"] {
		font-family: "source-sans-pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		border: 1px solid #D4CCBD;
		border-radius: 3px;
		color: #262F39;
		padding: 12px;
		font-size: 12px;
		line-height: 20px;

		&:hover, &:focus {
			outline: none;
			box-shadow: 0px 1px 2px rgba(60, 64, 67, 0.3), 0px 1px 3px 1px rgba(60, 64, 67, 0.15);
		}
		&:focus {
			border: 1px solid ${defaultTheme.backgroundSecondary};
		}
		&:not(:focus) {
			&:hover{
				border: 1px solid #f399c7;
			}
		}

		&::placeholder {
			color: #48586A;
		}
	}

	input[type="checkbox"] {
		appearance: none;
		height: 32px;
		width: 32px;
		border: 1px solid ${defaultTheme.backgroundSecondary};
		border-radius: 2px;

		&:checked {
			position: relative;

			&::after {
				position: absolute;
				left: 6px;
				right: 6px;
				top: 6px;
				bottom: 6px;
				content: '';
				background: ${defaultTheme.backgroundSecondary};
			}
		}
	}

	label {
		font-size: 12px;
		color: #262F39;
		margin-left: 16px;
	}
	
	p {
		line-height: 26px;
	}

	h1 {
		font-size: 28px;
		line-height: 32px;
	}
	h2 {
		font-size: 24px;
		line-height: 36px;
	}
	h3 {
		font-size: 18px;
		line-height: 28px;
	}

	@media ${devices.tablet} {
		
		h1 {
			font-size: 34px;
			line-height: 46px;
		}
		h2 {
			font-size: 28px;
			line-height: 41px;
		}
		h3 {
			font-size: 21px;
			line-height: 33px;
		}
	}
`;
