import React from "react";
import styled from "styled-components";

export function PrimaryButton({ disabled, onClick, children }) {
  return (
    <StyledPrimaryButton disabled={disabled} onClick={onClick}>
      {children}
    </StyledPrimaryButton>
  );
}
export function SecondaryButton({ disabled, onClick, children }) {
  return (
    <StyledSecondaryButton disabled={disabled} onClick={onClick}>
      {children}
    </StyledSecondaryButton>
  );
}
export function TertiaryButton({ disabled, onClick, children, ...props }) {
  return (
    <StyledTertiaryButton disabled={disabled} onClick={onClick} {...props}>
      {children}
    </StyledTertiaryButton>
  );
}
export function QuaternaryButton({ disabled, onClick, children }) {
  return (
    <StyledQuaternaryButton disabled={disabled} onClick={onClick}>
      {children}
    </StyledQuaternaryButton>
  );
}

const StyledButton = styled.button`
  font-family: "source-sans-pro";
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: ${(props) => props.theme.colorPrimary};
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  height: 44px;

  &:disabled {
    opacity: 0.2;
  }
`;

const StyledPrimaryButton = styled(StyledButton)`
  color: ${(props) => props.theme.colorSecondary};
  background: ${(props) => props.theme.buttonPrimaryBackground};

  &:hover:enabled {
    background: ${(props) => props.theme.buttonPrimaryHover};
  }
`;
const StyledSecondaryButton = styled(StyledButton)`
  color: ${(props) => props.theme.buttonSecondaryColor};
  background: ${(props) => props.theme.buttonSecondaryBackground};
  border: ${(props) => `1px solid ${props.theme.buttonSecondaryColor}`};

  &:hover:enabled {
    border: ${(props) => `1px solid ${props.theme.buttonSecondaryHover}`};
    color: ${(props) => props.theme.buttonSecondaryHover};
  }
`;
const StyledTertiaryButton = styled(StyledButton)`
  color: ${(props) => props.theme.colorSecondary};
  background: ${(props) => props.theme.buttonTertiaryBackground};

  &:hover:enabled {
    background: #000000;
  }
`;
const StyledQuaternaryButton = styled(StyledButton)`
  color: ${(props) => props.theme.colorPrimary};
  background: ${(props) => props.theme.buttonQuaternaryBackground};

  &:hover:enabled {
    background: ${(props) => props.theme.buttonQuaternaryHover};
  }
`;
