import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getUniqueProduct, useOffers } from "../../context/offerContext";
import { useResponses } from "../../context/responseContext";
import { RESPONSE_SECTIONS } from "../../routes/Questionnaire/questions";
import { devices } from "../../styles";

export const colorHexValues = {
  Beige: "#D4CCBD",
  Black: "#262F39",
  Brown: "#CAAA79",
  Grey: "#cccccc",
  Navy: "#2a355c",
  Sand: "#CAAA79",
  White: "#ffffff",
};
const colors = ["Beige", "Black", "Brown", "Grey", "Navy", "Sand", "White"];

export default function ColorPicker({ setButtonDisabled, goToNext }) {
  const { selected, details, selectedProduct, setSelectedProduct } =
    useOffers();
  const { responses, updateResponse } = useResponses();
  const [selectedColor, setSelectedColor] = useState(
    responses ? responses[RESPONSE_SECTIONS.COLOR] : null
  );
  const [selectedToe, setSelectedToe] = useState(
    details.toe.length === 1 ? details.toe[0] : "closed toe"
  );

  // if there is no color or toe style to select, skip this section
  useEffect(() => {
    if (details.toe.length < 2 && details.colors.length < 2) {
      goToNext();
    }
  }, [details.toe.length, details.colors.length, goToNext]);

  useEffect(() => {
    updateResponse(RESPONSE_SECTIONS.TOE_TYPE, selectedToe);
  }, [selectedToe]);

  // if there is only one toe style (so the toe selector doesn't show), update the subheading to not include the toe style copy
  useEffect(() => {
    const heading = document.getElementById("heading");
    const subheading = document.getElementById("subheading");

    if (heading && details.toe.length < 2) {
      heading.innerText = `Pick the colour for ${selected.displayName}`;
    }
    if (subheading && details.toe.length < 2) {
      subheading.innerText =
        "Please select your patient’s preferred colour from the options below:";
    }
  }, [details.toe.length, selected.displayName]);

  useEffect(() => {
    if (!selectedColor || !selectedToe) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [selectedColor, selectedToe, setButtonDisabled]);

  useEffect(() => {
    // get unique products for left/right leg (if applicable) with selected color and toe style, and update in global state so it can be accessed on next screen
    if (selectedToe && selectedColor) {
      const measurements = responses[RESPONSE_SECTIONS.MEASUREMENTS];
      const toeType = responses[RESPONSE_SECTIONS.TOE_TYPE];
      const color = responses[RESPONSE_SECTIONS.COLOR];
      const compressionClass = responses[RESPONSE_SECTIONS.COMPRESSION_CLASS];

      const uniqueProductLeft =
        selectedProduct["left"] === null
          ? null
          : getUniqueProduct(
              selected.name,
              measurements["left"]["CC"] || null,
              measurements["left"]["CB"] || null,
              measurements["left"]["ID"] || null,
              measurements["left"]["measurement_CB1"] || null,
              measurements["left"]["measurement_BD"] || null,
              color,
              toeType,
              compressionClass
            );

      const uniqueProductRight =
        selectedProduct["right"] === null
          ? null
          : getUniqueProduct(
              selected.name,
              measurements["right"]["CC"] || null,
              measurements["right"]["CB"] || null,
              measurements["right"]["ID"] || null,
              measurements["right"]["measurement_CB1"] || null,
              measurements["right"]["measurement_BD"] || null,
              color,
              toeType,
              compressionClass
            );

      // update global state with selected unique products
      setSelectedProduct({
        left: uniqueProductLeft ? uniqueProductLeft : null,
        right: uniqueProductRight ? uniqueProductRight : null,
      });
    }
  }, [
    selectedToe,
    selectedColor,
    responses,
    selected.name,
    setSelectedProduct,
  ]);

  return (
    <Wrapper>
      <h3>Colour</h3>
      {details ? (
        details.colors && (
          <ColorContainer>
            {details.colors.map((item) => {
              return (
                <Color
                  key={item}
                  onClick={() => {
                    setSelectedColor(item);
                    updateResponse(RESPONSE_SECTIONS.COLOR, item);
                  }}
                >
                  <Dot
                    isWhite={item ? item.toLowerCase() === "white" : false}
                    isSelected={item === selectedColor}
                    color={colorHexValues[item]}
                  />
                  <p>{item}</p>
                </Color>
              );
            })}
          </ColorContainer>
        )
      ) : (
        <ColorContainer>
          {colors.map((item) => {
            return (
              <Color onClick={() => setSelectedColor(item)}>
                <Dot
                  isWhite={item.toLowerCase() === "white"}
                  isSelected={item === selectedColor}
                  color={colorHexValues[item]}
                />
                <p>{item}</p>
              </Color>
            );
          })}
        </ColorContainer>
      )}
      {details.toe.length === 2 && (
        <ToeType>
          <h3>Toe type</h3>
          <ToeToggle
            checked={false}
            setSelectedToe={setSelectedToe}
            updateResponse={updateResponse}
          />
        </ToeType>
      )}
    </Wrapper>
  );
}

const ToeToggle = ({ checked = false, setSelectedToe, updateResponse }) => {
  const [toggle, setToggle] = useState(checked);

  const handleChange = (e) => {
    const toeType = e.target.checked ? "open" : "closed";
    setSelectedToe(toeType);
    updateResponse(RESPONSE_SECTIONS.TOE_TYPE, toeType);
  };

  return (
    <ToggleWrapper>
      <span>Closed</span>
      <ToeLabel>
        <ToeInput type="checkbox" onChange={(e) => handleChange(e)} />
        <ToeSpan {...{ toggle }} onClick={() => setToggle(!toggle)}></ToeSpan>
      </ToeLabel>
      <span>Open</span>
    </ToggleWrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 24px;

  @media ${devices.tablet} {
    margin-top: 72px;
  }
`;

const ColorContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`;

const Color = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(:last-child) {
    margin-right: 36px;
  }

  p {
    font-size: 12px;
    color: ${(props) => props.theme.colorTertiary};
    margin-top: 8px;
  }
`;

const Dot = styled.div`
  border-radius: 50%;
  background: ${(props) => props.color};
  height: 32px;
  width: 32px;
  cursor:pointer;
  border: ${(props) =>
    props.isSelected
      ? `1px solid ${props.theme.backgroundSecondary};box-shadow: 0px 1px 2px rgba(60, 64, 67, 0.3), 0px 1px 3px 1px rgba(60, 64, 67, 0.15);`
      : props.isWhite && "1px solid #000"};
`;

const ToeType = styled.div`
  margin-top: 24px;

  @media ${devices.tablet} {
    margin-top: 36px;
  }
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

const ToeSpan = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.buttonQuaternaryHover};
  border-radius: 15px;
  transition: 0.4s;

  &:before {
    content: "";
    position: absolute;
    left: 1px;
    bottom: 1px;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    background-color: #ffffff;
    transition: 0.4s;
  }
`;

const ToeInput = styled.input`
  &:checked + ${ToeSpan}{
    background-color: ${(props) => props.theme.colorTertiary};
  }
  &:checked + ${ToeSpan}:before {
    transform: translateX(20px);
    
  }
`;

const ToeLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
  background-color: white;
  border-radius: 15px;
  margin: 0px 16px;

  ${ToeInput} {
    opacity: 0;
    height: 0px;
    width: 0px;
  }
`;
