import React, { useEffect } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import gsap from "gsap";
import ReactGA from "react-ga4";
import { TertiaryButton } from "../../components/Button";
import { WelcomeHeader } from "../../components/Header";
import useLocalStorage from "../../hooks/useLocalStorage";
import Logo from "../../assets/logo.png";
import { devices } from "../../styles";

export default function Welcome() {
  const history = useHistory();
  const [showIntro] = useLocalStorage("showIntro", true);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", title: `homepage` });
  }, []);

  const handleClick = () => {
    gsap.fromTo(
      [...document.querySelectorAll("#content")],
      { opacity: 1 },
      {
        opacity: 0,
        duration: 1,
        onComplete: () => {
          if (!showIntro) {
            history.push("/questionnaire");
          } else {
            history.push("/intro");
          }
        },
      }
    );
  };

  return (
    <Wrapper>
      <WelcomeHeader />
      <Container>
        <Content id="content">
          <img alt="Hosiery Hunter logo" src={Logo} />
          <h3>Welcome to Hosiery Hunter&reg;</h3>
          <h4>This app is brought to you by medi UK</h4>
          <p>
            The aim of this app is to support Healthcare Professionals in
            selecting compression hosiery or wrap products &mdash; to best match
            patient needs. Please click the box below to confirm that you are a
            Healthcare Professional.
          </p>
          <TertiaryButton onClick={() => handleClick()}>Confirm</TertiaryButton>
        </Content>
      </Container>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 80px;

  @media ${devices.tablet} {
    padding-top: 120px;
  }
`;

const Container = styled.div`
  height: 100%;
  flex: 1;
  background: linear-gradient(206.12deg, #e20074 1.01%, #b9005f 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 28px;
`;

const Content = styled.div`
  color: ${(props) => props.theme.colorSecondary};
  text-align: center;
  width: 320px;

  img {
    height: 120px;
    width: 120px;
    margin-bottom: 24px;
  }

  h3 {
    margin-bottom: 8px;
  }

  h4 {
    font-size: 18px;
    margin-bottom: 24px;
  }

  p {
    width: 320px;
    margin: 0px auto 56px;
  }

  @media ${devices.tablet} {
    width: 340px;

    h4 {
      margin-bottom: 36px;
    }
  }
`;
