import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import gsap from "gsap";
import ReactGA from "react-ga4";
import { PrimaryButton, SecondaryButton } from "../../components/Button";
import { IntroHeader } from "../../components/Header";
import useLocalStorage from "../../hooks/useLocalStorage";
import { devices } from "../../styles";
import IntroPage1 from "../../assets/intro-0.png";
import IntroPage2 from "../../assets/intro-1.png";
import IntroPage3 from "../../assets/intro-2.png";
import IntroPage4 from "../../assets/intro-3.png";
import IntroGuidePage from "./IntroGuidePage";

export default function Intro() {
  const [index, setIndex] = useState(0);
  const [showIntro, setShowIntro] = useLocalStorage("showIntro", true);
  const history = useHistory();

  // on first load, fade in elements
  useEffect(() => {
    const animation = gsap.fromTo(
      [...document.querySelectorAll(".content-wrapper")],
      { opacity: 0 },
      {
        opacity: 1,
        duration: 1,
      }
    );

    const imagesPreload = [IntroPage1, IntroPage2, IntroPage3, IntroPage4];
    imagesPreload.forEach((image) => {
      let newImage = new Image();
      newImage.src = image;
      window[image] = newImage;
    });

    return () => {
      animation.kill();
    };
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", title: `intro-section-${index + 1}` });
  });

  // scroll to top when new question shows
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [index]);

  const handleSkipIntroClick = (e) => {
    setShowIntro(!e.target.checked);
  };

  const fadeOutInAnimation = (onCompleteFn) => {
    const elements = [...document.querySelectorAll(".content-wrapper")];
    gsap.fromTo(
      elements,
      { opacity: 1, y: 0 },
      {
        opacity: 0,
        duration: 0.5,
        onComplete: () => {
          // when finished run onComplete function, and fade content back in again
          onCompleteFn();
          gsap.fromTo(
            elements,
            { opacity: 0 },
            {
              opacity: 1,
              duration: 0.5,
            }
          );
        },
      }
    );
  };

  const handleNextButtonClick = () => {
    const goToNext = () => {
      if (index < introContent.length - 1) {
        ReactGA.event({
          category: "Navigation",
          action: "Moved to next intro page",
        });
        setIndex(index + 1);
      } else {
        ReactGA.event({
          category: "Navigation",
          action: "Moved from intro page to questionnaire",
        });
        history.push("/questionnaire");
      }
    };

    // don't run onComplete fadeIn animation on last section of intro because questionnaire page already has a default fadeIn animation on page load
    if (index >= introContent.length - 1) {
      gsap.fromTo(
        [...document.querySelectorAll(".content-wrapper")],
        { opacity: 1, y: 0 },
        {
          opacity: 0,
          duration: 0.5,
          onComplete: () => {
            ReactGA.event({
              category: "Navigation",
              action: "Moved from intro page to questionnaire",
            });
            history.push("/questionnaire");
          },
        }
      );
    } else {
      fadeOutInAnimation(goToNext);
    }
  };

  const handleBackButtonClick = () => {
    fadeOutInAnimation(() => {
      ReactGA.event({
        category: "Navigation",
        action: "Moved to previous intro page",
      });
      setIndex(index - 1);
    });
  };

  // console.log({ index });
  return (
    <Wrapper>
      <IntroHeader index={index} length={introContent.length} />
      <div
        className="content-wrapper"
        style={{
          flex: 1,
          display: "flex",
          opacity: 0,
          alignItems: index === 0 ? "flex-start" : "center",
        }}
      >
        {index === 0 ? (
          <GuideContentWrapper>
            {/* <GuideContentWrapper className="content-wrapper"> */}
            <Container isFullWidth={true} style={{ flex: 1 }}>
              <IntroGuidePage />
            </Container>
          </GuideContentWrapper>
        ) : (
          <ContentWrapper>
            {/* <ContentWrapper className="content-wrapper"> */}
            <Container>
              <img alt="" src={introContent[index].image} />
              {introContent[index] && (
                <TextContent
                  dangerouslySetInnerHTML={{
                    __html: `<h2>${introContent[index].heading}</h2><p>${introContent[index].text}</p>`,
                  }}
                ></TextContent>
              )}
            </Container>
          </ContentWrapper>
        )}
      </div>
      <FooterContent>
        <ButtonContainer>
          {index !== 0 && (
            <SecondaryButton onClick={() => handleBackButtonClick()}>
              Back
            </SecondaryButton>
          )}
          <PrimaryButton onClick={() => handleNextButtonClick()}>
            Next
          </PrimaryButton>
        </ButtonContainer>
        <SkipContainer>
          <form style={{ display: "flex", alignItems: "center" }}>
            <input
              id="refusal-checkbox"
              type="checkbox"
              defaultChecked={!showIntro ? true : false}
              onClick={(e) => handleSkipIntroClick(e)}
            />
            <label htmlFor="refusal-checkbox">Don't show me this again</label>
          </form>
          <SkipButton
            onClick={() =>
              fadeOutInAnimation(() => {
                ReactGA.event({
                  category: "Navigation",
                  action: "Skipped intro",
                });
                history.push("/questionnaire");
              })
            }
          >
            Skip intro
          </SkipButton>
        </SkipContainer>
      </FooterContent>
    </Wrapper>
  );
}

const introContent = [
  {
    Component: IntroGuidePage,
  },
  {
    image: IntroPage1,
    heading: "Compression therapy",
    text: "Medical compression is often a lifelong therapy and therefore compression therapy must fit in with the patient's lifestyle. The selection of appropriate compression should be a shared decision based on holistic assessment, patient preference and treatment goals. The choice of product takes into account: limb shape and size, patient concerns and considerations, and the garment itself.",
  },
  {
    image: IntroPage4,
    heading: "How this app works",
    text: "The data that you are entering into this app is retained on your local device during your session. Once you close the session or clear your browsing history, the information will <strong>not</strong> be stored, so please ensure you send or copy the details <strong>prior to</strong> completing the session.",
  },
];

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 80px;

  @media ${devices.tablet} {
    padding-top: 120px;
  }
`;

const GuideContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0px;
  flex: 1;
  height: 100%;
  /* justify-content: center; */
  align-items: center;
  /* opacity: 0; // initialize to 0, gsap animations will set this to 1 so content is visible */
  background-color: ${(props) => props.theme.colorTertiary};
  color: #ffffff;

  /* img {
    align-self: center;
    width: 342px;
    max-width: 100%;
  } */

  @media ${devices.tablet} {
    flex-direction: row;
    text-align: left;
    align-items: ${(props) => !props.isGuidePage && "center"};
    /* padding-left: 72px;
    padding-right: 72px; */
  }
`;

const ContentWrapper = styled(GuideContentWrapper)`
  padding: 32px 16px 24px;
  /* justify-content: center; */
  justify-content: center;
  align-items: unset;
  background-color: #fff;
  color: #000;
  flex-direction: row;
  opacity: 1;

  img {
    align-self: center;
    width: 342px;
    max-width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${devices.tablet} {
    flex-direction: row;
    align-items: flex-start;
  }
  @media ${devices.desktop} {
    max-width: ${(props) => (props.isFullWidth ? "100%" : "900px")};
    margin: 0 auto;
    /* align-items: center; */
  }
`;

const TextContent = styled.div`
  max-width: 530px;

  h2 {
    color: ${(props) => props.theme.colorTertiary};
  }

  h2,
  p {
    margin-top: 24px;
  }

  @media ${devices.tablet} {
    margin-left: 28px;

    h2 {
      margin-top: 0px;
    }
    p {
      margin-top: 28px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;

  button:first-child {
    margin-right: 24px;
  }

  @media ${devices.desktop} {
    margin-bottom: 0px;
    margin-left: 40px;
  }
`;
const SkipContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media ${devices.tablet} {
    justify-content: center;
  }
  @media ${devices.desktop} {
    justify-content: unset;
  }
`;

const SkipButton = styled.button`
  color: ${(props) => props.theme.buttonSecondaryColor};
  font-family: "source-sans-pro";
  background: none;
  border: none;
  font-weight: bold;
  cursor: pointer;
  margin-left: 70px;
  width: 100px;

  &:hover {
    color: ${(props) => props.theme.buttonSecondaryHover};
  }
`;

const FooterContent = styled.div`
  height: 144px;
  padding: 24px 16px 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 2px rgba(60, 64, 67, 0.3),
    0px 2px 6px 2px rgba(60, 64, 67, 0.15);
  z-index: 1;

  form {
    cursor: pointer;
    input,
    label {
      cursor: pointer;
      white-space: nowrap;
    }
  }

  @media ${devices.desktop} {
    height: 120px;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
  }
`;
