import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  useOffers,
  getProductVariants,
  getUniqueFeatures,
} from "../../context/offerContext";

import { devices } from "../../styles";
import notification from "../../assets/notification-black.png";
import LabelBackground from "../../assets/label-background.png";

export default function ItemSelector({ setWillOpenModal, setButtonDisabled }) {
  const { offers, setSelected, setDetails } = useOffers();
  const [current, setCurrent] = useState(null);

  useEffect(() => {
    if (!current) {
      setButtonDisabled(true);
    }
  }, [current, setButtonDisabled]);

  const handleItemClick = (item) => {
    // deselect if clicking on currently selected item
    if (current === item) {
      setCurrent(null);
      setSelected(null);
      setButtonDisabled(true);
    } else {
      // otherwise set selected item to the clicked item, and get the required info for the product
      setCurrent(item);
      setSelected(item);
      setButtonDisabled(false);

      //This gets you all the variants of the current product by name
      let productVariants = getProductVariants(item.name);

      //this is the unique colours
      const colors = getUniqueFeatures(productVariants, "colour");

      // this is the unqique toe types
      const toe = getUniqueFeatures(productVariants, "toe_type");

      // set details to global state so they can be referenced on following pages
      setDetails({ colors, toe });

      if (item.isMadeToMeasure) {
        setWillOpenModal(true);
      } else {
        setWillOpenModal(false);
      }
    }
  };

  return (
    <Wrapper>
      {offers.map((item, index) => {
        const { name } = item;

        // products that have full-width images for the card rather than an image which is placed
        // in the center of the card image background
        const fullWidthProducts = ["Mediven Plus", "Duomed soft"];
        const isFullWidthImage = fullWidthProducts.includes(name);

        return (
          <ItemWrapper
            isSelected={item === current ? true : false}
            onClick={() => {
              handleItemClick(item);
            }}
            key={name}
          >
            <Image>
              {isFullWidthImage ? (
                <>
                  <OpenClosedToeLabel>
                    <img alt="" src={LabelBackground} />
                    <p>Open & Closed Toe</p>
                  </OpenClosedToeLabel>
                  <FullWidthImage alt="" src={item.img} />
                </>
              ) : (
                <img alt="" src={item.img} />
              )}
            </Image>
            <Toggle isSelected={item === current ? true : false} />
            <Text>
              <ItemHeadingContainer>
                <h3>{item.displayName}</h3>
                {name === "Mediven Plus" && <p>Closed Toe Now Available</p>}
              </ItemHeadingContainer>
              <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
              {item.isMadeToMeasure && (
                <Notification>
                  <img alt="" src={notification} height="24px" />
                  <p>This garment is made to measure</p>
                </Notification>
              )}
            </Text>
          </ItemWrapper>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  @media ${devices.tablet} {
    flex-direction: row;
    flex-wrap: wrap;

    margin-top: 72px;
    row-gap: 24px;
  }
`;

const ItemWrapper = styled.div`
  border: ${(props) =>
    props.isSelected
      ? `1px solid ${props.theme.colorTertiary}`
      : "1px solid #d4ccbd"};
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  @media ${devices.tablet} {
    flex-basis: calc(50% - 12px);

    &:nth-child(2n + 1) {
      margin-right: 24px;
    }

    &:not(:last-child) {
      margin-bottom: 0px;
    }
  }
`;

const Image = styled.div`
  background: #e9edf0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  position: relative;
  overflow: hidden;

  img {
    height: 155px;
  }
`;

const Text = styled.div`
  padding: 16px;

  h3 {
    /* margin-bottom: 16px; */
  }
`;
const Toggle = styled.div`
  border-radius: 50%;
  border: ${(props) => `1px solid ${props.theme.colorTertiary}`};
  width: 32px;
  height: 32px;
  position: absolute;
  top: 112px;
  left: 16px;

  &:after {
    width: 22px;
    height: 22px;
    content: "";
    background: ${(props) => props.isSelected && props.theme.colorTertiary};
    border-radius: 50%;
    position: absolute;
    top: 4px;
    left: 4px;
  }
`;
const Notification = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;

  img {
    height: 24px;
    margin-right: 16px;
  }

  p {
    font-size: 12px;
  }
`;

const ItemHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  @media (min-width: 768px) and (max-width: 800px) {
    h3 {
      width: 100%;
    }
    p {
      max-width: 80px;
    }
  }

  p {
    color: #e20074;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
  }
`;

const OpenClosedToeLabel = styled.div`
  img {
    height: unset;
    position: absolute;
    left: 0;
    top: 0;
    height: 93px;
    width: 93px;
    border-top-left-radius: 5px;
  }
  p {
    max-width: 41px;
    position: absolute;
    top: 6px;
    left: 8px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
  }
`;

const FullWidthImage = styled.img`
  @media (max-width: 400px) {
    margin-left: 50px;
  }

  @media ${devices.tablet} {
    margin-left: 40px;
    margin-right: -40px;
  }
  @media ${devices.desktop} {
    margin-left: 20px;
    margin-right: -20px;
  }
`;
