import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { devices } from "../../styles";
import placeholder from "../../assets/test.png";
import { useOffers } from "../../context/offerContext";
import { useResponses } from "../../context/responseContext";
import { PrimaryButton, SecondaryButton } from "../Button";
import PrescriptionInfoForm from "./PrescriptionInfoForm";
import {
  questions,
  RESPONSE_SECTIONS,
} from "../../routes/Questionnaire/questions";
import { capitalizeFirstLetter } from "../../utils/stringUtils";
import { colorHexValues } from "../ColorPicker";
import FinishedModal from "../FinishedModal";

import LabelBackground from "../../assets/label-background.png";

export default function ProductInfo({ openModal, returnToStart, modalOpen }) {
  const { selected, selectedProduct } = useOffers();
  const { responses } = useResponses();
  const [showInfo, setShowInfo] = useState(false);
  const [finishedModalOpen, setFinishedModalOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showError, setShowError] = useState(false);

  const compressionClassKey = responses[RESPONSE_SECTIONS.COMPRESSION_CLASS];
  const compressionClass =
    compressionClassKey.length > 0
      ? questions[2].answers.find((e) => e.key === compressionClassKey).heading
      : null;

  const leftProduct = selectedProduct["left"];
  const rightProduct = selectedProduct["right"];

  // fade in content on initial load
  useEffect(() => {
    gsap.fromTo(
      [...document.querySelectorAll("#content")],
      { opacity: 0 },
      {
        opacity: 1,
        duration: 1,
      }
    );
  }, []);

  useEffect(() => {
    setShowError(false);
  }, [showInfo]);

  const fadeOutAnimation = () => {
    gsap.fromTo(
      [...document.querySelectorAll("#content")],
      { opacity: 1 },
      {
        opacity: 1,
        duration: 0.5,
        onComplete: () => {
          returnToStart();

          // fade back in first question content (will be 0 opacity by default)
          gsap.fromTo(
            [...document.querySelectorAll("#content")],
            { opacity: 0 },
            {
              opacity: 1,
              duration: 0.5,
            }
          );
        },
      }
    );
  };

  const sendEmailButtonClick = () => {
    // if form not validated show error, otherwise open the modal
    if (isError) {
      setShowError(true);
    } else {
      openModal();
    }
  };

  const isFullWidthImage =
    selected.name === "Mediven Plus" || selected.name === "Duomed soft";

  return selected ? (
    <Wrapper id="content">
      <h1>Your garment order code & information</h1>
      <Background>
        <Content>
          <InfoContainer>
            <h2>Your garment information</h2>
            <MainImage>
              {isFullWidthImage ? (
                <FullWidthImage
                  alt="Selected garment"
                  src={selected ? selected.img : placeholder}
                />
              ) : (
                <img
                  alt="Selected garment"
                  src={selected ? selected.img : placeholder}
                />
              )}
            </MainImage>
            <LegDescription>
              {selected && (
                <>
                  <h3>{selected.displayName}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `<b>Compression Class:</b> ${compressionClass}`,
                    }}
                  ></p>
                  {selected && responses[RESPONSE_SECTIONS.TOE_TYPE] && (
                    <p>
                      <b>Toe style: </b>
                      {selected &&
                        responses[RESPONSE_SECTIONS.TOE_TYPE] &&
                        capitalizeFirstLetter(
                          responses[RESPONSE_SECTIONS.TOE_TYPE]
                        )}
                    </p>
                  )}
                  {selected && responses[RESPONSE_SECTIONS.COLOR] && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: "24px",
                      }}
                    >
                      <b style={{ marginRight: "8px" }}>Colour: </b>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            marginRight: "8px",
                            marginBottom: "0px",
                          }}
                        >
                          {selected &&
                            responses[RESPONSE_SECTIONS.COLOR] &&
                            capitalizeFirstLetter(
                              responses[RESPONSE_SECTIONS.COLOR]
                            )}
                        </p>
                        <Dot
                          color={
                            colorHexValues[responses[RESPONSE_SECTIONS.COLOR]]
                          }
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </LegDescription>
          </InfoContainer>
          <LegInfoContainer>
            <LegInfo
              leg="Left"
              productCode={leftProduct ? leftProduct.code : null}
              pipCode={leftProduct ? leftProduct.pip : null}
              size={leftProduct ? leftProduct.size : null}
            />
            <LegInfo
              leg="Right"
              productCode={rightProduct ? rightProduct.code : null}
              pipCode={rightProduct ? rightProduct.pip : null}
              size={rightProduct ? rightProduct.size : null}
            />
          </LegInfoContainer>
        </Content>
      </Background>
      {!showInfo ? (
        <Container>
          <ItemWrapper>
            <Image>
              {isFullWidthImage ? (
                <FullWidthCardImage
                  alt=""
                  src={selected ? selected.img : placeholder}
                />
              ) : (
                <img alt="" src={selected ? selected.img : placeholder} />
              )}
            </Image>
            <Text>
              <h5>{selected.displayName}</h5>
              <p>
                <b>Features</b>
              </p>
              <p dangerouslySetInnerHTML={{ __html: selected.desc }}></p>
            </Text>
          </ItemWrapper>
        </Container>
      ) : (
        <PrescriptionInfoForm
          showError={showError}
          setShowError={setShowError}
          setIsError={setIsError}
        />
      )}
      <ButtonContainer>
        <SecondaryButton
          onClick={() => {
            if (showInfo) {
              setShowInfo(false);
            } else {
              setFinishedModalOpen(true);
            }
          }}
        >
          {showInfo ? "Back" : "Done"}
        </SecondaryButton>
        <PrimaryButton
          onClick={() => {
            if (showInfo) {
              sendEmailButtonClick();
            } else {
              setShowInfo(true);
            }
          }}
        >
          {showInfo ? "Send email" : "Send prescription info"}
        </PrimaryButton>
      </ButtonContainer>
      <FinishedModal
        isOpen={finishedModalOpen}
        close={() => setFinishedModalOpen(false)}
        reset={fadeOutAnimation}
      />
    </Wrapper>
  ) : null;
}

const LegInfo = ({ leg, productCode, pipCode, color, size }) => {
  return (
    <LegInfoWrapper style={{}}>
      <Heading>
        <Icon>{leg.charAt(0)}</Icon>
        <h3>{leg} Leg</h3>
      </Heading>
      <ProductInfoContainer>
        <div
          style={{ display: "flex", flexDirection: "row", minHeight: "115px" }}
        >
          <ItemInfo>
            <h6>Order code</h6>
            <h5>{productCode ? productCode : "-"}</h5>
          </ItemInfo>
          <ItemInfo>
            <h6>PIP code</h6>
            <h5>{pipCode ? pipCode : "-"}</h5>
          </ItemInfo>
          <ItemInfo>
            <h6>Size</h6>
            <h5>{size ? size : "-"}</h5>
          </ItemInfo>
        </div>
        <div></div>
      </ProductInfoContainer>
    </LegInfoWrapper>
  );
};

const Wrapper = styled.div`
  color: ${(props) => props.theme.colorSecondary};

  h1 {
    padding: 28px 16px;
    color: ${(props) => props.theme.colorTertiary};
  }

  @media ${devices.tablet} {
    h1 {
      padding: 28px 72px;
      margin: 0 auto;
    }
  }
  @media ${devices.desktop} {
    h1 {
      max-width: 900px;
      padding: 80px 72px;
    }
  }
`;
const Background = styled.div`
  color: ${(props) => props.theme.colorSecondary};
  position: relative;
  background: #48586a;
`;

const Content = styled.div`
  color: ${(props) => props.theme.backgroundPrimary};
  padding: 16px;

  h2 {
    margin: 8px 0px;
  }

  h3 {
    margin: 16px 0px;
  }

  p {
    margin-bottom: 16px;
  }

  @media ${devices.tablet} {
    padding: 16px 72px;

    h2 {
      margin-bottom: 24px;
    }
  }

  @media ${devices.desktop} {
    max-width: 900px;
    margin: 0 auto;
    box-sizing: border-box;
  }
`;

const InfoContainer = styled.div`
  @media ${devices.tablet} {
    border-bottom: 1px solid white;
    padding-bottom: 8px;
  }
`;

const LegInfoContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;

  @media ${devices.tablet} {
    display: flex;
    flex-direction: row;
  }
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
`;

const Container = styled.div`
  position: relative;
  top: -72px;
  padding: 0px 16px;
  color: #000000;

  @media ${devices.tablet} {
    margin: 0 auto;
    max-width: 341px;
    padding: 0px;
    top: -24px;
  }
`;

const Image = styled.div`
  background: #e9edf0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 6px;
  /* margin-top: -1px; */
  img {
    width: 103px;
  }
`;

const ItemWrapper = styled.div`
  border: ${(props) =>
    props.isSelected
      ? `1px solid ${props.theme.colorTertiary}`
      : "1px solid #d4ccbd"};
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  ${Image} {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

const MainImage = styled(Image)`
  position: relative;

  @media ${devices.tablet} {
    img {
      width: 200px;
    }
  }
  @media ${devices.desktop} {
    img {
      width: 211px;
    }
  }
`;

const Text = styled.div`
  padding: 16px;

  h5 {
    font-size: 18px;
    margin-bottom: 16px;
  }

  p {
    &:not(:last-child) {
      margin-bottom: 8px;
      font-size: 16px;
    }
  }
`;

const LegInfoWrapper = styled.div`
  margin-bottom: 24px;
  border-top: 1px solid white;
  padding-top: 24px;

  @media ${devices.tablet} {
    flex: 1;
    padding: 0px;
    border: none;

    &:first-child {
      border-right: 1px solid white;
    }

    &:not(:first-child) {
      padding-left: 40px;
    }
    &:not(:last-child) {
      padding-right: 40px;
    }
  }
`;

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  h6 {
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 16px;
  }

  h5 {
    font-size: 18px;
  }

  p {
    margin-top: 8px;
    font-size: 12px;
  }
`;

const Icon = styled.div`
  border-radius: 50%;
  background: white;
  width: 36px;
  height: 36px;
  color: black;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 18px 30px;
  box-shadow: 0px 1px 2px rgba(60, 64, 67, 0.3),
    0px 2px 6px 2px rgba(60, 64, 67, 0.15);
  margin-top: 12px;

  button {
    &:first-child {
      margin-right: 24px;
    }
    font-size: 14px;
  }

  @media ${devices.tablet} {
    margin-top: 56px;
    padding: 18px 94px;

    button {
      font-size: 16px;
    }
  }
`;

const Dot = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${(props) => props.color};
  border: 1px solid #ebebee;
  box-shadow: 0px 1px 2px rgba(60, 64, 67, 0.3),
    0px 1px 3px 1px rgba(60, 64, 67, 0.15);
`;

const LegDescription = styled.div`
  @media ${devices.tablet} {
    padding-right: 24px;
  }
`;

const ProductInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  > div:first-child {
    flex: 3;
  }
  > div {
    flex: 1;
  }
  @media (max-width: 400px) {
    flex-direction: column;
  }
  @media ${devices.tablet} {
    flex-direction: column;
  }
`;

const OpenClosedToeLabel = styled.div`
  img {
    height: unset;
    position: absolute;
    left: 0;
    top: 0;
    height: 93px;
    width: 93px;
    border-top-left-radius: 5px;
  }
  p {
    max-width: 41px;
    position: absolute;
    top: 6px;
    left: 8px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
  }
`;

const FullWidthImage = styled.img`
  max-height: 123px;
  width: auto !important;

  @media ${devices.tablet} {
    max-height: 240px;
  }
  @media ${devices.desktop} {
    max-height: 250px;
  }
`;

const FullWidthCardImage = styled.img`
  width: auto !important;
  max-height: 123px;
`;
