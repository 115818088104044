import React from "react";
import styled from "styled-components";

// import icon from "../../assets/test.svg";
import { devices } from "../../styles";
import pdfIcon from "../../assets/pdf.png";
import altImage from "../../assets/alt-progress.svg";
import altImageComplete from "../../assets/alt-progress-complete.svg";
import altImageWhite from "../../assets/alt-progress-white.svg";

export default function ProgressBar({
  isQuestionnaire = false,
  progress = [],
}) {
  const algorithmIndex = 3;

  return (
    <Wrapper isQuestionnaire={isQuestionnaire}>
      {progress.map((item, index) => {
        return (
          <React.Fragment key={index}>
            {algorithmIndex + 1 === index ? null : (
              <>
                {index !== 0 && <Line isComplete={item} />}
                {isQuestionnaire &&
                (algorithmIndex === index || algorithmIndex + 1 === index) ? (
                  <AltDot
                    alt=""
                    src={
                      progress[algorithmIndex + 1] ? altImageComplete : altImage
                    }
                  ></AltDot>
                ) : (
                  <Dot isQuestionnaire={isQuestionnaire} isComplete={item} />
                )}
              </>
            )}
          </React.Fragment>
        );
      })}
    </Wrapper>
  );
}

export const LeaveAppProgress = () => {
  return (
    <LeaveAppWrapper>
      <DotAlt />
      <LineAlt />
      <DotAlt />
      <LineAlt />
      <DotAlt />
      <LineAlt />
      <img
        alt=""
        style={{ marginLeft: "8px", marginRight: "8px" }}
        height="24px"
        width="24px"
        src={altImageWhite}
      />
      <LineAlt />
      <img
        alt=""
        style={{ marginLeft: "8px" }}
        height="24px"
        width="24px"
        src={pdfIcon}
      />
    </LeaveAppWrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  max-width: ${(props) => (props.isQuestionnaire ? "877px" : "877px")};

  @media ${devices.tablet} {
    max-width: ${(props) => (props.isQuestionnaire ? "877px" : "877px")};
  }
`;

const LeaveAppWrapper = styled(Wrapper)`
  justify-self: flex-start;
  width: 155px;
  margin-left: 72px;

  @media ${devices.tablet} {
    margin-left: 88px;
    width: 300px;
  }
  @media ${devices.desktop} {
    margin-left: 300px;
  }
`;

const Dot = styled.div`
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background: ${(props) =>
    props.isComplete ? props.theme.colorPrimary : "#D4CCBD"};
  position: relative;

  &:not(:first-child) {
    margin-left: ${(props) => (props.isQuestionnaire ? "8px" : "10px")};
  }
  &:not(:last-child) {
    margin-right: ${(props) => (props.isQuestionnaire ? "8px" : "10px")};
  }

  @media (max-width: 400px) {
    &:not(:first-child) {
      margin-left: ${(props) => (props.isQuestionnaire ? "6px" : "10px")};
    }
    &:not(:last-child) {
      margin-right: ${(props) => (props.isQuestionnaire ? "6px" : "10px")};
    }
  }
`;

const DotAlt = styled(Dot)`
  background: ${(props) => props.theme.colorSecondary};
`;

const Line = styled.div`
  height: 2px;
  background: ${(props) =>
    props.isComplete ? props.theme.colorPrimary : "#D4CCBD"};
  flex: 1;
`;
const LineAlt = styled(Line)`
  background: ${(props) => props.theme.colorSecondary};
`;

const AltDot = styled.img`
  height: 17px;
  width: 17px;
  margin: 0px 8px 0px 8px;

  @media (max-width: 400px) {
    &:not(:first-child) {
      margin-left: 6px;
    }
    &:not(:last-child) {
      margin-right: 6px;
    }
  }
`;
