import React, { useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { devices } from "../../styles";
import PlayIcon from "../../assets/play-icon.png";
import AndroidInstructions from "./android-pin-instructions.mov";
import IOSInstructions from "./ios-pin-instructions.mov";

export default function IntroGuideModal({ device, isOpen, close }) {
  const vidRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);

  const videos = {
    ios: { heading: "iOS", videoSrc: IOSInstructions },
    android: { heading: "Android", videoSrc: AndroidInstructions },
  };

  const handlePlayVideo = () => {
    vidRef.current.play();
    setIsPlaying(true);
  };

  const closeModal = () => {
    if (isPlaying) {
      vidRef.current.pause();
      setIsPlaying(false);
    }
    close();
  };

  return device !== false ? (
    <Modal isOpen={isOpen} close={closeModal} device={videos[device].heading}>
      <Video
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        ref={vidRef}
        controls
      >
        <source src={videos[device].videoSrc} type="video/mp4" />
      </Video>
      {/* {!isPlaying && (
        <PlayButton onClick={() => handlePlayVideo()}>
          <img src={PlayIcon} alt="Play" />
        </PlayButton>
      )} */}
    </Modal>
  ) : null;
}

const PlayButton = styled.button`
  margin: 0px;
  padding: 0px;
  position: absolute;
  width: 48px;
  height: 48px;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: none;
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
  }

  @media ${devices.desktop} {
    width: 72px;
    height: 72px;
    top: calc(50% - 36px);
    left: calc(50% - 36px);

    img {
      width: 40px;
      height: 40px;
    }
  }
`;

const Video = styled.video`
  width: 300px;
  max-width: 100%;
  object-fit: cover;
  min-width: 80%;

  @media ${devices.tablet} {
    width: 618px;
  }
  @media ${devices.desktop} {
    width: 900px;
  }

  @media (min-width: 1440px) {
    width: 1224px;
  }

  @media (min-height: 300px) {
    max-height: 55vh;
    width: unset;
  }

  @media (min-height: 700px) {
    max-height: 65vh;
    width: unset;
  }
`;

const Modal = ({ device, isOpen, close, children }) => {
  return isOpen ? (
    <Wrapper isOpen={isOpen}>
      <Backdrop onClick={close}></Backdrop>
      <Container>
        <TopBar>
          <h3>Instructions for {device}</h3>
          <CloseButton close={close} />
        </TopBar>
        <Content>{children}</Content>
      </Container>
    </Wrapper>
  ) : null;
};

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(75, 93, 107, 0.95);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fadeIn} 0.2s linear;
`;

const Container = styled.div`
  background: ${(props) => props.theme.backgroundSecondary};
  background: linear-gradient(206.12deg, #e20074 1.01%, #b9005f 100%);
  border-radius: 12px;
  margin-inline: 10px;
  position: relative;

  @media ${devices.tablet} {
    margin-inline: 72px;
  }
  @media ${devices.tablet} {
    margin-inline: 72px;
  }
`;

const TopBar = styled.div`
  height: 80px;
  border-bottom: 1px solid #ffffff;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-inline: 16px;
  position: relative;

  @media ${devices.tablet} {
    padding-inline: 32px;
  }

  @media ${devices.desktop} {
    padding-inline: 36px;
    height: 124px;

    h3 {
      font-size: 38px;
      line-height: 52px;
    }
  }
`;

const Content = styled.div`
  margin: auto;
  max-width: 100%;
  width: 100%;
  text-align: center;
  position: relative;
  padding: 28px 24px;
  color: ${(props) => props.theme.colorSecondary};

  /* img {
    height: 48px;
    width: 48px;
    margin-bottom: 24px;
  } */

  h3 {
    margin-bottom: 24px;
  }

  p {
    margin-bottom: 40px;
  }

  /* button {
    &:last-child {
      margin-left: 24px;
    }
  } */

  @media ${devices.tablet} {
    /* padding: 72px 36px; */
    max-width: 690px;
    width: 100%;
  }
  @media ${devices.desktop} {
    max-width: 1296px;
    padding: 36px;
    /* width: 100%; */
  }
`;

const CloseButtonWrapper = styled.div`
  background: white;
  border-radius: 50%;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: flex-end;
  cursor: pointer;

  div {
    content: "";
    width: 24px;
    height: 2px;
    background-color: black;
    position: absolute;

    &:first-child,
    &:last-child {
      transform-origin: center;
    }

    &:first-child {
      transform: rotate(45deg);
    }
    &:last-child {
      transform: rotate(-45deg);
    }
  }
`;

const CloseButton = ({ close }) => {
  return (
    <CloseButtonWrapper onClick={() => close()}>
      <div></div>
      <div></div>
    </CloseButtonWrapper>
  );
};
