import React, { useEffect, useReducer, useState } from "react";
import styled from "styled-components";

import placeholder from "../../assets/placeholder.png";
import {
  getAllProducts,
  getUniqueFeatures,
  getUniqueProduct,
  useOffers,
} from "../../context/offerContext";
import { useResponses } from "../../context/responseContext";
import { RESPONSE_SECTIONS } from "../../routes/Questionnaire/questions";
import { devices } from "../../styles";

export const MEASUREMENT_LABELS = {
  AD: "A–D length (floor to below knee crease)",
  ID: "A–D length (floor to below knee crease)",
  measurement_BD:
    "B-D length (2 fingers width above ankle bone to 2 fingers width below knee crease)",
  CC: "cC circumference (widest calf)",
  CB: "cB circumference (above ankle bone)",
  measurement_CB1: "cB1 (is halfway between cB and cC)",
};

const minValue = 0;
const maxValue = 100;

const measurementsReducer = (state, action) => {
  const { key, value, leg, range } = action;

  let valueToSet;

  if (value.toString().length === 1) {
    valueToSet = !isNaN(value)
      ? Math.min(Math.max(value, minValue), maxValue)
      : "";
  } else {
    valueToSet = !isNaN(value)
      ? Math.min(
          Math.max(value, range ? range[0] : minValue),
          range ? range[1] : maxValue
        )
      : "";
  }

  switch (leg) {
    case "left":
    case "right":
      return {
        ...state,
        [leg]: { ...state[leg], [key]: valueToSet },
      };
    default:
      return {
        left: {
          ...state["left"],
          [key]: valueToSet,
        },
        right: {
          ...state["right"],
          [key]: valueToSet,
        },
      };
  }
};

const defaultState = {
  left: {},
  right: {},
};

export default function Measurements({
  setButtonDisabled,
  showError = false,
  setShowError,
  setIsError,
  setIsCombinedError,
  updateMeasurements,
}) {
  const [tab, setTab] = useState("both");
  const { details, setDetails, selected, setSelectedProduct } = useOffers();
  const { responses, updateResponse } = useResponses();
  const [hover, setHover] = useState(null);
  const [colors, setColors] = useState(null);

  // store the values the user enters in the inputs
  const [state, dispatch] = useReducer(
    measurementsReducer,
    responses[RESPONSE_SECTIONS.MEASUREMENTS] || defaultState
  );

  // when a measurement is entered, update the 'responses' global object with the given measurements
  useEffect(() => {
    updateResponse(RESPONSE_SECTIONS.MEASUREMENTS, state);
  }, [state]);

  useEffect(() => {
    setButtonDisabled(false);
  }, [setButtonDisabled]);

  useEffect(() => {
    setShowError(false);
  }, [tab, setShowError]);

  useEffect(() => {
    // add red border to all input fields that have an error
    const showInputErrors = () => {
      setIsCombinedError(false);
      let isInputError = false;

      // reset all inputs to have no border
      const inputs = [
        ...document.getElementsByTagName("input"),
        ...document.getElementsByTagName("select"),
      ];
      inputs.forEach((i) => {
        i.style.border = "1px solid #D4CCBD";
      });

      const required = selected.measurementsRequired;

      required.forEach((item) => {
        const { name, range } = item;
        const isBD = name === "measurement_BD";

        if (tab === "left" || tab === "both") {
          const el = document.getElementById(`left-${name}`);
          const value = el ? el.value : null;
          const inputError = isBD
            ? value === "default"
            : !value || value < range[0] || value > range[1];

          if (el && inputError && showError) {
            el.style.border = "1px solid red";
            isInputError = true;
          }
        }
        if (tab === "right" || tab === "both") {
          const el = document.getElementById(`right-${name}`);
          const value = el ? el.value : null;
          const inputError = isBD
            ? value === "default"
            : !value || value < range[0] || value > range[1];

          if (el && inputError && showError) {
            el.style.border = "1px solid red";
            isInputError = true;
          }
        }
      });

      // if inputs are all in correct range, but there is still an error it means there is no product with these combined dimensions
      // so show alternate error message
      if (!isInputError && showError) {
        const uniqueProductsLeft = getUniqueProduct(
          selected.name,
          state["left"]["CC"] || null,
          state["left"]["CB"] || null,
          state["left"]["ID"] || null,
          state["left"]["measurement_CB1"] || null,
          state["left"]["measurement_BD"] || null,
          null,
          null,
          responses["compression_class"]
        );
        const uniqueProductsRight = getUniqueProduct(
          selected.name,
          state["right"]["CC"] || null,
          state["right"]["CB"] || null,
          state["right"]["ID"] || null,
          state["right"]["measurement_CB1"] || null,
          state["right"]["measurement_BD"] || null,
          null,
          null,
          responses["compression_class"]
        );
        setIsCombinedError(true);

        let inputs = [];

        const isLeftError = uniqueProductsLeft[0] === "error";
        const isRightError = uniqueProductsRight[0] === "error";

        // only show input error border if the particular leg has no products associated (ie - it has been filled out incorrectly)
        if (isLeftError && isRightError) {
          inputs = [
            ...document.getElementsByTagName("input"),
            ...document.getElementsByTagName("select"),
          ];
        } else if (isLeftError && !isRightError) {
          inputs = [...document.getElementsByClassName("left-input")];
        } else if (!isLeftError && isRightError) {
          inputs = [...document.getElementsByClassName("right-input")];
        }

        inputs.forEach((i) => {
          i.style.border = "1px solid red";
        });
      }
    };

    showInputErrors();
  }, [
    showError,
    selected.measurementsRequired,
    setIsCombinedError,
    tab,
    state,
  ]);

  useEffect(() => {
    function isMissingInputs() {
      const { measurementsRequired } = selected;
      const measurementsCount = measurementsRequired.length;

      const leftValues = Object.keys(state.left);
      const rightValues = Object.keys(state.right);

      const isLeftInputted =
        leftValues.length === measurementsCount &&
        !leftValues.find((e) => (state["left"][e] === "" ? true : false));
      const isRightInputted =
        rightValues.length === measurementsCount &&
        !rightValues.find((e) => (state["right"][e] === "" ? true : false));

      const isMissingInput =
        tab === "left"
          ? !isLeftInputted
          : tab === "right"
          ? !isRightInputted
          : !(isLeftInputted && isRightInputted);

      return isMissingInput;
    }

    setShowError(false);
    setIsCombinedError(false);

    const isMissingInput = isMissingInputs();

    // if there are no unique products available with these measurements, show an error message
    const uniqueProductsLeft = getUniqueProduct(
      selected.name,
      state["left"]["CC"] || null,
      state["left"]["CB"] || null,
      state["left"]["ID"] || null,
      state["left"]["measurement_CB1"] || null,
      state["left"]["measurement_BD"] || null,
      null,
      null,
      responses["compression_class"]
    );
    const uniqueProductsRight = getUniqueProduct(
      selected.name,
      state["right"]["CC"] || null,
      state["right"]["CB"] || null,
      state["right"]["ID"] || null,
      state["right"]["measurement_CB1"] || null,
      state["right"]["measurement_BD"] || null,
      null,
      null,
      responses["compression_class"]
    );

    const allProductsLeft = getAllProducts(
      selected.name,
      state["left"]["CC"] || null,
      state["left"]["CB"] || null,
      state["left"]["ID"] || null,
      state["left"]["measurement_CB1"] || null,
      state["left"]["measurement_BD"] || null,
      null,
      null,
      responses["compression_class"]
    );
    const allProductsRight = getAllProducts(
      selected.name,
      state["right"]["CC"] || null,
      state["right"]["CB"] || null,
      state["right"]["ID"] || null,
      state["right"]["measurement_CB1"] || null,
      state["right"]["measurement_BD"] || null,
      null,
      null,
      responses["compression_class"]
    );

    if (selected.name.toLowerCase().indexOf("juxta") === -1) {
      // get available colors for left leg product and right leg product (based on currently inputted measurements)
      const colorsLeft = getUniqueFeatures(allProductsLeft, "colour");
      const colorsRight = getUniqueFeatures(allProductsRight, "colour");

      // get intersection of colors -- only colors that are available for both legs
      const sharedColors = [
        ...new Set([...colorsLeft.filter((col) => colorsRight.includes(col))]),
      ];

      // set colors to offer on the color picker screen to be shared colors for both legs
      setDetails({ ...details, colors: sharedColors });

      // if only one color available, set the selected color to be the only available one
      if (sharedColors.length === 1) {
        setColors(sharedColors[0]);
      }
    }

    const { measurementsRequired } = selected;
    const measurementsCount = measurementsRequired.length;

    const leftValues = Object.keys(state.left);
    const rightValues = Object.keys(state.right);

    const isLeftInputted =
      leftValues.length === measurementsCount &&
      !leftValues.find((e) => (state["left"][e] === "" ? true : false));
    const isRightInputted =
      rightValues.length === measurementsCount &&
      !rightValues.find((e) => (state["right"][e] === "" ? true : false));

    const isLeftProduct = !(uniqueProductsLeft[0] === "error");
    const isRightProduct = !(uniqueProductsRight[0] === "error");

    if (
      isMissingInput ||
      (tab !== "right" && !isLeftProduct) ||
      (tab !== "left" && !isRightProduct) ||
      (!isLeftProduct && !isRightProduct)
    ) {
      setIsError(true);
    } else {
      // otherwise go to next page
      setIsError(false);
      setSelectedProduct({
        left: isLeftInputted ? uniqueProductsLeft : null,
        right: isRightInputted ? uniqueProductsRight : null,
      });
    }
  }, [
    state,
    selected,
    setButtonDisabled,
    tab,
    setIsCombinedError,
    setShowError,
    setIsError,
    setSelectedProduct,
  ]);

  // when clicking 'next' btn on measurements input screen, update the color to the value stored in 'colors' state
  // to avoid multiple setState calls potentially overwriting each other
  useEffect(() => {
    if (updateMeasurements) {
      updateResponse(RESPONSE_SECTIONS.COLOR, colors);
    }
  }, [updateMeasurements]);

  return (
    <Wrapper>
      <Image>
        <img
          alt=""
          src={
            selected.measurementGuide ? selected.measurementGuide : placeholder
          }
        />
      </Image>
      <TabHeader>
        <TabItem
          showBorder={tab === "both" && (hover === null || hover === "right")}
          onClick={() => setTab("left")}
          onMouseEnter={() => setHover("left")}
          onMouseLeave={() => setHover(null)}
          isSelected={tab === "left"}
        >
          Left leg
        </TabItem>
        <TabItem
          showBorder={tab === "left" && (hover === null || hover === "left")}
          onClick={() => setTab("right")}
          onMouseEnter={() => setHover("middle")}
          onMouseLeave={() => setHover(null)}
          isSelected={tab === "right"}
        >
          Right leg
        </TabItem>
        <TabItem
          onClick={() => setTab("both")}
          isSelected={tab === "both"}
          onMouseEnter={() => setHover("right")}
          onMouseLeave={() => setHover(null)}
        >
          Both legs
        </TabItem>
      </TabHeader>
      <MeasureContainer>
        {tab !== "right" && (
          <MeasureInput
            heading="Left leg"
            measurements={selected.measurementsRequired}
            dispatch={dispatch}
            state={state["left"]}
          />
        )}
        {tab !== "left" && (
          <MeasureInput
            heading="Right leg"
            measurements={selected.measurementsRequired}
            dispatch={dispatch}
            state={state["right"]}
          />
        )}
      </MeasureContainer>
    </Wrapper>
  );
}

const MeasureInput = ({ heading, measurements, dispatch, state }) => {
  const { selected } = useOffers();
  const leg = heading.split(" ")[0].toLowerCase();

  const handleInputChange = (e, key, range) => {
    dispatch({
      key,
      value: e.target.valueAsNumber,
      leg,
      range,
    });
  };

  return (
    <MeasureWrapper>
      <h3>{heading}</h3>
      <form>
        {measurements.map((item, index) => {
          const { name, range } = item;

          const rangeMin = range[0];
          const rangeMax = range[1];

          // BD measurement always has 2 out of the values 28, 33, 36 so show dropdown for BD measurement
          if (name === "measurement_BD") {
            // juxtalite should have 28 & 33 as options --- juxtafit should have 28 & 36
            const secondValue = selected.name
              .toLowerCase()
              .includes("juxtalite")
              ? 33
              : 36;

            return (
              <React.Fragment key={index}>
                <label>{MEASUREMENT_LABELS[name] || name}</label>
                <div>
                  <select
                    className={`${leg}-input`}
                    id={`${leg}-${name}`}
                    onChange={(e) =>
                      dispatch({
                        key: name,
                        value: e.target.value,
                        leg: heading.split(" ")[0].toLowerCase(),
                      })
                    }
                    defaultValue="default"
                  >
                    <option value="default" disabled hidden>
                      Select value...
                    </option>
                    <option value={28}>28</option>
                    <option value={secondValue}>{secondValue}</option>
                  </select>
                  <span>cm</span>
                </div>
              </React.Fragment>
            );
          }

          // otherwise show standard input field and allow user to enter a number value
          return (
            <React.Fragment key={index}>
              <label>{MEASUREMENT_LABELS[name] || name}</label>
              <div>
                <input
                  className={`${leg}-input`}
                  id={`${heading.split(" ")[0].toLowerCase()}-${name}`}
                  min={rangeMin}
                  max={rangeMax}
                  onChange={(e) => {
                    handleInputChange(e, name, range);
                  }}
                  value={state[name] || ""}
                  type="number"
                  onWheel={(e) => {
                    // onwheel event to not allow scrolling to affect the input value inside the field when the field is in focus
                    e.target.blur();
                  }}
                />
                <span>cm</span>
              </div>
            </React.Fragment>
          );
        })}
      </form>
    </MeasureWrapper>
  );
};

const Wrapper = styled.div`
  label {
    margin: 0px;
  }

  margin-bottom: 12px;

  @media ${devices.tablet} {
    margin-bottom: 0px;
  }
`;

const Image = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #e9edf0;
  border-radius: 6px;
  margin: 24px 0px;

  img {
    height: 155px;
    width: 100%;
  }

  @media ${devices.tablet} {
    margin-top: 72px;
    margin-bottom: 36px;

    img {
      height: 290px;
    }
  }
  @media ${devices.desktop} {
    margin-top: 72px;
    margin-bottom: 36px;
  }
`;

const TabHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  border: 1px solid ${(props) => props.theme.backgroundSecondary};
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;

  @media ${devices.tablet} {
    max-width: 343px;
    margin: 0 auto;
  }
  @media ${devices.desktop} {
    max-width: 100%;
  }
`;

const TabItem = styled.div`
  color: ${(props) =>
    props.isSelected ? props.theme.colorSecondary : props.theme.colorTertiary};
  background: ${(props) =>
    props.isSelected ? props.theme.colorTertiary : props.theme.colorSecondary};
  flex: 1;
  text-align: center;
  padding: 8px 0px;
  margin: 2px 0px;
  align-self: center;
  border-radius: ${(props) => props.isSelected && `6px`};
  font-weight: 700;

  border-right: ${(props) =>
    props.showBorder
      ? `1px solid ${props.theme.colorTertiary}`
      : `0px solid #ffffff`};

  margin-right: ${(props) => (props.showBorder ? "0px" : "1px")};

  &:first-child {
    margin-left: 2px;
  }
  &:last-child {
    margin-right: 2px;
  }

  &:hover {
    background-color: #8d004c;
    color: ${(props) => props.theme.colorSecondary};
    border-radius: 6px;
    border: none;
  }
`;

const MeasureContainer = styled.div`
  @media ${devices.tablet} {
    display: flex;
  }
`;

const MeasureWrapper = styled.div`
  margin-top: 24px;

  form {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
  }
  label {
    color: #48586a;
  }
  label:not(:first-child) {
    margin-top: 24px;
  }
  input,
  select {
    margin-top: 8px;
    width: 100%;
    color: ${(props) => props.colorPrimary};
  }
  option {
    font-size: 16px;
  }
  span {
    margin-left: -50px;
    font-size: 12px;
    font-weight: bold;
  }

  @media ${devices.tablet} {
    margin-top: 36px;
    margin-bottom: 0px;
    flex-grow: 1;

    &:first-child {
      &:not(:only-child) {
        margin-right: 24px;
      }
    }
  }
`;
