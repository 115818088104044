import React from "react";
import styled from "styled-components";
import { devices } from "../../styles";

export default function Modal({
	isOpen,
	close,
	children = "Test content",
	leaveApp = false,
}) {
	return isOpen ? (
		<Wrapper>
			<Container>
				<TopBar leaveApp={leaveApp}>
					<CloseButton close={close} />
				</TopBar>
				<Content leaveApp={leaveApp}>{children}</Content>
			</Container>
		</Wrapper>
	) : null;
}

const Wrapper = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(75, 93, 107, 0.95);
	z-index: 1;
`;

const Container = styled.div`
	background: ${(props) => props.theme.backgroundSecondary};
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	margin-left: 16px;

	@media ${devices.tablet} {
		margin-left: 56px;
	}
	@media ${devices.desktop} {
		margin-left: 120px;
	}
`;

const TopBar = styled.div`
	height: 80px;
	border-bottom: 1px solid #ffffff;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	padding-right: 16px;
	position: relative;
`;

const Content = styled.div`
	margin: auto;
	max-width: 100%;
	text-align: center;
	position: relative;	
	padding: ${(props) => props.leaveApp ? '28px 12.5px' : '28px 24px'};
	color: ${(props) => props.theme.colorSecondary};

	img {
		height: 48px;
		width: 48px;
		margin-bottom: 24px;
	}

	h3 {
		margin-bottom: 24px;
	}

	p {
		margin-bottom: 40px;
	}

	button {
		&:last-child {
			margin-left: 24px;
		}
	}

	@media ${devices.tablet} {
		padding: ${(props) => props.leaveApp ? '72px 72px' : '72px 222px'};

		h3, p {
			margin-inline: auto;
		}

		h3 {
			max-width: 400px;
		}
		p {
			max-width: 550px;
		}
	}
	@media ${devices.desktop} {
		padding-top: 72px;
		padding-left: 222px;
		padding: 72px 222px 120px;

		h3 {
			max-width: unset;
		}
		p {
			max-width: 650px;
		}
	}
`;

const CloseButtonWrapper = styled.div`
	background: white;
	border-radius: 50%;
	height: 48px;
	width: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	justify-self: flex-end;
	cursor: pointer;

	div {
		content: "";
		width: 24px;
		height: 2px;
		background-color: black;
		position: absolute;

		&:first-child,
		&:last-child {
			transform-origin: center;
		}

		&:first-child {
			transform: rotate(45deg);
		}
		&:last-child {
			transform: rotate(-45deg);
		}
	}
`;

const CloseButton = ({ close }) => {
	return (
		<CloseButtonWrapper onClick={() => close()}>
			<div></div>
			<div></div>
		</CloseButtonWrapper>
	);
};
