import React, { useEffect } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import AWS from "aws-sdk";
import dotenv from "dotenv";
import ReactGA from "react-ga4";

import Intro from "./routes/Intro";
import Questionnaire from "./routes/Questionnaire";
import Welcome from "./routes/Welcome";
import { defaultTheme, GlobalStyles } from "./styles";

ReactGA.initialize("G-L257CD7841");
ReactGA.set({ anonymizeIp: true });

let broadcast;

try {
  broadcast = new BroadcastChannel("mediuk");
} catch (err) {}

const SESConfig = {
  apiVersion: "2010-12-01",
  accessKeyId: process.env.AWS_SES_ACCESS_KEY_ID,
  secretAccessKey: process.env.AWS_SES_SECRET_ACCESS_KEY,
  region: process.env.AWS_SES_REGION,
};

dotenv.config();

export default function App() {
  // fix for ipad to remove scrolling, because vh works differently
  useEffect(() => {
    const updateAppHeight = () => {
      const wrapper = document.getElementById("wrapper");
      wrapper.style.minHeight = `${window.innerHeight}px`;
    };

    window.addEventListener("resize", updateAppHeight);

    return () => window.removeEventListener("resize", updateAppHeight);
  }, []);

  // detect when app is back online and send a message to the service worker so it can send any scheduled emails
  useEffect(() => {
    const notifySW = () => {
      if (navigator && navigator.serviceWorker) {
        if (navigator.serviceWorker.controller) {
          navigator.serviceWorker.controller.postMessage({ isOnline: true });
        }
      }
    };
    window.addEventListener("online", notifySW);

    return () => window.removeEventListener("online", notifySW);
  }, []);

  useEffect(() => {
    if (broadcast) {
      broadcast.onmessage = (event) => {
        const params = event.data.data;

        new AWS.SES(SESConfig).sendEmail(params).promise();
      };
    }
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <AppWrapper id="wrapper">
        <Switch>
          <Route path="/" exact component={Welcome} />
          <Route path="/intro" exact component={Intro} />
          <Route path="/questionnaire" exact component={Questionnaire} />
          <Redirect to="/" />
        </Switch>
      </AppWrapper>
    </ThemeProvider>
  );
}

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
