import React from "react";
import { QuaternaryButton, TertiaryButton } from "../Button";
import Modal from "../Modal";
import Notification from "../../assets/notification.png";

export default function FinishedModal({ isOpen, close, reset }) {
  const handleClick = () => {
    reset();
  };

  return (
    <Modal isOpen={isOpen} close={close}>
      <img alt="" src={Notification} />
      <h3>Have you sent your patient's prescription?</h3>
      <p>
        All information will be lost if you click ‘I’m finished’. This action
        cannot be undone.
      </p>
      <QuaternaryButton onClick={() => close()}>Back</QuaternaryButton>
      <TertiaryButton onClick={() => handleClick()}>
        I'm finished
      </TertiaryButton>
    </Modal>
  );
}
