import React, { useEffect, useState } from "react";
import gsap from "gsap";
import Loader from "../Loader";
import { useResponses } from "../../context/responseContext";
import {
  useOffers,
  getProductCollectionNames,
} from "../../context/offerContext";

export default function Algorithm({ goToNext }) {
  const [isLoading, setIsLoading] = useState(true);
  const { getScore, getCompressionClass } = useResponses();
  const { setOffers } = useOffers();

  useEffect(() => {
    const timeout = setTimeout(() => {
      let productCollections = getProductCollectionNames(
        getScore(),
        getCompressionClass()
      );

      setOffers(productCollections);

      gsap.fromTo(
        [...document.querySelectorAll("#content")],
        { opacity: 1 },
        {
          opacity: 0,
          duration: 0.5,
          onComplete: () => {
            setIsLoading(false);
            gsap.fromTo(
              [...document.querySelectorAll("#content")],
              { opacity: 0 },
              {
                opacity: 1,
                duration: 0.5,
              }
            );
          },
        }
      );
    }, 2000);

    return () => clearTimeout(timeout);
  }, [setOffers, getScore, getCompressionClass]);

  useEffect(() => {
    if (!isLoading) {
      goToNext();
    }
  }, [isLoading, goToNext]);

  // get user responses to the questions asked
  // use these to work out which products to offer them on the following pages
  // save these responses to a global context so they can be accessed elsewhere

  return <Loader />;
}
