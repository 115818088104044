import React, { useState } from "react";
import infoIcon from "../../assets/notification-pink.png";
import { RESPONSE_SECTIONS } from "../../routes/Questionnaire/questions";
import {
  Wrapper,
  BodyText,
  Button,
  CloseButton,
  Content,
  Header,
  InfoIcon,
  LimbBodyText,
  Toggle,
  LimbWrapper,
  LimbImage,
  CompressionWrapper,
  CompressionBodyText,
} from "./styles";

export default function Answer({
  section,
  selectAnswer,
  isSelected,
  heading = "heading",
  subheading,
  body = "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
  image,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);

  return section === RESPONSE_SECTIONS.LIMB ? (
    <LimbAnswer
      selectAnswer={selectAnswer}
      isSelected={isSelected}
      heading={heading}
      subheading={subheading}
      body={body}
      image={image}
    />
  ) : section === RESPONSE_SECTIONS.COMPRESSION_CLASS ? (
    <CompressionAnswer
      selectAnswer={selectAnswer}
      isSelected={isSelected}
      heading={heading}
      body={body}
    />
  ) : (
    <Wrapper
      isSelected={isSelected}
      onClick={() => selectAnswer()}
      isSymptoms={true}
    >
      <Header>
        <Toggle isSelected={isSelected} />
        <h4>{heading}</h4>
        {isOpen ? (
          <CloseButton
            close={() => {
              setIsOpen(!isOpen);
              setShowMore(false);
            }}
            showMore={showMore}
          />
        ) : (
          <InfoIcon
            alt="Info icon"
            src={infoIcon}
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
              setShowMore(false);
            }}
          />
        )}
      </Header>
      <Content showMore={showMore} isOpen={isOpen}>
        <div>
          <BodyText
            showMore={showMore}
            dangerouslySetInnerHTML={{ __html: body }}
          ></BodyText>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setShowMore(!showMore);
            }}
          >
            {showMore ? "Show less" : "Show more"}
          </Button>
        </div>
      </Content>
    </Wrapper>
  );
}

function LimbAnswer({
  selectAnswer,
  isSelected,
  heading = "heading",
  subheading,
  body = "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
  image,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);

  return (
    <LimbWrapper isSelected={isSelected} onClick={() => selectAnswer()}>
      <div>
        <Header style={{ padding: "16px 16px 0px 16px" }}>
          <Toggle isSelected={isSelected} />
          <h4>{heading}</h4>
          {isOpen ? (
            <CloseButton
              close={() => {
                setIsOpen(!isOpen);
                setShowMore(false);
              }}
              showMore={showMore}
            />
          ) : (
            <InfoIcon
              alt="Info icon"
              src={infoIcon}
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(!isOpen);
                setShowMore(true);
              }}
            />
          )}
        </Header>
        <Content
          showMore={showMore}
          isOpen={isOpen}
          style={{ display: "flex", padding: "0px 16px 16px 16px" }}
        >
          <h5>{subheading}</h5>
          <LimbBodyText
            showMore={showMore}
            dangerouslySetInnerHTML={{ __html: body }}
          ></LimbBodyText>
        </Content>
      </div>
      <LimbImage src={image} />
    </LimbWrapper>
  );
}

function CompressionAnswer({
  selectAnswer,
  isSelected,
  heading = "heading",
  body = "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);

  return (
    <CompressionWrapper isSelected={isSelected} onClick={() => selectAnswer()}>
      <Header>
        <Toggle isSelected={isSelected} />
        <h4 dangerouslySetInnerHTML={{ __html: heading }}></h4>
        {isOpen ? (
          <CloseButton
            close={() => {
              setIsOpen(!isOpen);
              setShowMore(false);
            }}
            showMore={showMore}
            style={{ display: "block" }}
          />
        ) : (
          <InfoIcon
            alt="Info icon"
            src={infoIcon}
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
              setShowMore(true);
            }}
            style={{ display: "block" }}
          />
        )}
      </Header>
      <Content
        showMore={showMore}
        isOpen={isOpen}
        style={{ marginTop: isOpen ? "16px" : "0px" }}
      >
        <div>
          {showMore && (
            <CompressionBodyText
              dangerouslySetInnerHTML={{ __html: body }}
            ></CompressionBodyText>
          )}
        </div>
      </Content>
    </CompressionWrapper>
  );
}
