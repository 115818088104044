import styled from "styled-components";
import { devices } from "../../styles";

export const Wrapper = styled.div`
  cursor: pointer;
  border: ${(props) =>
    props.isSelected
      ? `1px solid ${props.theme.colorTertiary}`
      : "1px solid #d4ccbd"};
  box-sizing: border-box;
  border-radius: 6px;
  padding: 16px;
  box-shadow: ${(props) =>
    props.isSelected &&
    `0px 1px 2px rgba(60, 64, 67, 0.3), 0px 1px 3px 1px rgba(60, 64, 67, 0.15)`};

  /* Chrome, Safari, Opera */
  -webkit-column-break-inside: avoid;
  /* Firefox */
  page-break-inside: avoid;
  /* IE 10+ */
  break-inside: avoid;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  h4 {
    margin: 0px 16px;
    justify-self: flex-start;
    flex: 1;
    line-height: 20px;
  }

  &:hover {
    border: 1px solid #f399c7;
  }

  @media ${devices.tablet} {
    flex-basis: calc(50% - 12px);
    width: 100%;
    margin-bottom: 24px;
    min-height: ${(props) => props.isSymptoms && "180px"};
  }
`;

export const LimbWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  padding: 0px;

  @media ${devices.tablet} {
    flex-direction: row-reverse;

    > div {
      max-width: 346px;
    }

    width: 100%;
  }
  @media ${devices.desktop} {
    width: 756px;
    &:not(:last-child) {
      margin-bottom: 36px;
    }
  }
`;

export const CompressionWrapper = styled(Wrapper)`
  width: 100%;
  margin: 0px;

  @media ${devices.desktop} {
    width: 756px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 24px;
    width: 24px;
  }
`;

export const CloseButtonWrapper = styled.div`
  background: white;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: flex-end;

  div {
    content: "";
    width: 24px;
    height: 2px;
    background-color: black;
    position: absolute;

    &:first-child,
    &:last-child {
      transform-origin: center;
    }

    &:first-child {
      transform: rotate(45deg);
    }
    &:last-child {
      transform: rotate(-45deg);
    }
  }
`;

export const CloseButton = ({ close, showMore }) => {
  return (
    <CloseButtonWrapper
      showMore={showMore}
      onClick={(e) => {
        e.stopPropagation();
        close();
      }}
    >
      <div></div>
      <div></div>
    </CloseButtonWrapper>
  );
};

export const Content = styled.div`
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  margin-top: 16px;
  max-width: 100%;
  flex-direction: column;

  h5 {
    color: ${(props) => props.theme.colorPrimary};
    color: #000000;
    font-size: 16px;
  }

  @media ${devices.tablet} {
    display: flex;
  }
`;

export const BodyText = styled.p`
  display: -webkit-box;
  overflow: hidden;
  line-clamp: ${(props) => !props.showMore && "2"};
  -webkit-line-clamp: ${(props) => !props.showMore && "2"};
  -webkit-box-orient: vertical;
  margin-bottom: 16px;
`;

export const LimbBodyText = styled.div`
  display: ${(props) => (props.showMore ? "block" : "none")};
  margin-top: 10px;

  @media ${devices.tablet} {
    display: flex;
  }
`;
export const CompressionBodyText = styled.div`
  display: block;
`;

export const Button = styled.button`
  background: none;
  border: none;
  color: ${(props) => props.theme.colorTertiary};
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.buttonSecondaryHover};
  }
`;

export const Toggle = styled.div`
  border-radius: 50%;
  border: ${(props) => `1px solid ${props.theme.colorTertiary}`};
  width: 32px;
  height: 32px;
  position: relative;

  &:after {
    width: 22px;
    height: 22px;
    content: "";
    background: ${(props) => props.isSelected && props.theme.colorTertiary};
    border-radius: 50%;
    position: absolute;
    top: 4px;
    left: 4px;
  }
`;

export const InfoIcon = styled.img`
  @media ${devices.tablet} {
    display: none;
  }
`;

export const LimbImage = styled.img`
  margin-top: 16px;
  height: 391px;
  width: 341px;
  margin: 0 auto;

  @media ${devices.tablet} {
    flex-basis: 50%;
    height: 393px;
    margin-top: 0px;
  }
`;
