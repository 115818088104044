import React, { useState, useEffect, useMemo, useReducer } from "react";
import { useEmailInfo } from "../../context/emailContext";
import styled from "styled-components";
import { devices } from "../../styles";

const defaultInfoObject = {
  email: "",
  patientName: "",
};

const inputs = [
  {
    label: "Your email",
    placeholder: "Please enter your email",
    key: "email",
    name: "reply_to",
  },
  {
    label: "Additional details",
    placeholder: "Please enter additional details",
    key: "patientName",
    name: "name",
  },
];

const reducer = (state, action) => {
  const { key, value } = action;

  return { ...state, [key]: value };
};

export default function PrescriptionInfoForm({
  showError,
  setShowError,
  setIsError,
}) {
  const [state, dispatch] = useReducer(reducer, defaultInfoObject);
  const optionalKeys = useMemo(() => {
    return inputs.filter((item) => item.optional).map((item) => item.key);
  }, []);

  const [errorMessage, setErrorMessage] = useState(
    "Please complete all mandatory fields to continue"
  );

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setShowError(false);
  }, [errorMessage, setShowError]);

  // if error is showing, highlight inputs that need to be changed
  useEffect(() => {
    const showInputErrors = () => {
      // reset all inputs to have no border
      const inputs = [...document.getElementsByTagName("input")];

      inputs.forEach((i) => {
        if (i) {
          i.style.border = "1px solid #D4CCBD";
        }
      });

      errors.forEach((key) => {
        const el = document.getElementById(key);

        if (showError && el) {
          el.style.border = "1px solid red";
        }
        if (state["secondaryInfo"] !== "default") {
          const infoInput = document.getElementById(state["secondaryInfo"]);

          if (showError && infoInput && key === "secondaryInfoInput") {
            infoInput.style.border = "1px solid red";
          }
        }
      });
    };

    showInputErrors();
  }, [state, showError, errors]);

  const { setEmailInfo } = useEmailInfo();

  // validate inputs
  const validateFields = () => {
    const { email, patientName } = state;

    if (!email || !patientName) {
      setErrorMessage("Please complete all mandatory fields to continue");
      return false;
    }

    function validEmail(email) {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }

    const isValidEmail = validEmail(email);

    if (!isValidEmail) {
      setErrorMessage("Invalid email address");
      setErrors(["email"]);
      return false;
    }

    return true;
  };

  useEffect(() => {
    // check if every (non-optional) field has a value
    const unentered = Object.keys(state).filter((item) => {
      if (optionalKeys.includes(item)) {
        return false;
      }
      return state[item] === "";
    });

    // if all items have values and it passes validation enable the button, otherwise disable
    if (unentered.length > 0) {
      setIsError(true);
      setErrorMessage("Please complete all mandatory fields to continue");
      const errors = Object.keys(state).filter((item) => {
        return state[item] === "";
      });

      setErrors(errors);
    } else {
      const isValidated = validateFields();

      if (isValidated) {
        setIsError(false);
      } else {
        setIsError(true);
      }
    }

    setEmailInfo(state);
  }, [state, optionalKeys, setEmailInfo, setIsError]);

  return (
    <Wrapper>
      <form id="email-submit-form" action="https://y0eqv28o73.execute-api.us-east-1.amazonaws.com/PROD/static-site-mailer-dev-staticSiteMailer" method="POST">
        {inputs.map((item) => {
          const { label, placeholder, key, name } = item;

          return (
            <div key={key}>
              <label>{label}</label>
              <input
                id={key}
                value={state[key]}
                onChange={(e) => dispatch({ key, value: e.target.value })}
                type="text"
                name={name}
                placeholder={placeholder}
              />
            </div>
          );
        })}
      </form>
      <ErrorContainer>{showError && errorMessage}</ErrorContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  color: #48586a;

  padding: 28px 16px;

  @media ${devices.tablet} {
    padding: 28px 72px;
    margin: 0 auto;
  }
  @media ${devices.desktop} {
    max-width: 900px;
    padding: 40px 72px 0px;
  }

  form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media ${devices.tablet} {
      max-height: 416px;
      column-gap: 24px;
      flex-direction: row;
    }
  }

  label {
    margin: 0px;
    margin-bottom: 8px;
  }

  label {
    color: #48586a !important;
  }

  option {
    font-size: 16px;
  }
  form > div {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    @media ${devices.tablet} {
      width: calc(50% - 12px);

      &:nth-child(2n + 1) {
        padding-right: 28px;
      }
      &:nth-child(2n) {
        padding-left: 28px;
      }
    }
  }
`;

const ErrorContainer = styled.div`
  font-size: 12px;
  margin-top: 32px;
  margin-bottom: -32px;
  min-height: 40px;
  color: red;
`;
