import Algorithm from "../../components/Algorithm";
import ColorPicker from "../../components/ColorPicker";
import ItemSelector from "../../components/ItemSelector";
import Measurements from "../../components/Measurements";
import ProductInfo from "../../components/ProductInfo";
import Limb0 from "../../assets/limb-0.png";
import Limb1 from "../../assets/limb-1.png";
import Limb2 from "../../assets/limb-2.png";

export const RESPONSE_SECTIONS = {
  SYMPTOMS: "symptoms",
  LIMB: "limb",
  COMPRESSION_CLASS: "compression_class",
  RECOMMENDED: "recommended",
  MEASUREMENTS: "measurements",
  COLOR: "color",
  TOE_TYPE: "toe_type",
};

export const questions = [
  {
    question: "What are your patient's symptoms?",
    subheading: "Please select all the symptoms your patient is showing:",
    answers: [
      {
        key: "VENOUS",
        heading: "Venous disease with no other wound(s) or oedema",
        body: "When the venous valves become damaged, blood pools in the ankles and feet, the resulting build-up of pressure (‘venous hypertension’) causes widening of the vessels, which can become enlarged and twisted, called <b>varicose veins.</b> Treatment of the underlying condition at this point can reduce the risk of further development of associated complications.",
      },
      {
        key: "VENOUS_MILD_OEDEMA",
        heading: "Venous disease with no wound(s) with mild oedema",
        body: "When early warning signs of venous disease are not treated correctly, mild oedema may develop (venous oedema); this manifests as a swelling in the ankle and foot which typically gets worse throughout the course of the day.<br/><br/>Prior to prescribing compression therapy, it is important to perform holistic assessment of the patient in order to rule out conditions which require medical attention, e.g. cardiac failure or renal disease.",
      },
      {
        key: "HEALED_VENOUS",
        heading: "Healed venous leg ulcer with no active wound(s)",
        body: "Venous leg ulcers are slow-healing wounds which occur on the lower leg. Once healed they have a high recurrence rate due to the often untreated underlying venous insufficiency. Patients who have had compression therapy to treat a venous leg ulcer will require lifelong compression to reduce the risk of recurrence.",
      },
      {
        key: "ACTIVE_VENOUS",
        heading: "Active venous leg ulcer(s)",
        body: "The symptoms of a venous leg ulcer include pain, itching and swelling in the affected leg. The wound may also be sore and produce a malodourous discharge.<br/><br/>If a wound is present for more than two weeks with no sign of healing, it is important to carry out a full holistic assessment of the patient. Part of this holistic assessment includes assessing the flow of blood into the lower limbs to exclude the presence of peripheral arterial disease (PAD) with the most common method being the assessment of ankle brachial pressure index (ABPI). Once the ABPI score is obtained the compression level can be prescribed following your local guidelines. Compression is contra-indicated in the presence of severe PAD (low ABPI). When the ABPI is within normal range, the National Wound Care Strategy Programme: (2020) Recommendations for Lower Limb Ulcers suggests the use of 2-layer leg ulcer kits to improve venous return and heal the ulcer.",
      },
      {
        key: "LYMPHOEDEMA",
        heading: "Lymphoedema/chronic oedema ",
        body: "This is the term used to describe a swelling in the leg(s) that does not go down after elevation. <br/><br/>The use of diuretics will have no impact on chronic oedema because the swelling is due to congestion within the tissue spaces and diuretics work to reduce the amount of fluid in blood vessels.<br/><br/>Chronic oedema and lymphoedema require compression to keep the fluid from becoming hardened creating skin changes and to reduce the risk of cellulitis.",
      },
    ],
    multipleAnswers: true,
    section: RESPONSE_SECTIONS.SYMPTOMS,
  },
  {
    question: "Which limb resembles your patient the most?",
    subheading: "Please select one of the following:",
    answers: [
      {
        key: "STRAIGHT_LEG",
        heading: "Straight leg",
        subheading: "Muscle wasting",
        body: "The leg has no shape definition and may have signs of muscle wastage. Also called muscle ‘atrophy’, muscles waste away due to a lack of physical activity when a disease, injury or even long-term bandaging make it difficult or impossible to activate the ankle flex.",
        image: Limb0,
      },
      {
        key: "NORMAL_SHAPE",
        heading: "Normal shape",
        subheading: "Defined graduation",
        body: "The leg is smaller at the ankle and gradually changes shape to the calf with a defined knee shape.",
        image: Limb1,
      },
      {
        key: "SHAPE_DISTORTION",
        heading: "Shape distortion",
        subheading: "Leg is not a graduated shape",
        body: "The leg does not gradually change shape from the ankle to the calf. There may be distortion of shape around the ankles and knee with skin folds present.",
        image: Limb2,
      },
    ],
    multipleAnswers: false,
    section: RESPONSE_SECTIONS.LIMB,
  },
  {
    question: "Choose garment compression class",
    subheading: "Please select one of the following:",
    answers: [
      {
        key: "BSC_1",
        heading: "British Standard Class I (14–17&nbsp;mmHg)",
        body: "<b>duomed® soft</b> is a light, sheer fabric that is comfortable and easy to apply and can be safely used without ABPI assessment if there are no red flags present. Measuring is simple and straightforward with sizes ranging from small to XXL. Guaranteed to provide effective compression for 3 months.",
      },
      {
        key: "BSC_2",
        heading: "British Standard Class II (18–24&nbsp;mmHg)",
        body: "<b>duomed® soft</b> is a light, sheer fabric that is comfortable and easy to apply. Measuring is simple and straightfoward with sizes ranging from small to XXL. Guaranteed to provide effective compression for 3 months.",
      },
      {
        key: "RAL_1",
        heading: "RAL class 1 (18–21&nbsp;mmHg)",
        body: "<b>mediven®</b> RAL class 1 is used in mild oedema, venous disease and palliative care to help support the tissues and improve venous return. Available in a comprehensive range of sizes and lengths to enable an accurate fit for your patient, <b>mediven®</b> RAL hosiery is guaranteed to provide effective compression for 6 months.",
      },
      {
        key: "RAL_2",
        heading: "RAL class 2 (23–32&nbsp;mmHg)",
        body: "<b>mediven®</b> RAL class 2 is used for moderate conditions to reduce the risk of them deteriorating and is often used after a venous leg ulcer has healed if the patient cannot tolerate class 3 compression. Available in a comprehensive range of sizes and lengths to enable an accurate fit for your patient, <b>mediven®</b> RAL hosiery is guaranteed to provide effective compression for 6 months.",
      },
      {
        key: "RAL_3",
        heading: "RAL class 3 (34–46&nbsp;mmHg)",
        body: "<b>mediven®</b> RAL class 3 is used for severe recurring venous disease with or without chronic oedema. Available in a comprehensive range of sizes and lengths to enable an accurate fit for your patient, <b>mediven®</b> RAL hosiery is guaranteed to provide effective compression for 6 months.",
      },
    ],
    multipleAnswers: false,
    section: RESPONSE_SECTIONS.COMPRESSION_CLASS,
  },
  {
    component: Algorithm,
    question: "Thanks for entering your patient’s details",
    subheading:
      "The <b>Hosiery Hunter®</b> app is searching to identify the most suitable garment for your patient.",
  },
  {
    component: ItemSelector,
    question:
      "Choose one of the recommended garments available on prescription",
    subheading:
      "Based on the information gathered, please select one of the following options:",
    answers: [],
    multipleAnswers: false,
  },
  {
    component: Measurements,
    question: "Measurements for {{name}}",
    subheading:
      "Please measure the length and circumference of your patient's affected leg(s) and complete the fields below. Use the diagram below as a guide:",
    answers: [],
    multipleAnswers: false,
  },
  {
    component: ColorPicker,
    question: "Pick the colour and toe style for {{name}}",
    subheading:
      "Please select your patient’s preferred colour and specific toe style from the options below:",
    answers: [],
    multipleAnswers: false,
  },
  {
    component: ProductInfo,
    question: "Your garment order code & information",
  },
];
