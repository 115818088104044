import React, { useEffect, useState } from "react";
import gsap from "gsap";
import AWS from "aws-sdk";
import ReactGA from "react-ga4";
import Modal from "../Modal";
import Envelope from "../../assets/envelope.png";
import Tick from "../../assets/tick.png";
import { QuaternaryButton, TertiaryButton } from "../Button";
import { useEmailInfo } from "../../context/emailContext";
import { useOffers } from "../../context/offerContext";
import { useResponses } from "../../context/responseContext";
import { questions } from "../../routes/Questionnaire/questions";
import { MEASUREMENT_LABELS } from "../Measurements";

export default function SendEmailModal({ isOpen, close, reset }) {
  const [isSent, setIsSent] = useState(false);
  const { emailInfo } = useEmailInfo();
  const { selected, selectedProduct } = useOffers();
  const { responses } = useResponses();

  const { email, patientName } = emailInfo;
  const { color, compression_class, limb, measurements, symptoms, toe_type } =
    responses;

  const compressionClass = compression_class
    ? compression_class.length > 0
      ? questions[2].answers.find((e) => e.key === compression_class).heading
      : null
    : null;

  const legShape = limb
    ? questions[1].answers.find((e) => e.key === limb).heading
    : null;

  const symptomHeadings = symptoms
    ? symptoms.map(
        (item) => questions[0].answers.find((e) => e.key === item).heading
      )
    : [];

  const leftLeg = selectedProduct["left"];
  const rightLeg = selectedProduct["right"];

  const getMeasurements = (measurements) => {
    if (!measurements) {
      return "";
    }

    let info = "<br/><br/>";

    const measurementsList = [
      "measurement_BD",
      "CC",
      "CB",
      "measurement_CB1",
      "ID",
    ];

    const leftCount = measurements["left"]
      ? Object.keys(measurements["left"]).length
      : 0;
    const rightCount = measurements["right"]
      ? Object.keys(measurements["right"]).length
      : 0;

    if (measurements) {
      if (measurements["left"] && leftCount > 0) {
        info += "<b>Left leg</b><br/>";

        measurementsList.forEach((key) => {
          if (
            measurements["left"][key] !== null &&
            measurements["left"][key] !== undefined
          ) {
            info += `${MEASUREMENT_LABELS[key]}: ${measurements["left"][key]}cm<br/>`;
          }
        });

        info += "<br/>";
      }

      if (measurements["right"] && rightCount > 0) {
        info += "<b>Right leg</b><br/>";

        measurementsList.forEach((key) => {
          if (
            measurements["right"][key] !== null &&
            measurements["right"][key] !== undefined
          ) {
            info += `${MEASUREMENT_LABELS[key]}: ${measurements["right"][key]}cm<br/>`;
          }
        });

        info += "<br/>";
      }
    }

    return info;
  };

  const legInfoToHtml = (leg, heading) => {
    let info = `<br/><br/><b>${heading}</b><br/>`;

    if (leg) {
      info += `Order code: ${leg["code"]}<br/>`;
      info += `PIP code: ${leg["pip"]}<br/>`;
      info += `Size: ${leg["size"]}<br/>`;
      if (color) {
        info += `Colour: ${color}<br/>`;
      }
      if (toe_type) {
        info += `Toe style: ${toe_type}<br/>`;
      }
    }

    return info;
  };

  const userInput = `<b>Symptoms:</b> ${symptomHeadings.join(
    ", "
  )}<br/><b>Leg shape:</b> ${legShape}<br/><b>Compression class:</b> ${compressionClass}`;

  const measurementsText = `Measurements of patient limb(s) that were recorded: ${getMeasurements(
    measurements
  )}`;

  const leftLegInfo =
    leftLeg === null ? null : legInfoToHtml(leftLeg, "Left Leg");
  const rightLegInfo =
    rightLeg === null ? null : legInfoToHtml(rightLeg, "Right Leg");

  const emailBody = `I have reviewed a patient today to assess their compression requirements and recommend that they are prescribed the following garment: ${
    selected && selected.displayName
  }. Please find all of the relevant information below:<br/><br/>Additional details: ${patientName} <br/><br/>${userInput}<br/><br/>${measurementsText}The recommended product, ${
    selected && selected.displayName
  } was selected, together with the following information:${
    leftLegInfo ? leftLegInfo : ""
  }${rightLegInfo ? rightLegInfo : ""}`;

  
  // fade in first question content
  const resetAnimation = () => {
    gsap.fromTo(
      [...document.querySelectorAll("#content")],
      { opacity: 1 },
      {
        opacity: 1,
        duration: 0.5,
        onComplete: () => {
          close();
          setIsSent(false);
          reset();

          // fade back in first question content (will be 0 opacity by default)
          gsap.fromTo(
            [...document.querySelectorAll("#content")],
            { opacity: 0 },
            {
              opacity: 1,
              duration: 0.5,
            }
          );
        },
      }
    );
  };

  useEffect(() => {
    const form = document.querySelector('form');

    if (form){
      form.onsubmit = e => {
        e.preventDefault();
    
        // Prepare data to send
        const data = {};
        const formElements = Array.from(form);
        formElements.map(input => (data[input.name] = input.value));
        data.message = emailBody;

        // Construct an HTTP request
        var xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action, true);
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify(data));
        
        // Callback function
        xhr.onloadend = response => {
          if (response.target.status !== 200) {
            console.error(JSON.parse(response.target.response).message);
          }
        };
      };
    }
  }, [isOpen]);

  return (
    <Modal
      id="content"
      isOpen={isOpen}
      close={() => {
        close();
        setIsSent(false);
      }}
    >
      <img alt="" src={isSent ? Tick : Envelope} />
      <h3>{isSent ? "Your email has been sent" : "Send email"}</h3>
      <p>
        {isSent
          ? "Thank you."
          : "Are you sure you have all the information correct before sending? This action cannot be undone."}
      </p>
      {!isSent && (
        <QuaternaryButton onClick={() => close()}>Cancel</QuaternaryButton>
      )}
      <TertiaryButton
        type="submit"
        form="email-submit-form"
        onClick={() => {
          if (isSent) {
            // close modal and return to start
            close();
            resetAnimation();
          } else {
            ReactGA.event({
              category: "User",
              action: `Sent email`,
            });
            setIsSent(true);
          }
        }}
      >
        Continue
      </TertiaryButton>
    </Modal>
  );
}
