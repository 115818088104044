import React from "react";
import gsap from "gsap";
import styled from "styled-components";
import ProgressBar from "../ProgressBar";
import { devices } from "../../styles";

import Logo from "../../assets/mediuk-logo.png";
import ResetIcon from "../../assets/reset.png";

function Header({ children }) {
  return <HeaderWrapper>{children}</HeaderWrapper>;
}

export function WelcomeHeader() {
  return (
    <Header>
      <Container>
        <a href="https://www.mediuk.co.uk" target="_blank" rel="noreferrer">
          <img alt="medi uk logo" src={Logo} />
        </a>
      </Container>
    </Header>
  );
}
export function IntroHeader({ index, length }) {
  const progress = [
    ...new Array(length)
      .fill(true, 0, index + 1)
      .fill(false, index + 1, length),
  ];

  return (
    <Header>
      <ProgressBar progress={progress} />
      <a href="https://www.mediuk.co.uk" target="_blank" rel="noreferrer">
        <img alt="medi uk logo" src={Logo} />
      </a>
    </Header>
  );
}
export function QuestionnaireHeader({ reset, index, length }) {
  const progress = [
    ...new Array(length)
      .fill(true, 0, index + 1)
      .fill(false, index + 1, length),
  ];

  // fadeOut content, then fade in first question again
  const animationOnReset = () => {
    gsap.fromTo(
      [...document.querySelectorAll("#content")],
      { opacity: 1 },
      {
        opacity: 0,
        duration: 0.5,
        onComplete: () => {
          reset();
          gsap.fromTo(
            [...document.querySelectorAll("#content")],
            { opacity: 0 },
            {
              opacity: 1,
              duration: 0.5,
            }
          );
        },
      }
    );
  };

  return (
    <Header>
      <div
        style={{
          maxWidth: "756px",
          display: "flex",
          flex: 1,
          alignItems: "center",
        }}
      >
        <Reset onClick={() => animationOnReset()}>
          <img alt="reset arrow" src={ResetIcon} />
        </Reset>
        <ProgressBar isQuestionnaire={true} progress={progress} />
        <a href="https://www.mediuk.co.uk" target="_blank" rel="noreferrer">
          <img alt="medi uk logo" src={Logo} />
        </a>
      </div>
    </Header>
  );
}

const HeaderWrapper = styled.header`
  height: 80px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  z-index: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-shadow: 0px 2px 6px 2px rgba(60, 64, 67, 0.15);
  filter: drop-shadow(0px 1px 2px rgba(60, 64, 67, 0.3));

  a {
    display: flex;
    align-items: center;
    margin-left: 24px;
  }

  a img {
    height: 40px;
    width: 40px;
  }

  @media ${devices.tablet} {
    height: 120px;
    padding: 40px 64px;
  }

  @media ${devices.desktop} {
    padding-top: 32px;
    padding-bottom: 32px;
    justify-content: center;

    a {
      margin-left: 32px;
    }
    a img {
      height: 55px;
      width: 55px;
    }
  }
`;

const Reset = styled.div`
  height: 48px;
  width: 48px;
  min-width: 48px;
  border-radius: 50%;
  border: 1px solid #d4ccbd;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;

  img {
    height: 24px;
    width: 24px;
    margin: 0px;
  }

  &:hover {
    cursor: pointer;
    border-color: #f399c7;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  @media ${devices.desktop} {
    width: 964px;
  }
`;
